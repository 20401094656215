*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

main {
  display: block;
  background-color: #ffffff;
}

p,
ul,
ol {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style: none;
}

cite {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5 {
  margin-top: 0;
}

figure {
  margin-left: 0;
  margin-right: 0;
}

audio,
canvas,
img,
video {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

@media screen and (max-width: 67.4375em) {
  input,
  textarea,
  select {
    font-size: 1em;
  }
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.showvisuallyhidden,
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
  white-space: inherit;
}

.is-transitioning {
  display: block !important;
  visibility: visible !important;
}

.js-focus-hidden:focus {
  outline: none;
}

a,
button,
[role="button"],
input,
label,
select,
textarea {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

button {
  background: none;
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  display: inline-block;
  cursor: pointer;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

button::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner {
  padding: 0;
  border: 0;
}

button:-moz-focusring,
input[type="button"]:-moz-focusring,
input[type="submit"]:-moz-focusring,
input[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.page-wrap {
  overflow: hidden;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
}

main {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

main::after {
  content: "";
  display: table;
  clear: both;
}

.page-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  max-width: 1600px;
}

.page-container::after {
  content: "";
  display: table;
  clear: both;
}

.page-width {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
}

.page-width::after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 67.5em) {
  .page-width {
    width: 990px;
  }
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .page-width {
    width: 750px;
  }
}

@media screen and (min-width: 67.5em) {
  .page-width--fluid {
    width: 100%;
    max-width: 1230px;
  }
}

@font-face {
  font-family: "ShopifySans";
  src: url("https://cdn.shopify.com/shopify-marketing_assets/static/ShopifySans--light.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: "ShopifySans";
  src: url("https://cdn.shopify.com/shopify-marketing_assets/static/ShopifySans--regular.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: "ShopifySans";
  src: url("https://cdn.shopify.com/shopify-marketing_assets/static/ShopifySans--medium.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "ShopifySans";
  src: url("https://cdn.shopify.com/shopify-marketing_assets/static/ShopifySans--bold.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "ShopifySans";
  src: url("https://cdn.shopify.com/shopify-marketing_assets/static/ShopifySans--extrabold.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: "ShopifySans";
  src: url("https://cdn.shopify.com/shopify-marketing_assets/static/ShopifySans--black.woff2")
    format("woff2");
  font-style: normal;
  font-weight: 900;
  font-display: swap;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  background: #dfe3e8;
  font-family: ShopifySans, Helvetica, Arial, Lucida Grande, sans-serif;
  font-size: 1em;
  line-height: 1.5;
  color: #637381;
  font-weight: 400;
}

.heading--jumbo {
  -webkit-font-kerning: normal;
  font-kerning: normal;
  -webkit-font-variant-ligatures: common-ligatures contextual
    discretionary-ligatures;
  font-variant-ligatures: common-ligatures contextual discretionary-ligatures;
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt", "dlig";
  font-feature-settings: "kern", "liga", "clig", "calt", "dlig";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  margin-bottom: 0.5555555556em;
  color: #212b35;
  font-size: 2.25em;
  line-height: 1.222;
  font-weight: 700;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
  letter-spacing: -0.025em;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .heading--jumbo {
    font-size: 2.25em;
    margin-bottom: 0.5555555556em;
  }
}

@media screen and (min-width: 67.5em) {
  .heading--jumbo {
    font-size: 3.75em;
    margin-bottom: 0.4em;
  }
}

h1,
.heading--1 {
  -webkit-font-kerning: normal;
  font-kerning: normal;
  -webkit-font-variant-ligatures: common-ligatures contextual
    discretionary-ligatures;
  font-variant-ligatures: common-ligatures contextual discretionary-ligatures;
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt", "dlig";
  font-feature-settings: "kern", "liga", "clig", "calt", "dlig";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  margin-bottom: 0.7142857143em;
  color: #212b35;
  font-size: 1.75em;
  line-height: 1.222;
  font-weight: 700;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
  letter-spacing: -0.025em;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  h1,
  .heading--1 {
    font-size: 1.75em;
    margin-bottom: 0.4166666667em;
  }
}

@media screen and (min-width: 67.5em) {
  h1,
  .heading--1 {
    font-size: 3em;
    margin-bottom: 0.25em;
  }
}

h2,
.heading--2 {
  -webkit-font-kerning: normal;
  font-kerning: normal;
  -webkit-font-variant-ligatures: common-ligatures contextual
    discretionary-ligatures;
  font-variant-ligatures: common-ligatures contextual discretionary-ligatures;
  -webkit-font-feature-settings: "kern", "liga", "clig", "calt", "dlig";
  font-feature-settings: "kern", "liga", "clig", "calt", "dlig";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  margin-bottom: 0.8333333333em;
  color: #212b35;
  font-size: 1.5em;
  line-height: 1.222;
  font-weight: 700;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  h2,
  .heading--2 {
    font-size: 1.5em;
    margin-bottom: 0.8333333333em;
  }
}

@media screen and (min-width: 67.5em) {
  h2,
  .heading--2 {
    font-size: 2.25em;
    margin-bottom: 0.5em;
  }
}

h3,
.heading--3 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  font-size: 1.25em;
  margin-bottom: 0.9em;
  color: #212b35;
  line-height: 1.222;
  font-weight: 700;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  h3,
  .heading--3 {
    font-size: 1.25em;
    margin-bottom: 0.9em;
  }
}

@media screen and (min-width: 67.5em) {
  h3,
  .heading--3 {
    font-size: 1.5em;
    margin-bottom: 0.8333333333em;
  }
}

h4,
.heading--4 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  margin-bottom: 0.625em;
  color: #212b35;
  font-size: 1em;
  line-height: 1.222;
  font-weight: 700;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  h4,
  .heading--4 {
    font-size: 1em;
    margin-bottom: 0.75em;
  }
}

@media screen and (min-width: 67.5em) {
  h4,
  .heading--4 {
    font-size: 1.125em;
    margin-bottom: 0.6666666667em;
  }
}

h5,
.heading--5 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  margin-bottom: 1.4285714286em;
  color: #212b35;
  font-size: 0.875em;
  line-height: 1.222;
  font-weight: 700;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  h5,
  .heading--5 {
    font-size: 0.875em;
    margin-bottom: 1.4285714286em;
  }
}

@media screen and (min-width: 67.5em) {
  h5,
  .heading--5 {
    font-size: 0.875em;
    margin-bottom: 1.4285714286em;
  }
}

a {
  text-decoration: none;
  cursor: pointer;
  color: #262626;
}

a:hover {
  color: #262626;
}

.text-minor {
  font-size: 0.75em;
}

.text-major {
  font-size: 1.125em;
}

@media screen and (min-width: 67.5em) {
  .text-major {
    font-size: 1.25em;
  }
}

.body-link,
.applied-filters__remove-all {
  color: #5c6ac4;
  text-decoration: underline;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
  -webkit-text-decoration-color: currentColor;
  text-decoration-color: currentColor;
  text-decoration-skip-ink: auto;
  -webkit-transition: color 150ms;
  transition: color 150ms;
}

.body-link:hover,
.applied-filters__remove-all:hover,
.body-link:focus,
.applied-filters__remove-all:focus {
  color: #4655bc;
}

.body-link:focus,
.applied-filters__remove-all:focus {
  outline: 0;
  background-color: rgba(92, 106, 196, 0.125);
}

.body-link--base {
  color: #637381;
  text-decoration: underline;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
  -webkit-text-decoration-color: currentColor;
  text-decoration-color: currentColor;
  text-decoration-skip-ink: auto;
  -webkit-transition: color 150ms;
  transition: color 150ms;
}

.body-link--base:hover,
.body-link--base:focus {
  color: #566470;
}

.body-link--base:focus {
  outline: 0;
  background-color: rgba(99, 115, 129, 0.125);
}

.body-link--reverse {
  color: #ffffff;
  text-decoration: underline;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
  -webkit-text-decoration-color: currentColor;
  text-decoration-color: currentColor;
  text-decoration-skip-ink: auto;
  -webkit-transition: color 150ms;
  transition: color 150ms;
}

.body-link--reverse:hover,
.body-link--reverse:focus {
  color: #f0f0f0;
}

.body-link--reverse:focus {
  outline: 0;
  background-color: rgba(255, 255, 255, 0.125);
}

.body-link--blue {
  color: #206882;
  text-decoration: underline;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
  -webkit-text-decoration-color: currentColor;
  text-decoration-color: currentColor;
  text-decoration-skip-ink: auto;
  -webkit-transition: color 150ms;
  transition: color 150ms;
}

.body-link--blue:hover,
.body-link--blue:focus {
  color: #1a5469;
}

.body-link--blue:focus {
  outline: 0;
  background-color: rgba(32, 104, 130, 0.125);
}

main p,
main ul,
main ol {
  margin-bottom: 0.9375em;
  line-height: 1.5;
  font-size: 1em;
}

main p > a {
  color: #5c6ac4;
  text-decoration: underline;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
  -webkit-text-decoration-color: currentColor;
  text-decoration-color: currentColor;
  text-decoration-skip-ink: auto;
  -webkit-transition: color 150ms;
  transition: color 150ms;
}

main p > a:hover,
main p > a:focus {
  color: #4655bc;
}

main p > a:focus {
  outline: 0;
  background-color: rgba(92, 106, 196, 0.125);
}

.marketing-quote {
  margin: 30px 0;
}

.marketing-quote__content {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
  font-weight: 300;
  margin: 0;
  padding: 0.6666666667em 0 0.6666666667em 30px;
  font-size: 1.5em;
  color: #000000;
  border-left: solid 2px #dfe3e8;
}

.marketing-quote__footer {
  text-align: right;
}

.heading-container {
  text-align: center;
}

.section-intro {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-width: 1px 0;
  padding: 2.25em 0;
}

.section-intro::after {
  content: "";
  display: table;
  clear: both;
}

.numbered-list {
  list-style: decimal;
  list-style-position: outside;
  margin-left: 2.5em;
}

.numbered-list li {
  margin-bottom: 0.5em;
}

.bulleted-list {
  list-style: disc;
  list-style-position: outside;
  margin-left: 1.25em;
}

.bulleted-list li {
  margin-bottom: 0.5em;
}

:lang(ja) {
  font-family: "Hiragino Kaku Gothic Pro", "ヒラギノ角ゴ Pro W3", "メイリオ",
    Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  -webkit-font-feature-settings: normal;
  font-feature-settings: normal;
  -webkit-font-variant-ligatures: normal;
  font-variant-ligatures: normal;
}

.grid-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.grid-container::after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 46.875em) {
  .grid-container--equal-height {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media screen and (min-width: 67.5em) {
  .grid-8 {
    width: 930px;
  }
  .grid-push-8 {
    margin-left: 960px;
  }
  .grid-7 {
    width: 810px;
    margin-right: 30px;
  }
  .grid-push-7 {
    margin-left: 840px;
  }
  .grid-6 {
    width: 690px;
    margin-right: 30px;
  }
  .grid-push-6 {
    margin-left: 720px;
  }
  .grid-5 {
    width: 570px;
    margin-right: 30px;
  }
  .grid-push-5 {
    margin-left: 600px;
  }
  .grid-4 {
    width: 450px;
    margin-right: 30px;
  }
  .grid-push-4 {
    margin-left: 480px;
  }
  .grid-3 {
    width: 330px;
    margin-right: 30px;
  }
  .grid-push-3 {
    margin-left: 360px;
  }
  .grid-2 {
    width: 210px;
    margin-right: 30px;
  }
  .grid-push-2 {
    margin-left: 240px;
  }
  .grid-1 {
    width: 90px;
    margin-right: 30px;
  }
  .grid-push-1 {
    margin-left: 120px;
  }
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .grid-8 {
    width: 690px;
  }
  .grid-push-8 {
    margin-left: 720px;
  }
  .grid-7 {
    width: 600px;
    margin-right: 30px;
  }
  .grid-push-7 {
    margin-left: 630px;
  }
  .grid-6 {
    width: 510px;
    margin-right: 30px;
  }
  .grid-push-6 {
    margin-left: 540px;
  }
  .grid-5 {
    width: 420px;
    margin-right: 30px;
  }
  .grid-push-5 {
    margin-left: 450px;
  }
  .grid-4 {
    width: 330px;
    margin-right: 30px;
  }
  .grid-push-4 {
    margin-left: 360px;
  }
  .grid-3 {
    width: 240px;
    margin-right: 30px;
  }
  .grid-push-3 {
    margin-left: 270px;
  }
  .grid-2 {
    width: 150px;
    margin-right: 30px;
  }
  .grid-push-2 {
    margin-left: 180px;
  }
  .grid-1 {
    width: 60px;
    margin-right: 30px;
  }
  .grid-push-1 {
    margin-left: 90px;
  }
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .grid--tablet-8 {
    width: 690px;
  }
  .grid--tablet-push-8 {
    margin-left: 720px;
  }
  .grid--tablet-7 {
    width: 600px;
    margin-right: 30px;
  }
  .grid--tablet-push-7 {
    margin-left: 630px;
  }
  .grid--tablet-6 {
    width: 510px;
    margin-right: 30px;
  }
  .grid--tablet-push-6 {
    margin-left: 540px;
  }
  .grid--tablet-5 {
    width: 420px;
    margin-right: 30px;
  }
  .grid--tablet-push-5 {
    margin-left: 450px;
  }
  .grid--tablet-4 {
    width: 330px;
    margin-right: 30px;
  }
  .grid--tablet-push-4 {
    margin-left: 360px;
  }
  .grid--tablet-3 {
    width: 240px;
    margin-right: 30px;
  }
  .grid--tablet-push-3 {
    margin-left: 270px;
  }
  .grid--tablet-2 {
    width: 150px;
    margin-right: 30px;
  }
  .grid--tablet-push-2 {
    margin-left: 180px;
  }
  .grid--tablet-1 {
    width: 60px;
    margin-right: 30px;
  }
  .grid--tablet-push-1 {
    margin-left: 90px;
  }
}

.grid-item {
  float: left;
  position: relative;
}

@media screen and (min-width: 46.875em) {
  .grid-container--halves .grid-item:nth-child(2n) {
    margin-right: 0;
  }
  .grid-container--halves .grid-item:nth-child(2n + 1) {
    clear: left;
  }
  .grid-container--thirds .grid-item:nth-child(3n) {
    margin-right: 0;
  }
  .grid-container--thirds .grid-item:nth-child(3n + 1) {
    clear: left;
  }
  .grid-container--quarters .grid-item:nth-child(4n) {
    margin-right: 0;
  }
  .grid-container--quarters .grid-item:nth-child(4n + 1) {
    clear: left;
  }
  .grid-container--eights .grid-item:nth-child(8n) {
    margin-right: 0;
  }
  .grid-container--eights .grid-item:nth-child(8n + 1) {
    clear: left;
  }
}

@media screen and (max-width: 46.8125em) {
  .grid-item {
    float: none;
  }
  .grid-item.grid--mobile {
    float: left;
    display: block;
    margin-right: 6.6666666667%;
    width: 46.6666666667%;
    float: left;
  }
  .grid-item.grid--mobile:last-child {
    margin-right: 0;
  }
  .grid-item.grid--mobile:nth-child(2n) {
    margin-right: 0;
  }
  .grid-item.grid--mobile:nth-child(2n + 1) {
    clear: left;
  }
  .grid-item.grid--mobile.grid--last + .grid-item {
    clear: none;
  }
}

.grid--last {
  margin-right: 0 !important;
}

.grid--last + .grid-item {
  clear: both;
}

@media screen and (min-width: 46.875em) {
  .grid-item--fluid__quarter {
    float: left;
    display: block;
    margin-right: 3.2258064516%;
    width: 22.5806451613%;
  }
  .grid-item--fluid__quarter:last-child {
    margin-right: 0;
  }
  .grid-item--fluid__third {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 31.914893617%;
  }
  .grid-item--fluid__third:last-child {
    margin-right: 0;
  }
  .grid-item--fluid__two-thirds {
    float: left;
    display: block;
    margin-right: 2.1276595745%;
    width: 65.9574468085%;
  }
  .grid-item--fluid__two-thirds:last-child {
    margin-right: 0;
  }
  .grid-item--fluid__half {
    float: left;
    display: block;
    margin-right: 3.2258064516%;
    width: 48.3870967742%;
  }
  .grid-item--fluid__half:last-child {
    margin-right: 0;
  }
}

@media screen and (min-width: 67.5em) {
  .grid-container--wide {
    width: 1440px;
    padding-left: 255px;
    padding-right: 255px;
    margin-left: -255px;
  }
  .grid-item--wide.grid-8,
  .grid-8.grid--wide-right,
  .grid-8.grid--wide-left {
    width: 1185px;
  }
  .grid-item--wide.grid-7,
  .grid-7.grid--wide-right,
  .grid-7.grid--wide-left {
    width: 1065px;
  }
  .grid-item--wide.grid-6,
  .grid-6.grid--wide-right,
  .grid-6.grid--wide-left {
    width: 945px;
  }
  .grid-item--wide.grid-5,
  .grid-5.grid--wide-right,
  .grid-5.grid--wide-left {
    width: 825px;
  }
  .grid-item--wide.grid-4,
  .grid-4.grid--wide-right,
  .grid-4.grid--wide-left {
    width: 705px;
  }
  .grid-item--wide.grid-3,
  .grid-3.grid--wide-right,
  .grid-3.grid--wide-left {
    width: 585px;
  }
  .grid-item--wide.grid-2,
  .grid-2.grid--wide-right,
  .grid-2.grid--wide-left {
    width: 465px;
  }
  .grid-item--wide.grid-1,
  .grid-1.grid--wide-right,
  .grid-1.grid--wide-left {
    width: 345px;
  }
  .grid--wide-left {
    margin-left: -255px;
  }
  .grid--wide-right {
    margin-right: -255px;
  }
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .grid--wide-right {
    margin-right: 0;
  }
}

@media screen and (min-width: 46.875em) {
  .grid-item--flush-half {
    width: 50%;
  }
  .grid-item--flush-third {
    width: 33.3333333333%;
  }
  .grid-item--flush-quarter {
    width: 25%;
  }
}

.grid-item--bordered {
  border-bottom: 1px solid #dfe3e8;
}

@media screen and (max-width: 46.8125em) {
  .grid-item--bordered:last-child {
    border-bottom: 0;
  }
}

@media screen and (min-width: 46.875em) {
  .grid-item--bordered {
    border-right: 1px solid #dfe3e8;
  }
  .grid-container--thirds .grid-item--bordered:nth-child(3n) {
    border-right: 0;
  }
  .grid-container--thirds .grid-item--bordered:nth-last-child(-n + 3) {
    border-bottom: 0;
  }
  .grid-container--quarters .grid-item--bordered:nth-child(4n) {
    border-right: 0;
  }
  .grid-container--quarters .grid-item--bordered:nth-last-child(-n + 4) {
    border-bottom: 0;
  }
  .grid-container--halves .grid-item--bordered:nth-child(2n) {
    border-right: 0;
  }
  .grid-container--halves .grid-item--bordered:nth-last-child(-n + 2) {
    border-bottom: 0;
  }
}

@media screen and (min-width: 46.875em) {
  .grid-item--margin-bottom {
    margin-bottom: 1.875em;
  }
}

@media screen and (min-width: 67.5em) {
  .grid-content-10 {
    float: left;
    display: block;
    margin-right: 2.5641025641%;
    width: 100%;
  }
  .grid-content-10:last-child {
    margin-right: 0;
  }
  .grid-content-push-10 {
    margin-left: 102.5641025641%;
  }
  .grid-content-9 {
    float: left;
    display: block;
    margin-right: 2.5641025641%;
    width: 89.7435897436%;
  }
  .grid-content-9:last-child {
    margin-right: 0;
  }
  .grid-content-push-9 {
    margin-left: 92.3076923077%;
  }
  .grid-content-8 {
    float: left;
    display: block;
    margin-right: 2.5641025641%;
    width: 79.4871794872%;
  }
  .grid-content-8:last-child {
    margin-right: 0;
  }
  .grid-content-push-8 {
    margin-left: 82.0512820513%;
  }
  .grid-content-7 {
    float: left;
    display: block;
    margin-right: 2.5641025641%;
    width: 69.2307692308%;
  }
  .grid-content-7:last-child {
    margin-right: 0;
  }
  .grid-content-push-7 {
    margin-left: 71.7948717949%;
  }
  .grid-content-6 {
    float: left;
    display: block;
    margin-right: 2.5641025641%;
    width: 58.9743589744%;
  }
  .grid-content-6:last-child {
    margin-right: 0;
  }
  .grid-content-push-6 {
    margin-left: 61.5384615385%;
  }
  .grid-content-5 {
    float: left;
    display: block;
    margin-right: 2.5641025641%;
    width: 48.7179487179%;
  }
  .grid-content-5:last-child {
    margin-right: 0;
  }
  .grid-content-push-5 {
    margin-left: 51.2820512821%;
  }
  .grid-content-4 {
    float: left;
    display: block;
    margin-right: 2.5641025641%;
    width: 38.4615384615%;
  }
  .grid-content-4:last-child {
    margin-right: 0;
  }
  .grid-content-push-4 {
    margin-left: 41.0256410256%;
  }
  .grid-content-3 {
    float: left;
    display: block;
    margin-right: 2.5641025641%;
    width: 28.2051282051%;
  }
  .grid-content-3:last-child {
    margin-right: 0;
  }
  .grid-content-push-3 {
    margin-left: 30.7692307692%;
  }
  .grid-content-2 {
    float: left;
    display: block;
    margin-right: 2.5641025641%;
    width: 17.9487179487%;
  }
  .grid-content-2:last-child {
    margin-right: 0;
  }
  .grid-content-push-2 {
    margin-left: 20.5128205128%;
  }
  .grid-content-1 {
    float: left;
    display: block;
    margin-right: 2.5641025641%;
    width: 7.6923076923%;
  }
  .grid-content-1:last-child {
    margin-right: 0;
  }
  .grid-content-push-1 {
    margin-left: 10.2564102564%;
  }
  .grid-content--desktop-push-1 {
    margin-left: 10.2564102564%;
  }
  .grid-content--desktop-push-2 {
    margin-left: 20.5128205128%;
  }
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .grid-content-10 {
    width: 690px;
  }
  .grid-content-push-10 {
    margin-left: 720px;
  }
  .grid-content-9 {
    width: 618px;
    margin-right: 30px;
  }
  .grid-content-push-9 {
    margin-left: 648px;
  }
  .grid-content-8 {
    width: 546px;
    margin-right: 30px;
  }
  .grid-content-push-8 {
    margin-left: 576px;
  }
  .grid-content-7 {
    width: 474px;
    margin-right: 30px;
  }
  .grid-content-push-7 {
    margin-left: 504px;
  }
  .grid-content-6 {
    width: 402px;
    margin-right: 30px;
  }
  .grid-content-push-6 {
    margin-left: 432px;
  }
  .grid-content-5 {
    width: 330px;
    margin-right: 30px;
  }
  .grid-content-push-5 {
    margin-left: 360px;
  }
  .grid-content-4 {
    width: 258px;
    margin-right: 30px;
  }
  .grid-content-push-4 {
    margin-left: 288px;
  }
  .grid-content-3 {
    width: 186px;
    margin-right: 30px;
  }
  .grid-content-push-3 {
    margin-left: 216px;
  }
  .grid-content-2 {
    width: 114px;
    margin-right: 30px;
  }
  .grid-content-push-2 {
    margin-left: 144px;
  }
  .grid-content-1 {
    width: 42px;
    margin-right: 30px;
  }
  .grid-content-push-1 {
    margin-left: 72px;
  }
  .grid-content--tablet-10 {
    width: 690px;
  }
  .grid-content--tablet-push-10 {
    margin-left: 720px;
  }
  .grid-content--tablet-9 {
    width: 618px;
    margin-right: 30px;
  }
  .grid-content--tablet-push-9 {
    margin-left: 648px;
  }
  .grid-content--tablet-8 {
    width: 546px;
    margin-right: 30px;
  }
  .grid-content--tablet-push-8 {
    margin-left: 576px;
  }
  .grid-content--tablet-7 {
    width: 474px;
    margin-right: 30px;
  }
  .grid-content--tablet-push-7 {
    margin-left: 504px;
  }
  .grid-content--tablet-6 {
    width: 402px;
    margin-right: 30px;
  }
  .grid-content--tablet-push-6 {
    margin-left: 432px;
  }
  .grid-content--tablet-5 {
    width: 330px;
    margin-right: 30px;
  }
  .grid-content--tablet-push-5 {
    margin-left: 360px;
  }
  .grid-content--tablet-4 {
    width: 258px;
    margin-right: 30px;
  }
  .grid-content--tablet-push-4 {
    margin-left: 288px;
  }
  .grid-content--tablet-3 {
    width: 186px;
    margin-right: 30px;
  }
  .grid-content--tablet-push-3 {
    margin-left: 216px;
  }
  .grid-content--tablet-2 {
    width: 114px;
    margin-right: 30px;
  }
  .grid-content--tablet-push-2 {
    margin-left: 144px;
  }
  .grid-content--tablet-1 {
    width: 42px;
    margin-right: 30px;
  }
  .grid-content--tablet-push-1 {
    margin-left: 72px;
  }
}

.grid {
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 5%;
  padding-right: 5%;
}

.grid:not(.grid--vertically-centered)::after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 100em) {
  .grid {
    padding-left: 80px;
    padding-right: 80px;
  }
}

.grid .grid {
  padding-left: 0;
  padding-right: 0;
  margin-left: -4px;
  margin-right: -4px;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .grid .grid {
    margin-left: -9px;
    margin-right: -9px;
  }
}

@media screen and (min-width: 67.5em) {
  .grid .grid {
    margin-left: -18px;
    margin-right: -18px;
  }
}

.grid--bleed {
  padding-left: 0;
  padding-right: 0;
}

.grid--layout-mode {
  padding-left: 0;
  padding-right: 0;
}

.grid--layout-mode .grid {
  margin-left: 0;
  margin-right: 0;
}

.grid--layout-mode .grid__item {
  padding-left: 0;
  padding-right: 0;
}

.grid--equal-height,
.grid--vertically-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.grid--vertically-centered {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.grid__item {
  display: block;
  position: relative;
  width: 100%;
  float: left;
  padding-left: 4px;
  padding-right: 4px;
}

@media screen and (min-width: 67.5em) {
  .grid__item {
    padding-left: 18px;
    padding-right: 18px;
  }
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .grid__item {
    padding-left: 9px;
    padding-right: 9px;
  }
}

.grid__item--mobile-up-1 {
  width: 25%;
}

.grid__item--mobile-up-2 {
  width: 50%;
}

.grid__item--mobile-up-3 {
  width: 75%;
}

.grid__item--mobile-up-4 {
  width: 100%;
}

.grid__item--mobile-up-full {
  width: 100%;
}

.grid__item--mobile-up-half {
  width: 50%;
}

.grid__item--mobile-up-third {
  width: 33.3333333333%;
}

.grid__item--mobile-up-two-thirds {
  width: 66.6666666667%;
}

.grid__item--mobile-up-quarter {
  width: 25%;
}

.grid__item--mobile-up-three-quarters {
  width: 75%;
}

.grid__item--mobile-up-align-center {
  float: none;
  clear: left;
  margin-left: auto;
  margin-right: auto;
}

.grid__item--mobile-up-align-left {
  float: left;
  clear: none;
  margin-left: 0;
  margin-right: 0;
}

.grid__item--mobile-up-offset-1 {
  margin-left: 25%;
}

.grid__item--mobile-up-offset-2 {
  margin-left: 50%;
}

.grid__item--mobile-up-push-1 {
  left: 25%;
}

.grid__item--mobile-up-pull-1 {
  right: 25%;
}

.grid__item--mobile-up-push-2 {
  left: 50%;
}

.grid__item--mobile-up-pull-2 {
  right: 50%;
}

.grid__item--mobile-up-push-3 {
  left: 75%;
}

.grid__item--mobile-up-pull-3 {
  right: 75%;
}

.grid__item--mobile-up-push-4 {
  left: 100%;
}

.grid__item--mobile-up-pull-4 {
  right: 100%;
}

.grid__item--mobile-up-push-full {
  left: 100%;
}

.grid__item--mobile-up-pull-full {
  right: 100%;
}

.grid__item--mobile-up-push-half {
  left: 50%;
}

.grid__item--mobile-up-pull-half {
  right: 50%;
}

.grid__item--mobile-up-push-third {
  left: 33.3333333333%;
}

.grid__item--mobile-up-pull-third {
  right: 33.3333333333%;
}

.grid__item--mobile-up-push-two-thirds {
  left: 66.6666666667%;
}

.grid__item--mobile-up-pull-two-thirds {
  right: 66.6666666667%;
}

.grid__item--mobile-up-push-quarter {
  left: 25%;
}

.grid__item--mobile-up-pull-quarter {
  right: 25%;
}

.grid__item--mobile-up-push-three-quarters {
  left: 75%;
}

.grid__item--mobile-up-pull-three-quarters {
  right: 75%;
}

[class*="grid__item--mobile-up-push"] {
  right: auto;
}

[class*="grid__item--mobile-up-pull"] {
  left: auto;
}

@media screen and (min-width: 46.875em) {
  .grid__item--tablet-up-1 {
    width: 16.6666666667%;
  }
  .grid__item--tablet-up-2 {
    width: 33.3333333333%;
  }
  .grid__item--tablet-up-3 {
    width: 50%;
  }
  .grid__item--tablet-up-4 {
    width: 66.6666666667%;
  }
  .grid__item--tablet-up-5 {
    width: 83.3333333333%;
  }
  .grid__item--tablet-up-6 {
    width: 100%;
  }
  .grid__item--tablet-up-full {
    width: 100%;
  }
  .grid__item--tablet-up-half {
    width: 50%;
  }
  .grid__item--tablet-up-third {
    width: 33.3333333333%;
  }
  .grid__item--tablet-up-two-thirds {
    width: 66.6666666667%;
  }
  .grid__item--tablet-up-quarter {
    width: 25%;
  }
  .grid__item--tablet-up-three-quarters {
    width: 75%;
  }
  .grid__item--tablet-up-align-center {
    float: none;
    clear: left;
    margin-left: auto;
    margin-right: auto;
  }
  .grid__item--tablet-up-align-left {
    float: left;
    clear: none;
    margin-left: 0;
    margin-right: 0;
  }
  .grid__item--tablet-up-offset-1 {
    margin-left: 16.6666666667%;
  }
  .grid__item--tablet-up-offset-2 {
    margin-left: 33.3333333333%;
  }
  .grid__item--tablet-up-offset-3 {
    margin-left: 50%;
  }
  .grid__item--tablet-up-push-reset {
    left: inherit;
  }
  .grid__item--tablet-up-pull-reset {
    right: inherit;
  }
  .grid__item--tablet-up-push-1 {
    left: 16.6666666667%;
  }
  .grid__item--tablet-up-pull-1 {
    right: 16.6666666667%;
  }
  .grid__item--tablet-up-push-2 {
    left: 33.3333333333%;
  }
  .grid__item--tablet-up-pull-2 {
    right: 33.3333333333%;
  }
  .grid__item--tablet-up-push-3 {
    left: 50%;
  }
  .grid__item--tablet-up-pull-3 {
    right: 50%;
  }
  .grid__item--tablet-up-push-4 {
    left: 66.6666666667%;
  }
  .grid__item--tablet-up-pull-4 {
    right: 66.6666666667%;
  }
  .grid__item--tablet-up-push-5 {
    left: 83.3333333333%;
  }
  .grid__item--tablet-up-pull-5 {
    right: 83.3333333333%;
  }
  .grid__item--tablet-up-push-6 {
    left: 100%;
  }
  .grid__item--tablet-up-pull-6 {
    right: 100%;
  }
  .grid__item--tablet-up-push-full {
    left: 100%;
  }
  .grid__item--tablet-up-pull-full {
    right: 100%;
  }
  .grid__item--tablet-up-push-half {
    left: 50%;
  }
  .grid__item--tablet-up-pull-half {
    right: 50%;
  }
  .grid__item--tablet-up-push-third {
    left: 33.3333333333%;
  }
  .grid__item--tablet-up-pull-third {
    right: 33.3333333333%;
  }
  .grid__item--tablet-up-push-two-thirds {
    left: 66.6666666667%;
  }
  .grid__item--tablet-up-pull-two-thirds {
    right: 66.6666666667%;
  }
  .grid__item--tablet-up-push-quarter {
    left: 25%;
  }
  .grid__item--tablet-up-pull-quarter {
    right: 25%;
  }
  .grid__item--tablet-up-push-three-quarters {
    left: 75%;
  }
  .grid__item--tablet-up-pull-three-quarters {
    right: 75%;
  }
  [class*="grid__item--tablet-up-push"] {
    right: auto;
  }
  [class*="grid__item--tablet-up-pull"] {
    left: auto;
  }
}

@media screen and (min-width: 67.5em) {
  .grid__item--desktop-up-1 {
    width: 8.3333333333%;
  }
  .grid__item--desktop-up-2 {
    width: 16.6666666667%;
  }
  .grid__item--desktop-up-3 {
    width: 25%;
  }
  .grid__item--desktop-up-4 {
    width: 33.3333333333%;
  }
  .grid__item--desktop-up-5 {
    width: 41.6666666667%;
  }
  .grid__item--desktop-up-6 {
    width: 50%;
  }
  .grid__item--desktop-up-7 {
    width: 58.3333333333%;
  }
  .grid__item--desktop-up-8 {
    width: 66.6666666667%;
  }
  .grid__item--desktop-up-9 {
    width: 75%;
  }
  .grid__item--desktop-up-10 {
    width: 83.3333333333%;
  }
  .grid__item--desktop-up-11 {
    width: 91.6666666667%;
  }
  .grid__item--desktop-up-12 {
    width: 100%;
  }
  .grid__item--desktop-up-full {
    width: 100%;
  }
  .grid__item--desktop-up-half {
    width: 50%;
  }
  .grid__item--desktop-up-third {
    width: 33.3333333333%;
  }
  .grid__item--desktop-up-two-thirds {
    width: 66.6666666667%;
  }
  .grid__item--desktop-up-quarter {
    width: 25%;
  }
  .grid__item--desktop-up-three-quarters {
    width: 75%;
  }
  .grid__item--desktop-up-align-center {
    float: none;
    clear: left;
    margin-left: auto;
    margin-right: auto;
  }
  .grid__item--desktop-up-align-left {
    float: left;
    clear: none;
    margin-left: 0;
    margin-right: 0;
  }
  .grid__item--desktop-up-offset-1 {
    margin-left: 8.3333333333%;
  }
  .grid__item--desktop-up-offset-2 {
    margin-left: 16.6666666667%;
  }
  .grid__item--desktop-up-offset-3 {
    margin-left: 25%;
  }
  .grid__item--desktop-up-offset-4 {
    margin-left: 33.3333333333%;
  }
  .grid__item--desktop-up-offset-5 {
    margin-left: 41.6666666667%;
  }
  .grid__item--desktop-up-offset-6 {
    margin-left: 50%;
  }
  .grid__item--desktop-up-push-reset {
    left: inherit;
  }
  .grid__item--desktop-up-pull-reset {
    right: inherit;
  }
  .grid__item--desktop-up-push-1 {
    left: 8.3333333333%;
  }
  .grid__item--desktop-up-pull-1 {
    right: 8.3333333333%;
  }
  .grid__item--desktop-up-push-2 {
    left: 16.6666666667%;
  }
  .grid__item--desktop-up-pull-2 {
    right: 16.6666666667%;
  }
  .grid__item--desktop-up-push-3 {
    left: 25%;
  }
  .grid__item--desktop-up-pull-3 {
    right: 25%;
  }
  .grid__item--desktop-up-push-4 {
    left: 33.3333333333%;
  }
  .grid__item--desktop-up-pull-4 {
    right: 33.3333333333%;
  }
  .grid__item--desktop-up-push-5 {
    left: 41.6666666667%;
  }
  .grid__item--desktop-up-pull-5 {
    right: 41.6666666667%;
  }
  .grid__item--desktop-up-push-6 {
    left: 50%;
  }
  .grid__item--desktop-up-pull-6 {
    right: 50%;
  }
  .grid__item--desktop-up-push-7 {
    left: 58.3333333333%;
  }
  .grid__item--desktop-up-pull-7 {
    right: 58.3333333333%;
  }
  .grid__item--desktop-up-push-8 {
    left: 66.6666666667%;
  }
  .grid__item--desktop-up-pull-8 {
    right: 66.6666666667%;
  }
  .grid__item--desktop-up-push-9 {
    left: 75%;
  }
  .grid__item--desktop-up-pull-9 {
    right: 75%;
  }
  .grid__item--desktop-up-push-10 {
    left: 83.3333333333%;
  }
  .grid__item--desktop-up-pull-10 {
    right: 83.3333333333%;
  }
  .grid__item--desktop-up-push-11 {
    left: 91.6666666667%;
  }
  .grid__item--desktop-up-pull-11 {
    right: 91.6666666667%;
  }
  .grid__item--desktop-up-push-12 {
    left: 100%;
  }
  .grid__item--desktop-up-pull-12 {
    right: 100%;
  }
  .grid__item--desktop-up-push-full {
    left: 100%;
  }
  .grid__item--desktop-up-pull-full {
    right: 100%;
  }
  .grid__item--desktop-up-push-half {
    left: 50%;
  }
  .grid__item--desktop-up-pull-half {
    right: 50%;
  }
  .grid__item--desktop-up-push-third {
    left: 33.3333333333%;
  }
  .grid__item--desktop-up-pull-third {
    right: 33.3333333333%;
  }
  .grid__item--desktop-up-push-two-thirds {
    left: 66.6666666667%;
  }
  .grid__item--desktop-up-pull-two-thirds {
    right: 66.6666666667%;
  }
  .grid__item--desktop-up-push-quarter {
    left: 25%;
  }
  .grid__item--desktop-up-pull-quarter {
    right: 25%;
  }
  .grid__item--desktop-up-push-three-quarters {
    left: 75%;
  }
  .grid__item--desktop-up-pull-three-quarters {
    right: 75%;
  }
  [class*="grid__item--desktop-up-push"] {
    right: auto;
  }
  [class*="grid__item--desktop-up-pull"] {
    left: auto;
  }
}

.marketing-button {
  display: inline-block;
  padding: 1.0625em 1.875em;
  border-radius: 5px;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1em;
  line-height: 1.133;
  -webkit-font-smoothing: antialiased;
  -webkit-transition: 150ms ease;
  transition: 150ms ease;
  -webkit-transition-property: background-color, border-color, color,
    -webkit-box-shadow;
  transition-property: background-color, border-color, color, -webkit-box-shadow;
  transition-property: background-color, border-color, box-shadow, color;
  transition-property: background-color, border-color, box-shadow, color,
    -webkit-box-shadow;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #262626;
  color: #f9f6f4;
  border-width: 0;
  border-style: solid;
  border-color: transparent;
}

.marketing-button,
.marketing-button:hover,
.marketing-button:focus {
  text-decoration: none;
}

.marketing-button:focus {
  outline: 0;
}

@media screen and (min-width: 46.875em) {
  .marketing-button + .marketing-button {
    margin-left: 15px;
  }
}

.marketing-button:hover,
.marketing-button:focus {
  background-color: #393939;
  color: #ffffff;
}

.marketing-button:active {
  background-color: #000639;
  color: #ffffff;
  border-color: #000639;
}

.marketing-button:hover {
  -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
}

.marketing-button:focus {
  -webkit-box-shadow: 0 0 0.1875em 0.1875em rgba(92, 106, 196, 0.5);
  box-shadow: 0 0 0.1875em 0.1875em rgba(92, 106, 196, 0.5);
}

.marketing-button[disabled],
.marketing-form--is-loading .marketing-button {
  -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #919ea9;
  color: #ffffff;
  cursor: not-allowed;
}

.marketing-button[disabled]:hover,
.marketing-button[disabled]:focus,
.marketing-form--is-loading .marketing-button:hover,
.marketing-form--is-loading .marketing-button:focus {
  background-color: #808f9c;
  color: #ffffff;
}

.marketing-button[disabled]:active,
.marketing-form--is-loading .marketing-button:active {
  background-color: #748593;
  color: #ffffff;
}

.marketing-button[disabled]:hover,
.marketing-form--is-loading .marketing-button:hover {
  -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
}

.marketing-button[disabled]:focus,
.marketing-form--is-loading .marketing-button:focus {
  -webkit-box-shadow: 0 0 0.1875em 0.1875em rgba(145, 158, 169, 0.5);
  box-shadow: 0 0 0.1875em 0.1875em rgba(145, 158, 169, 0.5);
}

.marketing-button[disabled]:hover,
.marketing-button[disabled]:focus,
.marketing-button[disabled]:active,
.marketing-form--is-loading .marketing-button:hover,
.marketing-form--is-loading .marketing-button:focus,
.marketing-form--is-loading .marketing-button:active {
  background-color: #919ea9;
}

.marketing-form--is-loading button.marketing-button {
  position: relative;
}

.marketing-form--is-loading button.marketing-button,
.marketing-form--is-loading button.marketing-button:hover,
.marketing-form--is-loading button.marketing-button:focus {
  color: #919ea9;
}

.marketing-form--is-loading button.marketing-button::after {
  content: "";
  display: block;
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -12px;
  margin-top: -12px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  border-top-color: transparent;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.marketing-button--block {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.marketing-button--secondary {
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
  color: #262626;
  border-width: 0.125em;
  border-style: solid;
  border-color: #262626;
  padding: 1em 1.75em;
}

.marketing-button--secondary:hover,
.marketing-button--secondary:focus {
  background-color: #262626;
  color: #ffffff;
}

.marketing-button--secondary:active {
  background-color: #273d5f;
  color: #ffffff;
  border-color: #273d5f;
}

.marketing-button--secondary:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.marketing-button--secondary:focus {
  -webkit-box-shadow: 0 0 0.1875em 0.1875em rgba(92, 106, 196, 0.5);
  box-shadow: 0 0 0.1875em 0.1875em rgba(92, 106, 196, 0.5);
}

.marketing-button--small {
  padding: 0.8125em 1.875em;
}

.marketing-button--small.marketing-button--secondary {
  padding: 0.6875em 1.75em;
}

.marketing-button--skin-teal {
  -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #47c1bf;
  color: #212b35;
}

.marketing-button--skin-teal:hover,
.marketing-button--skin-teal:focus {
  background-color: #28a2a0;
  color: #212b35;
}

.marketing-button--skin-teal:active {
  background-color: #28a2a0;
  color: #212b35;
}

.marketing-button--skin-teal:hover {
  -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
}

.marketing-button--skin-teal:focus {
  -webkit-box-shadow: 0 0 0.1875em 0.1875em rgba(40, 162, 160, 0.5);
  box-shadow: 0 0 0.1875em 0.1875em rgba(40, 162, 160, 0.5);
}

.marketing-button--skin-salmon {
  -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #fb8b8a;
  color: #212b35;
}

.marketing-button--skin-salmon:hover,
.marketing-button--skin-salmon:focus {
  background-color: #e27271;
  color: #212b35;
}

.marketing-button--skin-salmon:active {
  background-color: #e27271;
  color: #212b35;
}

.marketing-button--skin-salmon:hover {
  -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
}

.marketing-button--skin-salmon:focus {
  -webkit-box-shadow: 0 0 0.1875em 0.1875em rgba(226, 114, 113, 0.5);
  box-shadow: 0 0 0.1875em 0.1875em rgba(226, 114, 113, 0.5);
}

.marketing-button--skin-lowlight {
  -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #30373b;
  color: #ffffff;
}

.marketing-button--skin-lowlight:hover,
.marketing-button--skin-lowlight:focus {
  background-color: #22272a;
  color: #ffffff;
}

.marketing-button--skin-lowlight:active {
  background-color: #191d1f;
  color: #ffffff;
}

.marketing-button--skin-lowlight:hover {
  -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
}

.marketing-button--skin-lowlight:focus {
  -webkit-box-shadow: 0 0 0.1875em 0.1875em rgba(48, 55, 59, 0.5);
  box-shadow: 0 0 0.1875em 0.1875em rgba(48, 55, 59, 0.5);
}

.marketing-button--skin-light {
  -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
  color: #5c6ac4;
}

.marketing-button--skin-light:hover,
.marketing-button--skin-light:focus {
  background-color: #f0f0f0;
  color: #5c6ac4;
}

.marketing-button--skin-light:active {
  background-color: #e6e6e6;
  color: #5c6ac4;
}

.marketing-button--skin-light:hover {
  -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
}

.marketing-button--skin-light:focus {
  -webkit-box-shadow: 0 0 0.1875em 0.1875em rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 0.1875em 0.1875em rgba(255, 255, 255, 0.5);
}

.marketing-button--skin-reset {
  margin: 0;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

.marketing-button--skin-reset:focus,
.marketing-button--skin-reset:hover,
.marketing-button--skin-reset:active {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.marketing-button--skin-reset:focus,
.marketing-button--skin-reset:hover {
  color: #5c6ac4;
}

.marketing-button--skin-reset:active {
  color: #202e78;
}

.marketing-button-wrapper {
  display: inline;
}

@media screen and (min-width: 46.875em) {
  .marketing-form--inline {
    text-align: center;
  }
}

.marketing-form--inline .marketing-input-wrapper {
  text-align: left;
}

@media screen and (min-width: 46.875em) {
  .marketing-form--inline .marketing-input-wrapper {
    margin-bottom: 0;
  }
}

.marketing-form--inline__heading {
  text-align: center;
}

.marketing-form--inline__subheading {
  color: #454f5b;
  text-align: center;
  margin-bottom: 1.25em;
}

.marketing-form--lowlight .marketing-radio-label,
.marketing-form--lowlight .marketing-checkbox-label {
  color: #ffffff;
}

.marketing-form__hidden-on-success.js-is-hidden {
  display: none;
}

.marketing-form__fallback-cta {
  margin-top: 15px;
  color: #454f5b;
}

.marketing-input-wrapper {
  position: relative;
  display: block;
  margin-bottom: 15px;
}

.marketing-input-wrapper .marketing-select-wrapper,
.marketing-input-wrapper .marketing-input,
.marketing-input-wrapper .marketing-textarea {
  margin-bottom: 0;
}

.marketing-input-wrapper.js-is-hidden {
  display: none;
}

.marketing-input,
.marketing-textarea,
.marketing-select-wrapper > select {
  display: inline-block;
  height: 3.2em;
  width: 100%;
  margin: 0 0 15px;
  padding: 1em 1em;
  color: #30373b;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
  font-size: 1em;
  -webkit-box-shadow: 0 0 0 1px #c4cdd5;
  box-shadow: 0 0 0 1px #c4cdd5;
  border: 0;
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-transition: padding 150ms;
  transition: padding 150ms;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.marketing-block--lowlight .marketing-input,
.marketing-form--lowlight .marketing-input,
.page-block--lowlight .marketing-input,
.marketing-block--lowlight .marketing-textarea,
.marketing-form--lowlight .marketing-textarea,
.page-block--lowlight .marketing-textarea,
.marketing-block--lowlight .marketing-select-wrapper > select,
.marketing-form--lowlight .marketing-select-wrapper > select,
.page-block--lowlight .marketing-select-wrapper > select {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.js-is-filled .marketing-input,
.js-is-filled .marketing-textarea,
.js-is-filled .marketing-select-wrapper > select {
  padding-top: 1.5em;
  padding-bottom: 0.5em;
}

.marketing-input:focus,
.marketing-input.focus,
.marketing-textarea:focus,
.marketing-textarea.focus,
.marketing-select-wrapper > select:focus,
.marketing-select-wrapper > select.focus {
  color: #000000;
  outline: 0;
  -webkit-box-shadow: 0 0 0 2px #5c6ac4;
  box-shadow: 0 0 0 2px #5c6ac4;
}

.marketing-input:focus + .marketing-form__messages .error + .suggest,
.marketing-input.focus + .marketing-form__messages .error + .suggest,
.marketing-textarea:focus + .marketing-form__messages .error + .suggest,
.marketing-textarea.focus + .marketing-form__messages .error + .suggest,
.marketing-select-wrapper
  > select:focus
  + .marketing-form__messages
  .error
  + .suggest,
.marketing-select-wrapper
  > select.focus
  + .marketing-form__messages
  .error
  + .suggest {
  display: none;
}

.marketing-input:focus + .marketing-form__messages .error + .is-visible,
.marketing-input.focus + .marketing-form__messages .error + .is-visible,
.marketing-textarea:focus + .marketing-form__messages .error + .is-visible,
.marketing-textarea.focus + .marketing-form__messages .error + .is-visible,
.marketing-select-wrapper
  > select:focus
  + .marketing-form__messages
  .error
  + .is-visible,
.marketing-select-wrapper
  > select.focus
  + .marketing-form__messages
  .error
  + .is-visible {
  display: block;
}

.marketing-input[disabled],
.marketing-textarea[disabled],
.marketing-select-wrapper > select[disabled] {
  background-color: #dfe3e8;
}

.marketing-input::-webkit-input-placeholder,
.marketing-textarea::-webkit-input-placeholder {
  color: #637381;
}

.marketing-input:-ms-input-placeholder,
.marketing-textarea:-ms-input-placeholder {
  color: #637381;
}

.marketing-input::-ms-input-placeholder,
.marketing-textarea::-ms-input-placeholder {
  color: #637381;
}

.marketing-input::placeholder,
.marketing-textarea::placeholder {
  color: #637381;
}

.marketing-input--error,
.marketing-block--lowlight .marketing-input--error,
.marketing-form--lowlight .marketing-input--error,
.page-block--lowlight .marketing-input--error {
  -webkit-box-shadow: 0 0 0 2px #eb644d;
  box-shadow: 0 0 0 2px #eb644d;
}

.marketing-textarea {
  height: auto;
  min-height: 3.2em;
}

.marketing-select-wrapper {
  position: relative;
  display: block;
  margin-bottom: 15px;
}

.marketing-select-wrapper > select {
  margin: 0;
  padding-top: 1.375em;
  padding-bottom: 0.375em;
  padding-right: 3em;
}

.marketing-select-wrapper > select::-ms-expand {
  display: none;
}

.marketing-select-wrapper::after {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  right: 2.5em;
  height: 1.25em;
  width: 1px;
  background-color: #c4cdd5;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.marketing-select-wrapper::before {
  pointer-events: none;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20baseProfile=%22tiny%22%20viewBox=%220%200%2010.289%206.563%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23919eab%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M5.212%206.563L0%201.423%201.404%200l3.788%203.735L8.865.01l1.424%201.404%22/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: center;
  height: 0.75em;
  width: 0.75em;
  margin-bottom: -0.5625em;
  display: block;
  position: absolute;
  top: 50%;
  right: 1em;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.marketing-label {
  display: block;
  margin-bottom: 0.6153846154em;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 0.8125em;
  color: #454f5b;
}

.marketing-label--in-field {
  position: absolute;
  left: 1rem;
  top: 0.5rem;
  font-size: 0.6875em;
  font-weight: 400;
  pointer-events: none;
  z-index: 10;
}

.marketing-label--floating {
  opacity: 0;
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
}

.js-is-filled .marketing-label--floating {
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.marketing-label--hidden {
  display: inline;
}

.marketing-form__messages {
  font-size: 0.8125em;
  font-weight: 500;
}

.marketing-form__messages .error,
.marketing-form__messages .success,
.marketing-form__messages .suggest {
  display: block;
  padding: 0.6153846154em 0 0;
}

.marketing-form__messages .error {
  color: #eb644d;
}

.marketing-form__messages .error a {
  color: #eb644d;
  border-bottom: 1px solid #eb644d;
}

.marketing-form__messages .success {
  display: none;
  color: #5c6ac4;
}

.marketing-form__messages .success a {
  color: #eb644d;
  border-bottom: 1px solid #eb644d;
}

.marketing-form__messages .success.is-visible,
.marketing-form--has-success .marketing-form__messages .success {
  display: block;
}

.marketing-form__messages .suggest {
  display: none;
  color: #637381;
}

.marketing-block--lowlight .marketing-form__messages .suggest,
.marketing-form--lowlight .marketing-form__messages .suggest,
.page-block--lowlight .marketing-form__messages .suggest {
  color: #ffffff;
}

.marketing-form__messages .suggest.is-visible {
  display: block;
}

.marketing-form__ajax-success {
  display: none;
}

.marketing-form__ajax-success.js-is-visible {
  display: block;
}

.marketing-checkbox,
.marketing-radio {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  margin-bottom: 15px;
  float: left;
}

.marketing-checkbox:checked + label::after,
.marketing-radio:checked + label::after {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
}

.marketing-checkbox:focus + label::before,
.marketing-radio:focus + label::before,
.marketing-checkbox:focus:checked + label::before,
.marketing-radio:focus:checked + label::before {
  outline: none;
  border-color: #5c6ac4;
  -webkit-box-shadow: 0 0 4px 0 #5c6ac4;
  box-shadow: 0 0 4px 0 #5c6ac4;
}

.marketing-checkbox-label,
.marketing-radio-label {
  display: block;
  text-align: left;
  position: relative;
  margin-bottom: 15px;
  padding-left: 30px;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.marketing-checkbox-label:hover::before,
.marketing-radio-label:hover::before {
  border-color: #5c6ac4;
}

.marketing-checkbox-label::before,
.marketing-radio-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  width: 20px;
  background-color: #ffffff;
  border: 1px solid #c4cdd5;
  border-radius: 5px;
  -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
  -webkit-transition: border-color 150ms ease;
  transition: border-color 150ms ease;
}

.marketing-checkbox--large + label,
.marketing-radio--large + label {
  padding-left: 42px;
  line-height: 32px;
}

.marketing-checkbox--large + label::before,
.marketing-radio--large + label::before {
  width: 32px;
  height: 32px;
}

.marketing-checkbox:checked + label::before {
  background-color: #5c6ac4;
  border-color: #5c6ac4;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.marketing-checkbox:checked + label::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2040.7%2040%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23ffffff%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M16.7%2030l-7.4-7.9c-.8-.8-.6-2%20.4-2.6%201-.6%202.4-.5%203.1.3l3.7%204%2011.3-13c.7-.8%202.1-1%203.1-.4s1.2%201.8.5%202.6L16.7%2030z%22/%3E%3C/svg%3E");
  width: 20px;
  height: 20px;
}

.marketing-checkbox--large:checked + label::after {
  width: 32px;
  height: 32px;
}

.marketing-radio:checked + label::after {
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #5c6ac4;
}

.marketing-radio:checked + label::before {
  border-color: #5c6ac4;
}

.marketing-radio-label::before {
  border-radius: 50%;
}

.marketing-radio--large:checked + label::after {
  left: 8px;
  top: 8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
}

.marketing-checkbox-label--inline,
.marketing-radio-label--inline {
  margin-right: 0.9375em;
  margin-bottom: 0;
  display: inline-block;
}

.marketing-input-suffix-wrapper {
  position: relative;
}

.marketing-input-suffix {
  position: absolute;
  top: 0;
  right: 0;
  padding-top: 1em;
  padding-bottom: 1em;
  padding-right: 1em;
  background-color: white;
  color: #637381;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
  font-size: 1em;
  line-height: 1;
  z-index: 1;
  -webkit-transition: opacity 150ms;
  transition: opacity 150ms;
  border-radius: 5px;
}

.icon {
  display: inline-block;
  height: 1em;
  width: 0.8em;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.icon--size-large {
  width: 60px;
  height: 60px;
}

.icon--size-small {
  width: 40px;
  height: 40px;
}

.icon--primary {
  fill: #5c6ac4;
}

.icon--grey {
  fill: #919ea9;
}

.icon--black {
  fill: #000000;
}

.icon--white {
  fill: #ffffff;
}

.icon--yellow {
  fill: #fdd991;
}

.icon--green {
  fill: #58b44b;
}

.icon--blue {
  fill: #368aa9;
}

.icon--blue_light {
  fill: #479ccf;
}

.icon--blue_fb {
  fill: #385a98;
}

.icon--blue_twitter {
  fill: #55acee;
}

.icon--blue_linkedin {
  fill: #0976b4;
}

.icon--red_pinterest {
  fill: #bd081c;
}

.icon--fill-primary {
  fill: #5c6ac4;
}

.icon--fill-grey {
  fill: #919ea9;
}

.icon--fill-black {
  fill: #000000;
}

.icon--fill-white {
  fill: #ffffff;
}

.icon--fill-yellow {
  fill: #fdd991;
}

.icon--fill-green {
  fill: #58b44b;
}

.icon--fill-blue {
  fill: #368aa9;
}

.icon--fill-blue_light {
  fill: #479ccf;
}

.icon--fill-blue_fb {
  fill: #385a98;
}

.icon--fill-blue_twitter {
  fill: #55acee;
}

.icon--fill-blue_linkedin {
  fill: #0976b4;
}

.icon--fill-red_pinterest {
  fill: #bd081c;
}

img {
  max-width: 100%;
}

a img {
  border: 0;
}

img[data-srcset] {
  opacity: 0;
  -webkit-transition: opacity 150ms linear;
  transition: opacity 150ms linear;
}

img[data-srcset].lazyloaded {
  opacity: 1;
}

.marketing-nav-wrapper {
  max-width: 1600px;
  margin: 0 auto;
}

.marketing-nav {
  width: 100%;
  position: relative;
  clear: both;
}

.marketing-nav .popover-wrapper {
  display: block;
  cursor: auto;
}

.marketing-nav .popover {
  width: auto;
  min-width: calc(100% + 12px);
  top: 45px;
  left: 0;
  margin-top: 0;
  -webkit-transform: none;
  transform: none;
}

@media screen and (min-width: 67.5em) {
  .marketing-nav .popover {
    top: 65px;
  }
}

.marketing-nav .popover::before,
.marketing-nav .popover::after {
  content: none;
}

.marketing-nav .popover-content {
  padding: 12px 24px 12px 0;
  -webkit-box-shadow: 0 5px 30px 5px rgba(69, 79, 91, 0.2);
  box-shadow: 0 5px 30px 5px rgba(69, 79, 91, 0.2);
  border-radius: 5px;
  background-color: #ffffff;
}

.marketing-nav__primary {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  /*-ms-flex-wrap: wrap;
    flex-wrap: wrap;*/
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-between;
  padding-left: calc(5% + 4px);
  padding-right: calc(5% + 4px);
  background-color: #ffffff;
  border-bottom: 1px solid #dfe3e8;
}

@media screen and (min-width: 46.875em) {
  .marketing-nav__primary {
    padding-left: calc(5% + 9px);
    padding-right: calc(5% + 9px);
  }
}

@media screen and (max-width: 67.4375em) {
  .marketing-nav__primary {
    height: 60px;
  }
}

@media screen and (min-width: 67.5em) {
  .marketing-nav__primary {
    padding-left: calc(5% + 18px);
    padding-right: calc(5% + 18px);
  }
}

.marketing-nav__items {
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.marketing-nav__items > li {
  line-height: 60px;
}

@media screen and (min-width: 67.5em) {
  .marketing-nav__items > li {
    line-height: 80px;
  }
}

.marketing-nav__items > li.marketing-nav__item--nowrap {
  white-space: nowrap;
}

.marketing-nav__item,
.as-nav__link {
  display: block;
  white-space: nowrap;
  color: #212b35;
  font-size: 0.9375em;
  font-weight: 400;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
  -webkit-transition-property: border-color, fill;
  transition-property: border-color, fill;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
}

.marketing-nav__item:hover,
.as-nav__link:hover,
.marketing-nav__item:focus,
.as-nav__link:focus {
  color: #212b35;
}

.marketing-nav__item:active,
.as-nav__link:active,
.marketing-nav__item.marketing-nav__item--active,
.as-nav__item--primary.as-nav__item--active > .as-nav__link,
.marketing-nav__item--active.as-nav__link {
  color: #212b35;
}

.marketing-nav__item--primary {
  font-size: 0.9375em;
  font-weight: 700;
  color: #212b35;
}

@media screen and (min-width: 46.875em) {
  .marketing-nav__item--primary {
    margin-left: 12px;
    margin-right: 12px;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 3px solid transparent;
  }
  .marketing-nav__item--primary:hover,
  .marketing-nav__item--primary:focus {
    border-bottom-color: #919eab;
  }
  .marketing-nav__item--primary:active,
  .marketing-nav__item--primary.marketing-nav__item--active,
  .as-nav__item--primary.as-nav__item--active
    > .marketing-nav__item--primary.as-nav__link {
    border-bottom-color: #212b35;
  }
}

.marketing-nav__item--primary:hover,
.marketing-nav__item--primary:focus {
  color: #212b35;
  font-weight: 700;
}

.marketing-nav__item--primary:active,
.marketing-nav__item--primary.marketing-nav__item--active,
.as-nav__item--primary.as-nav__item--active
  > .marketing-nav__item--primary.as-nav__link {
  color: #212b35;
  font-weight: 700;
}

.marketing-nav__item--user {
  font-size: 0.9375em;
  font-weight: 700;
  color: #212b35;
}

@media screen and (min-width: 46.875em) {
  .marketing-nav__item--user {
    margin-left: 12px;
    margin-right: 12px;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 3px solid transparent;
  }
  .marketing-nav__item--user:hover,
  .marketing-nav__item--user:focus {
    border-bottom-color: #919eab;
  }
  .marketing-nav__item--user:active,
  .marketing-nav__item--user.marketing-nav__item--active,
  .as-nav__item--primary.as-nav__item--active
    > .marketing-nav__item--user.as-nav__link {
    border-bottom-color: #212b35;
  }
}

.marketing-nav__item--user:hover,
.marketing-nav__item--user:focus {
  color: #212b35;
  font-weight: 700;
}

.marketing-nav__item--user:active,
.marketing-nav__item--user.marketing-nav__item--active,
.as-nav__item--primary.as-nav__item--active
  > .marketing-nav__item--user.as-nav__link {
  color: #212b35;
  font-weight: 700;
}

.marketing-nav__item--secondary {
  font-size: 0.9375em;
  font-weight: 700;
  color: #637381;
}

@media screen and (min-width: 46.875em) {
  .marketing-nav__item--secondary {
    margin-left: 12px;
    margin-right: 12px;
    padding-left: 0;
    padding-right: 0;
    border-bottom: 3px solid transparent;
  }
  .marketing-nav__item--secondary:hover,
  .marketing-nav__item--secondary:focus {
    border-bottom-color: #919eab;
  }
  .marketing-nav__item--secondary:active,
  .marketing-nav__item--secondary.marketing-nav__item--active,
  .as-nav__item--primary.as-nav__item--active
    > .marketing-nav__item--secondary.as-nav__link {
    border-bottom-color: #212b35;
  }
}

.marketing-nav__item--secondary:hover,
.marketing-nav__item--secondary:focus {
  color: #212b35;
  font-weight: 700;
}

.marketing-nav__item--secondary:active,
.marketing-nav__item--secondary.marketing-nav__item--active,
.as-nav__item--primary.as-nav__item--active
  > .marketing-nav__item--secondary.as-nav__link {
  color: #212b35;
  font-weight: 700;
}

.marketing-nav__item--child,
.as-nav__link {
  font-size: 0.9375em;
  font-weight: 700;
  color: #637381;
}

@media screen and (min-width: 46.875em) {
  .marketing-nav__item--child,
  .as-nav__link {
    margin-left: 0;
    margin-right: 0;
    padding-left: 24px;
    padding-right: 24px;
  }
}

.marketing-nav__item--child:hover,
.as-nav__link:hover,
.marketing-nav__item--child:focus,
.as-nav__link:focus {
  color: #212b35;
  font-weight: 700;
}

.marketing-nav__item--child:active,
.as-nav__link:active,
.marketing-nav__item--child.marketing-nav__item--active,
.as-nav__item--primary.as-nav__item--active > .as-nav__link,
.marketing-nav__item--active.as-nav__link {
  color: #212b35;
  font-weight: 700;
}

.marketing-nav__item--primary {
  background-color: transparent;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.marketing-nav__item--primary:hover,
.marketing-nav__item--primary:focus {
  background-color: transparent;
}

.popover-wrapper.js-is-active > .marketing-nav__item--primary {
  background-color: #ffffff;
  color: #212b35;
}

.popover-wrapper.js-is-active > .marketing-nav__item--primary:focus {
  outline: 0;
}

.marketing-nav__item--primary.popover-trigger:hover {
  border-bottom: 1px solid #dfe3e8;
}

.popover-wrapper > .marketing-nav__item--primary {
  -webkit-transition: none;
  transition: none;
}

@media screen and (min-width: 46.875em) {
  .marketing-nav__item--primary,
  .marketing-nav__item--user {
    height: 60px;
  }
}

@media screen and (min-width: 67.5em) {
  .marketing-nav__item--primary,
  .marketing-nav__item--user {
    height: 80px;
  }
}

@media screen and (max-width: 46.8125em) {
  .marketing-nav__item--secondary {
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: calc(5% - 3px);
    border-left: 3px solid transparent;
  }
  .marketing-nav__item--secondary:hover,
  .marketing-nav__item--secondary:focus {
    border-left-color: #919eab;
  }
  .marketing-nav__item--secondary:active,
  .marketing-nav__item--secondary.marketing-nav__item--active,
  .as-nav__item--primary.as-nav__item--active
    > .marketing-nav__item--secondary.as-nav__link {
    border-left-color: #212b35;
  }
}

@media screen and (min-width: 46.875em) {
  .marketing-nav__item--secondary {
    height: 60px;
  }
}

@media screen and (min-width: 67.5em) {
  .marketing-nav__item--secondary {
    height: 70px;
  }
}

.marketing-nav__item--child,
.as-nav__link {
  padding-top: 9px;
  padding-bottom: 9px;
  line-height: 1;
}

@media screen and (min-width: 67.5em) {
  .marketing-nav__item--child,
  .as-nav__link {
    padding-left: calc(24px - 3px);
    border-left: 3px solid transparent;
    background-color: transparent;
  }
  .marketing-nav__item--child:hover,
  .as-nav__link:hover,
  .marketing-nav__item--child:focus,
  .as-nav__link:focus {
    border-left-color: #919eab;
    background-color: transparent;
  }
  .marketing-nav__item--child:active,
  .as-nav__link:active,
  .marketing-nav__item--child.marketing-nav__item--active,
  .as-nav__item--primary.as-nav__item--active > .as-nav__link,
  .marketing-nav__item--active.as-nav__link {
    border-left-color: #212b35;
    background-color: transparent;
  }
}

.marketing-nav__user {
  margin-right: 0;
}

.marketing-nav__button {
  margin-left: 12px;
  padding-left: 1.25em;
  padding-right: 1.25em;
}

.marketing-nav__secondary {
  position: relative;
  z-index: 1;
  min-height: 60px;
  background-color: #ffffff;
  border-bottom: 1px solid #dfe3e8;
}

@media screen and (max-width: 46.8125em) {
  .marketing-nav__secondary {
    border-top: 1px solid #dfe3e8;
  }
}

@media screen and (min-width: 67.5em) {
  .marketing-nav__secondary {
    min-height: 70px;
  }
}

@media screen and (max-width: 46.8125em) {
  .marketing-nav__secondary .marketing-nav__items {
    display: none;
    position: absolute;
    z-index: 10;
    width: 100%;
    top: 60px;
    left: 0;
    padding-top: 6px;
    padding-bottom: 6px;
    border-bottom: 1px solid #dfe3e8;
    background-color: #ffffff;
  }
}

@media screen and (min-width: 46.875em) {
  .marketing-nav__secondary .marketing-nav__items {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    margin-left: -12px;
    padding-left: calc(5% + 9px);
    padding-right: calc(5% + 9px);
  }
  .marketing-nav__secondary .marketing-nav__items::after {
    content: "";
    display: table;
    clear: both;
  }
}

@media screen and (min-width: 67.5em) {
  .marketing-nav__secondary .marketing-nav__items {
    padding-left: calc(5% + 18px);
    padding-right: calc(5% + 18px);
  }
}

@media screen and (min-width: 46.875em) {
  .marketing-nav__secondary .marketing-nav__items > li {
    line-height: 60px;
  }
}

@media screen and (min-width: 67.5em) {
  .marketing-nav__secondary .marketing-nav__items > li {
    line-height: 70px;
  }
}

.marketing-nav__secondary__button {
  width: 100%;
  padding-left: 5%;
  text-align: left;
  font-size: 0.9375em;
  line-height: 60px;
  color: #212b35;
}

@media screen and (min-width: 67.5em) {
  .marketing-nav__logo {
    margin-right: 1.5625em;
  }
}

@media screen and (max-width: 67.4375em) {
  .marketing-nav__logo--centered {
    margin-left: auto;
    margin-right: auto;
  }
}

.marketing-nav__logo .icon,
.marketing-nav__hamburger .icon {
  width: 100%;
  height: 100%;
}

.marketing-nav__logo__shopify {
  display: block;
  width: 160px;
  height: 32px;
}

@media screen and (min-width: 67.5em) {
  .marketing-nav__logo__shopify {
    width: 160px;
    height: 36px;
  }
}

.marketing-nav__hamburger {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 1.25em;
  height: 1.25em;
  padding: 0.625em;
  margin-right: -0.625em;
  fill: #212b35;
  margin-left: auto;
}

.marketing-nav__hamburger::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.marketing-nav__arrow,
.marketing-nav__external-indicator {
  display: inline-block;
  fill: #919eab;
}

.marketing-nav__item--primary .marketing-nav__arrow,
.marketing-nav__item--primary .marketing-nav__external-indicator {
  fill: #919eab;
}

.popover-wrapper.js-is-active
  .marketing-nav__item--primary
  .marketing-nav__arrow,
.marketing-nav__item--primary:hover .marketing-nav__arrow,
.marketing-nav__item--primary:focus .marketing-nav__arrow,
.marketing-nav__item--primary:active .marketing-nav__arrow,
.popover-wrapper.js-is-active
  .marketing-nav__item--primary
  .marketing-nav__external-indicator,
.marketing-nav__item--primary:hover .marketing-nav__external-indicator,
.marketing-nav__item--primary:focus .marketing-nav__external-indicator,
.marketing-nav__item--primary:active .marketing-nav__external-indicator {
  fill: #212b35;
}

.marketing-nav__item--user .marketing-nav__arrow,
.marketing-nav__item--user .marketing-nav__external-indicator {
  fill: #919eab;
}

.popover-wrapper.js-is-active .marketing-nav__item--user .marketing-nav__arrow,
.marketing-nav__item--user:hover .marketing-nav__arrow,
.marketing-nav__item--user:focus .marketing-nav__arrow,
.marketing-nav__item--user:active .marketing-nav__arrow,
.popover-wrapper.js-is-active
  .marketing-nav__item--user
  .marketing-nav__external-indicator,
.marketing-nav__item--user:hover .marketing-nav__external-indicator,
.marketing-nav__item--user:focus .marketing-nav__external-indicator,
.marketing-nav__item--user:active .marketing-nav__external-indicator {
  fill: #212b35;
}

.marketing-nav__item--secondary .marketing-nav__arrow,
.marketing-nav__item--secondary .marketing-nav__external-indicator {
  fill: #919eab;
}

.popover-wrapper.js-is-active
  .marketing-nav__item--secondary
  .marketing-nav__arrow,
.marketing-nav__item--secondary:hover .marketing-nav__arrow,
.marketing-nav__item--secondary:focus .marketing-nav__arrow,
.marketing-nav__item--secondary:active .marketing-nav__arrow,
.popover-wrapper.js-is-active
  .marketing-nav__item--secondary
  .marketing-nav__external-indicator,
.marketing-nav__item--secondary:hover .marketing-nav__external-indicator,
.marketing-nav__item--secondary:focus .marketing-nav__external-indicator,
.marketing-nav__item--secondary:active .marketing-nav__external-indicator {
  fill: #212b35;
}

.marketing-nav__arrow {
  vertical-align: middle;
  width: 10px;
  height: 5px;
  margin-left: 5px;
}

.marketing-nav__item--primary .marketing-nav__arrow {
  width: 10px;
  height: 5px;
  margin-left: 5px;
  margin-top: -3px;
}

.marketing-nav__item--secondary .marketing-nav__arrow {
  width: 10px;
  height: 5px;
  margin-left: 5px;
  margin-top: -3px;
}

@media screen and (max-width: 46.8125em) {
  .marketing-nav__secondary .marketing-nav__arrow {
    position: absolute;
    top: 50%;
    right: 5%;
    margin-top: -3px;
  }
}

.marketing-nav__external-indicator {
  vertical-align: middle;
  width: 7px;
  height: 7px;
  margin-left: 2px;
  -webkit-transition: fill 300ms;
  transition: fill 300ms;
}

.marketing-nav__item--primary .marketing-nav__external-indicator {
  width: 7px;
  height: 7px;
  margin-left: 2px;
  margin-top: -8px;
}

.marketing-nav__item--user .marketing-nav__external-indicator {
  width: 7px;
  height: 7px;
  margin-left: 2px;
  margin-top: -8px;
}

.marketing-nav__item--secondary .marketing-nav__external-indicator {
  width: 7px;
  height: 7px;
  margin-left: 2px;
  margin-top: -8px;
}

.marketing-nav__item--child .marketing-nav__external-indicator,
.as-nav__link .marketing-nav__external-indicator {
  width: 7px;
  height: 7px;
  margin-left: 2px;
  margin-top: -8px;
}

.skip-to-main.focusable:focus {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
  font-weight: 400;
  position: absolute;
  color: #000000;
  height: 60px;
  line-height: 60px;
  padding: 0 15px;
  background: #ffffff;
  z-index: 1001;
}

@media screen and (min-width: 67.5em) {
  .skip-to-main.focusable:focus {
    height: 80px;
    line-height: 80px;
  }
}

.footer--main {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  background: #c8e4d2;
  color: #262626;
}

.footer--main::after {
  content: "";
  display: table;
  clear: both;
}

.footer-section {
  margin-bottom: 1.875em;
}

.footer-section::after {
  content: "";
  display: table;
  clear: both;
}

.footer-heading {
  margin: 0 0 0.5625em;
  color: #ffffff;
  letter-spacing: normal;
}

.footer-link {
  display: block;
  font-size: 0.875em;
  padding: 0.375em 0;
  color: #919eab;
  -webkit-transition: color 300ms ease-in-out;
  transition: color 300ms ease-in-out;
}

.footer-link:hover,
.footer-link:focus {
  color: #ffffff;
}

.footer-top {
  padding-bottom: 1.5625em;
}

@media screen and (max-width: 46.8125em) {
  .footer-top {
    padding-top: 1.5625em;
  }
}

.footer-top + .footer-bottom {
  border-top: 1px solid rgba(223, 227, 232, 0.1);
}

.footer-bottom {
  padding-top: 1.875em;
  padding-bottom: 1.875em;
}

.footer-bottom::after {
  content: "";
  display: table;
  clear: both;
}

.footer-bottom a {
  color: #262626;
  -webkit-transition: color 300ms ease-in-out;
  transition: color 300ms ease-in-out;
}

@media screen and (min-width: 67.5em) {
  .footer-bottom a {
    line-height: 1.875em;
  }
}

.footer-bottom a:hover {
  color: #3c3c3c;
}

.footer-bottom__links > a {
  color: #ffffff;
  line-height: 1.875em;
  white-space: nowrap;
}

@media screen and (min-width: 46.875em) {
  .footer-bottom__links > a {
    margin-left: 15px;
  }
}

@media screen and (max-width: 46.8125em) {
  .footer-bottom__links > a {
    display: block;
  }
}

.footer-bottom__links > a:hover,
.footer-bottom__links > a:focus {
  color: #ffffff;
}

.footer-country-select {
  margin-bottom: 0;
  display: inline-block;
  position: relative;
  width: auto;
  line-height: 1.875em;
}

@media screen and (min-width: 46.875em) {
  .footer-country-select {
    margin-left: 15px;
  }
}

.footer-country-select .popover {
  width: auto;
  position: relative;
}

.footer-country-select .popover::before,
.footer-country-select .popover::after {
  display: none;
}

.footer-country-select__trigger {
  color: #ffffff;
  -webkit-transition: color 300ms ease-in-out;
  transition: color 300ms ease-in-out;
}

.footer-country-select__trigger:hover,
.footer-country-select__trigger:focus {
  color: #ffffff;
}

.footer-country-select__trigger:hover .footer-country-select__globe,
.footer-country-select__trigger:hover .footer-country-select__arrow,
.footer-country-select__trigger:focus .footer-country-select__globe,
.footer-country-select__trigger:focus .footer-country-select__arrow {
  fill: #ffffff;
}

.footer-country-select__popover.js-is-active .footer-country-select__arrow {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.footer-country-select__globe,
.footer-country-select__arrow {
  fill: #ffffff;
  -webkit-transition: fill 300ms ease-in-out;
  transition: fill 300ms ease-in-out;
  display: inline-block;
  vertical-align: middle;
}

.footer-country-select__globe {
  width: 1em;
  height: 1em;
  margin-bottom: 0.1875em;
}

.footer-country-select__arrow {
  margin-bottom: 0.1875em;
  width: 0.75em;
  height: 0.75em;
}

.footer-country-select__text {
  padding-left: 0.375em;
  padding-right: 0.375em;
}

.footer-country-select__content.popover-content {
  padding: 12px 24px 12px;
  position: absolute;
  bottom: 25px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  -webkit-column-count: 2;
  column-count: 2;
  -webkit-box-shadow: 0 5px 30px 5px rgba(69, 79, 91, 0.2);
  box-shadow: 0 5px 30px 5px rgba(69, 79, 91, 0.2);
  border-radius: 5px;
  background-color: #ffffff;
}

@media screen and (min-width: 46.875em) {
  .footer-country-select__content.popover-content {
    padding-left: 0;
    -webkit-transform: none;
    transform: none;
    right: 0;
    left: auto;
  }
}

.footer-country-select__content.popover-content li {
  display: inline-block;
  width: 100%;
}

.footer-country-select__content.popover-content a {
  color: #637381;
  font-weight: 700;
  line-height: 1;
  padding: 9px 24px 9px 21px;
  border-left: 3px solid transparent;
  margin-left: 0;
  margin-right: 0;
  white-space: nowrap;
  display: block;
}

@media screen and (max-width: 46.8125em) {
  .footer-country-select__content.popover-content a {
    font-size: 0.875em;
    padding-right: 10px;
    padding-left: 7px;
    line-height: 1.2;
  }
}

.footer-country-select__content.popover-content a:hover,
.footer-country-select__content.popover-content a:focus {
  color: #212b35;
  border-left-color: #919eab;
}

.footer-country-select__content.popover-content a:active {
  border-left-color: #212b35;
}

.footer-social__icon {
  display: inline-block;
  width: 1.125em;
  height: 1.125em;
  margin-top: 0.3125em;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .footer-social__icon {
    width: 1em;
    height: 1em;
  }
}

.footer-social__icon + .footer-social__icon {
  margin-left: 0.5em;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .footer-social__icon + .footer-social__icon {
    margin-left: 0.3125em;
  }
}

.footer-social__icon .icon {
  width: 100%;
  height: 100%;
  fill: #262626;
  -webkit-transition: fill 300ms ease-in-out;
  transition: fill 300ms ease-in-out;
}

.footer-social__icon:hover .icon,
.footer-social__icon:focus .icon {
  fill: #262626;
}

.footer-nav {
  border-bottom: 1px solid rgba(223, 227, 232, 0.1);
  margin-bottom: 1.5625em;
}

@media screen and (max-width: 46.8125em) {
  .footer-nav {
    text-align: center;
    padding-bottom: 1.5625em;
  }
}

.footer-nav a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
  font-weight: 700;
  font-size: 1em;
  margin-right: 0.9375em;
  color: #ffffff;
  display: inline-block;
}

@media screen and (max-width: 46.8125em) {
  .footer-nav a {
    margin-right: 0.46875em;
    margin-left: 0.46875em;
    padding-top: 0.3125em;
  }
}

@media screen and (min-width: 46.875em) {
  .footer-nav a {
    line-height: 70px;
    border-bottom: 2px solid #000639;
    -webkit-transition-property: border-color, fill;
    transition-property: border-color, fill;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms;
  }
  .footer-nav a:hover,
  .footer-nav a:focus {
    color: #ffffff;
    border-color: #ffffff;
  }
}

.footer-column--nameless {
  margin-top: 1.65em;
}

.footer-code-logo {
  display: inline-block;
  width: 100px;
  height: 22px;
  margin-top: 2px;
}

.footer-code-logo > .icon {
  width: 100%;
  height: 100%;
}

.drawer {
  display: none;
  position: fixed;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  z-index: 1000;
  top: 0;
  bottom: 0;
  width: 18.75em;
  max-width: 100%;
  -webkit-transition: -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.4s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  background-color: #ffffff;
  font-size: 1.1428571429em;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
}

@media screen and (prefers-reduced-motion: reduce) {
  .drawer {
    -webkit-transition: none;
    transition: none;
    -webkit-animation: none;
    animation: none;
  }
}

.js-drawer-open .drawer {
  display: block;
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.drawer .marketing-nav__logo__shopify {
  margin-top: 0;
}

.drawer--left {
  left: 0;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
}

.drawer--right {
  right: 0;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}

.drawer--powered-by-shopify .drawer__items--primary {
  padding-top: 0;
  border-top: 0;
}

.drawer__inner {
  position: relative;
  min-height: 100%;
  padding-right: 30px;
  padding-bottom: 30px;
  padding-left: 30px;
}

@media screen and (max-width: 67.4375em) {
  .drawer__inner .marketing-form__button {
    width: 100%;
  }
}

.drawer__top {
  position: relative;
  height: 60px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.drawer__close-button {
  display: block;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  width: 1.25em;
  height: 1.25em;
  padding: 0.625em;
  fill: #212b35;
  position: absolute;
  top: 50%;
  right: -0.625em;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.drawer__close-button .icon {
  width: 100%;
  height: 100%;
}

.drawer__items {
  padding-top: 15px;
  border-top: 1px solid #dfe3e8;
}

.drawer__items + .drawer__items {
  margin-top: 15px;
}

.drawer__items--nested {
  margin-bottom: 15px;
  padding-top: 0;
  border-top: 0;
  border-left: 1px solid #dfe3e8;
}

.drawer__items--nested .drawer__items--nested {
  margin-left: 1.0625em;
}

.drawer__item {
  display: block;
  width: 100%;
  color: #454f59;
  font-size: 1em;
  font-weight: 500;
  padding-top: 0.625em;
  padding-bottom: 0.625em;
  text-align: left;
  line-height: 1;
  -webkit-transition: color 300ms;
  transition: color 300ms;
}

.drawer__item:hover,
.drawer__item:focus {
  color: #000000;
  font-weight: 500;
}

.drawer__item.drawer__item--active {
  color: #000000;
  font-weight: 500;
}

.drawer__items--nested .drawer__item {
  margin-left: -1px;
  padding-left: 1.0625em;
  border-left: 3px solid transparent;
}

.drawer__items--nested .drawer__item.drawer__item--active {
  border-left-color: currentColor;
}

.drawer__items--corporate .drawer__item {
  color: #919ea9;
  font-weight: 500;
}

.drawer__items--corporate .drawer__item:hover,
.drawer__items--corporate .drawer__item:focus {
  color: #000000;
  font-weight: 500;
}

.drawer__items--corporate .drawer__item.drawer__item--active {
  color: #000000;
  font-weight: 500;
}

.drawer__items--user .drawer__item {
  color: #454f59;
  font-weight: 500;
}

.drawer__items--user .drawer__item:hover,
.drawer__items--user .drawer__item:focus {
  color: #000000;
  font-weight: 500;
}

.drawer__items--user .drawer__item.drawer__item--active {
  color: #000000;
  font-weight: 500;
}

.drawer__item--primary {
  font-size: 1em;
  font-weight: 700;
  text-transform: uppercase;
  padding-top: 15px;
  padding-bottom: 15px;
}

.drawer__item--primary,
.drawer__item--primary a {
  color: #000000;
}

.drawer__item--primary:hover,
.drawer__item--primary:focus {
  color: #000000;
  font-weight: 700;
}

.drawer__item--primary.drawer__item--active {
  color: #000000;
  font-weight: 700;
}

.drawer__item--primary .marketing-nav__arrow {
  vertical-align: 20%;
  margin-left: 0.3571428571em;
}

.drawer__item--signup {
  color: #5c6ac4;
  border: 0;
  background-color: transparent;
  padding-left: 0;
  padding-right: 0;
}

.page-wrap::before {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-transition: visibility 0.4s linear,
    opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  transition: visibility 0.4s linear, opacity 0.4s cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  content: "";
  z-index: 999;
}

.js-drawer-open {
  overflow: hidden;
}

.js-drawer-open .page-wrap::before {
  visibility: visible;
  opacity: 1;
}

.signup-modal__content {
  width: 100%;
}

@media screen and (min-width: 67.5em) {
  .signup-modal__content {
    max-width: 50%;
    margin: 0 auto;
  }
}

.signup-modal__content .marketing-form__button {
  float: right;
}

body > .signup--hidden {
  display: none;
}

.subdomain {
  position: relative;
}

.stateful-form .marketing-input::-ms-clear {
  display: none;
}

.stateful-form .js-has-error .marketing-input {
  -webkit-box-shadow: 0 0 0 2px #eb644d;
  box-shadow: 0 0 0 2px #eb644d;
}

.stateful-form .js-has-hint .marketing-input {
  -webkit-box-shadow: 0 0 0 2px #b7ecec;
  box-shadow: 0 0 0 2px #b7ecec;
}

.stateful-form .js-is-pending::after {
  content: "";
  display: block;
  width: 18px;
  height: 18px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -9px;
  margin-top: -9px;
  border-radius: 50%;
  border: 3px solid #454f59;
  border-top-color: transparent;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
  z-index: 10;
  right: 1.25em;
  left: auto;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.stateful-form .marketing-input {
  position: relative;
  z-index: 1;
}

.stateful-form .js-is-hidden {
  display: none;
}

.stateful-form .marketing-form__messages {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
  padding-top: 2px;
  font-size: 1em;
  max-height: 0;
  overflow: hidden;
  display: block;
}

@media screen and (min-width: 46.875em) {
  .stateful-form .marketing-form__messages {
    -webkit-transition: max-height 300ms;
    transition: max-height 300ms;
  }
}

.stateful-form .marketing-form__messages .error {
  color: #ffffff;
  display: block;
  font-size: 0.75em;
  padding: 0.4166666667em 1em;
  position: relative;
  min-height: 2em;
}

.stateful-form .marketing-form__messages .error.hide {
  display: none;
}

.stateful-form .marketing-form__messages .error a,
.stateful-form .marketing-form__messages .error button {
  color: #ffffff;
  text-decoration: underline;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
  -webkit-text-decoration-color: currentColor;
  text-decoration-color: currentColor;
  text-decoration-skip-ink: auto;
  -webkit-transition: color 150ms;
  transition: color 150ms;
}

.stateful-form .marketing-form__messages .error a:hover,
.stateful-form .marketing-form__messages .error a:focus,
.stateful-form .marketing-form__messages .error button:hover,
.stateful-form .marketing-form__messages .error button:focus {
  color: #f0f0f0;
}

.stateful-form .marketing-form__messages .error a:focus,
.stateful-form .marketing-form__messages .error button:focus {
  outline: 0;
  background-color: rgba(255, 255, 255, 0.125);
}

.stateful-form .marketing-form__messages .suggest {
  color: #000000;
  display: block;
  font-size: 0.75em;
  padding: 0.4166666667em 1em;
  position: relative;
  min-height: 2em;
}

.stateful-form .marketing-form__messages .suggest.hide {
  display: none;
}

.stateful-form .marketing-form__messages .suggest a,
.stateful-form .marketing-form__messages .suggest button {
  color: #000000;
  text-decoration: underline;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
  -webkit-text-decoration-color: currentColor;
  text-decoration-color: currentColor;
  text-decoration-skip-ink: auto;
  -webkit-transition: color 150ms;
  transition: color 150ms;
}

.stateful-form .marketing-form__messages .suggest a:hover,
.stateful-form .marketing-form__messages .suggest a:focus,
.stateful-form .marketing-form__messages .suggest button:hover,
.stateful-form .marketing-form__messages .suggest button:focus {
  color: black;
}

.stateful-form .marketing-form__messages .suggest a:focus,
.stateful-form .marketing-form__messages .suggest button:focus {
  outline: 0;
  background-color: rgba(0, 0, 0, 0.125);
}

.stateful-form .js-has-error .marketing-form__messages {
  background-color: #eb644d;
  -webkit-box-shadow: 0 0 0 2px #eb644d;
  box-shadow: 0 0 0 2px #eb644d;
  border-radius: 0 0 5px 5px;
  max-height: 90px;
}

@media screen and (min-width: 46.875em) {
  .stateful-form .js-has-error .marketing-form__messages {
    -webkit-transition: max-height 300ms;
    transition: max-height 300ms;
  }
}

.stateful-form .js-has-hint .marketing-form__messages {
  background-color: #b7ecec;
  -webkit-box-shadow: 0 0 0 2px #b7ecec;
  box-shadow: 0 0 0 2px #b7ecec;
  border-radius: 0 0 5px 5px;
  max-height: 90px;
}

@media screen and (min-width: 46.875em) {
  .stateful-form .js-has-hint .marketing-form__messages {
    -webkit-transition: max-height 300ms;
    transition: max-height 300ms;
  }
}

.stateful-form .js-is-pending .marketing-input {
  -webkit-box-shadow: 0 0 0 1px #c4cdd5;
  box-shadow: 0 0 0 1px #c4cdd5;
}

.stateful-form .js-is-pending .marketing-form__messages {
  max-height: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}

@media screen and (min-width: 46.875em) {
  .stateful-form .js-is-pending .marketing-form__messages {
    -webkit-transition: all 300ms;
    transition: all 300ms;
  }
}

.stateful-form .js-is-pending + .marketing-input-suffix {
  opacity: 0.2;
}

.page-block {
  padding: 5.625em 0;
  border-color: #dfe3e8;
}

.page-block--white {
  background: #ffffff;
  color: #454f59;
}

.page-block--light {
  background: #f9f6f4;
  color: #212b37;
}

.page-block--lowlight {
  background-color: #30373b;
  color: #919ea9;
}

.page-block--blue {
  background-color: #f6fafd;
  color: #212b37;
}

.page-block--border-top {
  border-top-width: 1px;
  border-top-style: solid;
}

@media screen and (max-width: 46.8125em) {
  .page-block {
    padding: 3.75em 0;
  }
}

.page-block--lowlight .section-heading__heading {
  color: #ffffff;
}

.page-block--lowlight .section-heading__subhead {
  color: #dfe3e8;
}

.page-block--image {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.page-block--image > .page-width {
  height: 100%;
  z-index: 1;
}

.section-block {
  padding-top: 2.8125em;
  padding-bottom: 2.8125em;
  border-color: #dfe3e8;
}

.section-block--border-top {
  border-top-width: 1px;
  border-top-style: solid;
}

.section-block--white {
  background: #ffffff;
  color: #454f59;
}

.section-block--light {
  background: #f9f6f4;
  color: #212b37;
}

.section-block--lowlight {
  background-color: #30373b;
  color: #919ea9;
}

.section-block--blue {
  background-color: #f6fafd;
  color: #212b37;
}

@media screen and (max-width: 46.8125em) {
  .section-block {
    padding-top: 1.875em;
    padding-bottom: 1.875em;
  }
}

.page-width .section-block:first-child:not(:only-child) {
  padding-top: 0;
}

.page-width .section-block:last-child:not(:only-child) {
  padding-bottom: 0;
}

.section {
  padding: 3.75em 0;
}

@media screen and (min-width: 46.875em) {
  .section {
    padding-top: 5.625em;
    padding-bottom: 5.625em;
  }
}

.section .section:first-child:not(:only-child) {
  padding-top: 0;
}

.section .section:last-child:not(:only-child) {
  padding-bottom: 0;
}

.section--tight {
  padding: 1.875em 0;
}

@media screen and (min-width: 46.875em) {
  .section--tight {
    padding-top: 2.8125em;
    padding-bottom: 2.8125em;
  }
}

.section--padding-top-only {
  padding-bottom: 0;
}

.section--padding-bottom-only {
  padding-top: 0;
}

.section--border {
  border-top: 1px solid #dfe3e8;
}

.section--image {
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.section-heading {
  margin-bottom: 2.8125em;
  text-align: center;
}

@media screen and (min-width: 67.5em) {
  .section-heading {
    margin-bottom: 3.75em;
  }
}

.section-heading--lowlight .section-heading__heading {
  color: #ffffff;
}

.section-heading--lowlight .section-heading__subhead {
  color: #dfe3e8;
}

.section-heading--lowlight .section-heading__kicker {
  color: #dfe3e8;
}

.section-heading--tight {
  margin-bottom: 1.5625em;
}

.section-heading--tight .section-heading__heading {
  margin-bottom: 0.25em;
}

.section-heading--tight .section-heading__subhead {
  margin-bottom: 0;
}

.section-heading--tight .section-heading__kicker {
  margin-bottom: 0.75em;
}

.section-heading__subhead {
  font-weight: 400;
  color: #637381;
}

.section-heading__kicker {
  font-weight: 700;
  color: #637381;
}

.section-heading__kicker.section-heading__kicker--has-icon {
  display: inline-block;
  margin-bottom: 0;
  vertical-align: middle;
}

.section-heading__icon-wrapper {
  margin-bottom: 0.625em;
}

.section-heading__icon {
  width: 1.75em;
  height: 1.75em;
  margin-right: 0.625em;
  vertical-align: middle;
}

@media screen and (min-width: 46.875em) {
  .section-heading--left {
    text-align: left;
  }
}

.block {
  display: block;
  border-color: #dfe3e8;
  margin-bottom: 2.8125em;
}

.block__icon,
.block__image {
  margin-bottom: 0.9375em;
}

.block--padded {
  padding: 30px;
}

.block--padded .block__content:last-child,
.block--padded .block__cta:last-child {
  margin-bottom: 0;
}

.block--rounded {
  border-radius: 5px;
}

.block--bordered {
  border-width: 1px;
  border-style: solid;
}

.block--lowlight .block__icon {
  fill: #ffffff;
}

.block--lowlight .block__heading {
  color: #ffffff;
}

.block--lowlight .block__content {
  color: #dfe3e8;
}

.block--lowlight .block__cta a {
  color: #ffffff;
}

.block__content {
  margin-bottom: 0.9375em;
}

.marketing-block {
  display: block;
  border-color: #dfe3e8;
}

.marketing-block--white {
  background: #ffffff;
  color: #454f59;
}

.marketing-block--light {
  background: #f9f6f4;
  color: #212b37;
}

.marketing-block--lowlight {
  background-color: #30373b;
  color: #919ea9;
}

.marketing-block--blue {
  background-color: #f6fafd;
  color: #212b37;
}

@media screen and (max-width: 46.8125em) {
  .marketing-block {
    margin-bottom: 2.8125em;
    width: 100%;
  }
}

.marketing-block .icon {
  margin-bottom: 0.75em;
}

.marketing-block--bordered {
  border-width: 1px;
  border-style: solid;
}

.marketing-block--light {
  border-width: 1px;
  border-style: solid;
}

.marketing-block--blue {
  border: 1px solid #479ccf;
}

.marketing-block--padded {
  padding: 1.875em;
}

.marketing-block--rounded {
  border-radius: 5px;
}

.marketing-block__content p:last-child {
  margin-bottom: 0;
}

.marketing-block__img img {
  width: 100%;
}

.bullet {
  display: table;
  margin-bottom: 1.875em;
  position: relative;
}

.bullet__icon {
  padding-right: 15px;
  display: table-cell;
  vertical-align: top;
}

.bullet__content {
  padding-top: 8px;
  margin-bottom: 0;
  display: table-cell;
  vertical-align: top;
  width: 100%;
}

.marketing-bullet-list {
  margin-bottom: 1.875em;
  padding-top: 1.875em;
}

.marketing-bullet-list:last-child {
  margin-bottom: -1.875em;
}

.marketing-bullet {
  list-style: none;
  margin-bottom: 1.875em;
  position: relative;
}

.marketing-bullet::after {
  content: "";
  display: table;
  clear: both;
}

.marketing-bullet .icon {
  display: block;
  margin-bottom: 0;
  float: left;
  margin-right: 15px;
}

.marketing-bullet--without-heading .marketing-bullet__content {
  -webkit-font-smoothing: auto;
  -moz-osx-font-smoothing: auto;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
  font-weight: 300;
  position: absolute;
  left: 75px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 1.125em;
  line-height: 1;
}

.marketing-bullet__content-wrapper {
  overflow: hidden;
  display: block;
  float: none;
  position: static;
}

@media screen and (max-width: 46.8125em) {
  .marketing-bullet__content-wrapper {
    margin-bottom: 0;
    text-align: left;
    width: inherit;
  }
}

@media screen and (max-width: 46.8125em) {
  .media__center--mobile {
    text-align: center;
  }
}

@media screen and (min-width: 67.5em) {
  .media__offset {
    margin-top: 2.8125em;
  }
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .media__offset {
    margin-top: 0.9375em;
  }
}

@media screen and (max-width: 46.8125em) {
  .media__image {
    text-align: center;
  }
}

@media screen and (max-width: 46.8125em) {
  .media__last {
    margin-top: 1.875em;
  }
}

@media screen and (min-width: 46.875em) {
  .media--flex-centered {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .media--flex-centered .media__right {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2;
  }
  .media--flex-centered .media__offset {
    margin-top: 0;
  }
}

@media screen and (min-width: 46.875em) {
  .media__right {
    float: right;
    margin-right: 0;
  }
}

@media screen and (min-width: 46.875em) {
  .media__image--wide-left img,
  .media__image--wide-left .lazyload-image {
    float: right;
  }
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .media__image--wide-left img,
  .media__image--wide-left .lazyload-image {
    max-width: none;
  }
}

@media screen and (max-width: 46.8125em) {
  .media__image--wide-left--mobile img {
    margin-left: -30px;
  }
}

@media screen and (min-width: 46.875em) {
  .media__image--wide-right img,
  .media__image--wide-right .lazyload-image {
    float: left;
  }
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .media__image--wide-right img,
  .media__image--wide-right .lazyload-image {
    max-width: none;
  }
}

@media screen and (min-width: 46.875em) {
  .media__image--wide-left .lazyload-image,
  .media__image--wide-right .lazyload-image {
    overflow: visible;
  }
}

@media screen and (max-width: 46.8125em) {
  .media__image--wide-right--mobile {
    text-align: right;
  }
  .media__image--wide-right--mobile img {
    margin-right: -30px;
  }
}

@media screen and (min-width: 46.875em) {
  .media__image--bottom img,
  .media__image--bottom .lazyload-image {
    margin-bottom: -5.625em;
  }
}

@media screen and (min-width: 46.875em) {
  .media__image--bottom .lazyload-image img {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 46.875em) {
  .media__image--top img,
  .media__image--top .lazyload-image {
    margin-top: -5.625em;
  }
}

@media screen and (min-width: 46.875em) {
  .media__image--top .lazyload-image img {
    margin-top: 0;
  }
}

@media screen and (min-width: 67.5em) {
  .media__image--top--desktop img,
  .media__image--top--desktop .lazyload-image {
    margin-top: -5.625em;
  }
}

@media screen and (min-width: 67.5em) {
  .media__image--top--desktop .lazyload-image img {
    margin-top: 0;
  }
}

@media screen and (min-width: 67.5em) {
  .media__image--bottom--desktop img {
    margin-bottom: -5.625em;
  }
}

@media screen and (min-width: 67.5em) {
  .media__image--bottom--desktop img,
  .media__image--bottom--desktop .lazyload-image {
    margin-bottom: -5.625em;
  }
}

@media screen and (min-width: 67.5em) {
  .media__image--bottom--desktop .lazyload-image img {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 46.8125em) {
  .media__image--bottom--mobile img,
  .media__image--bottom--mobile .lazyload-image {
    margin-bottom: -3.75em;
  }
}

@media screen and (max-width: 46.8125em) {
  .media__image--bottom--mobile .lazyload-image img {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 46.875em) {
  .media__image--right-tight {
    margin-left: -15em;
  }
}

@media screen and (min-width: 67.5em) {
  .media__image--overflow img {
    max-width: none;
  }
}

.lazyload-image {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
  margin: auto;
}

.lazyload-image img {
  display: block;
}

.lazyload-image .lazyload-image__placeholder {
  display: block;
  width: 100%;
  height: 0;
}

.lazyload {
  display: block;
  height: 0;
  overflow: hidden;
}

.flash-container {
  position: relative;
}

.flash-container + .flash-container {
  margin-top: 1.875em;
}

.flash {
  padding: 0.9375em 3.0625em 0.9375em 3.5em;
  min-height: 3.75em;
  border: 0.125em solid;
  border-radius: 5px;
}

.flash h1,
.flash h2,
.flash h3,
.flash h4,
.flash h5,
.flash h6,
.flash .long-form-content li::before {
  color: currentColor;
}

.flash--notice,
.flash--info {
  background-color: #f9f6f4;
  border-color: #dfe3e8;
  color: #454f59;
}

.flash--notice,
.flash--notice .long-form-content,
.flash--info,
.flash--info .long-form-content {
  color: #454f59;
}

.flash--error,
.flash--alert {
  background-color: rgba(235, 100, 77, 0.05);
}

.flash--error,
.flash--error .long-form-content,
.flash--alert,
.flash--alert .long-form-content {
  color: #eb644d;
}

.flash--success {
  background-color: rgba(88, 180, 75, 0.05);
  border-color: #58b44b;
}

.flash--success,
.flash--success .long-form-content {
  color: #58b44b;
}

.icon--flash {
  position: absolute;
  top: 0.9375em;
  left: 0.9375em;
  width: 1.625em;
  height: 1.625em;
  fill: currentColor;
}

.flash__close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.9375em;
  opacity: 0.8;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}

.flash__close svg {
  width: 0.9375em;
  height: 0.9375em;
  fill: currentColor;
}

.flash__close:hover,
.flash__close:focus {
  opacity: 1;
}

.flash__close:active {
  opacity: 0.8;
}

.flash__content.long-form-content {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
  font-weight: 500;
  margin: 0;
}

.flash__content.long-form-content .heading {
  margin-top: -0.125em;
}

.flash__content.long-form-content > *:last-child {
  margin-bottom: 0;
}

@media screen and (max-width: 46.8125em) {
  .hero {
    text-align: center;
  }
}

.hero .hero__inner {
  padding-top: 10%;
  padding-bottom: 10%;
}

.hero .hero__image {
  width: 100%;
  height: auto;
}

.spot-image--small {
  width: 60px;
}

.spot-image--medium {
  width: 85px;
}

.spot-image--large {
  width: 120px;
}

.cookies-notice {
  z-index: 1000;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  padding: 15px 0;
  background-color: #000000;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  -webkit-text-size-adjust: 100%;
}

.js-is-loaded .cookies-notice.js-is-active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

@media screen and (min-width: 46.875em) {
  .cookies-notice {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.cookies-notice__body {
  padding-right: 15px;
  font-size: 0.875em;
  line-height: 1.5;
}

@media screen and (max-width: 46.8125em) {
  .cookies-notice__body {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 46.875em) {
  .cookies-notice__body {
    float: left;
    width: 80%;
    font-size: 0.8125em;
  }
}

.cookies-notice__body,
.cookies-notice__body a {
  color: #ffffff;
}

@media screen and (max-width: 46.8125em) {
  .cookies-notice__cta {
    float: right;
    font-size: 0.875em;
  }
}

@media screen and (min-width: 46.875em) {
  .cookies-notice__cta {
    float: left;
    width: 20%;
    text-align: right;
  }
}

.background-video,
.background-video--fallback {
  opacity: 0;
  visibility: hidden;
  z-index: -1;
  position: absolute;
  bottom: 0;
  left: 50%;
  min-height: 100%;
  min-width: 100%;
  max-width: none;
  overflow: hidden;
  -webkit-transition: opacity 1s ease-in;
  transition: opacity 1s ease-in;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media screen and (max-width: 46.8125em) {
  .background-video,
  .background-video--fallback {
    min-width: 0;
    min-height: 0;
    height: 100%;
    opacity: 1;
  }
}

.background-video.js-is-active,
.background-video--fallback.js-is-active {
  opacity: 1;
  visibility: visible;
  z-index: 0;
}

.inline-video,
.inline-video--fallback {
  display: block;
  max-width: 100%;
  height: auto;
}

.responsive-video-wrapper {
  position: relative;
  height: 0;
  padding-top: 56.25%;
}

.responsive-video-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.background-video-next {
  display: block;
  position: relative;
}

.background-video-next__video {
  width: 100%;
}

.background-video-next__button {
  position: absolute;
  width: 1.25em;
  height: 1.25em;
  background-position: center;
  background-size: contain;
  z-index: 999;
}

.background-video-next__button--bottom-right {
  right: 2.5em;
  bottom: 2.5em;
}

.background-video-next__button--bottom-left {
  left: 2.5em;
  bottom: 2.5em;
}

.background-video-next__button--below-center {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -2.5em;
}

.background-video-next__button--below-right {
  right: 2.5em;
  bottom: -2.5em;
}

.background-video-next--skin-light .background-video-next__button--pause {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2020%2020%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23ffffff%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M5.5,19a1,1,0,0,1-1-1V2a1,1,0,0,1,2,0V18A1,1,0,0,1,5.5,19Z%22/%3E%3Cpath%20d=%22M14.51,19a1,1,0,0,1-1-1V2a1,1,0,0,1,2,0V18A1,1,0,0,1,14.51,19Z%22/%3E%3C/svg%3E");
}

.background-video-next--skin-light .background-video-next__button--play {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2020%2020%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23ffffff%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M5,3.51,15.82,10,5,16.49v-13M4.33,1A1.33,1.33,0,0,0,3,2.33V17.67A1.33,1.33,0,0,0,4.33,19,1.3,1.3,0,0,0,5,18.81L17.8,11.14a1.33,1.33,0,0,0,0-2.28L5,1.19A1.3,1.3,0,0,0,4.33,1Z%22/%3E%3C/svg%3E");
}

.background-video-next--skin-dark .background-video-next__button--pause {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2020%2020%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23212b35%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M5.5,19a1,1,0,0,1-1-1V2a1,1,0,0,1,2,0V18A1,1,0,0,1,5.5,19Z%22/%3E%3Cpath%20d=%22M14.51,19a1,1,0,0,1-1-1V2a1,1,0,0,1,2,0V18A1,1,0,0,1,14.51,19Z%22/%3E%3C/svg%3E");
}

.background-video-next--skin-dark .background-video-next__button--play {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2020%2020%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23212b35%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M5,3.51,15.82,10,5,16.49v-13M4.33,1A1.33,1.33,0,0,0,3,2.33V17.67A1.33,1.33,0,0,0,4.33,19,1.3,1.3,0,0,0,5,18.81L17.8,11.14a1.33,1.33,0,0,0,0-2.28L5,1.19A1.3,1.3,0,0,0,4.33,1Z%22/%3E%3C/svg%3E");
}

.paginated-carousel {
  position: relative;
}

.paginated-carousel .carousel-item {
  position: relative;
}

@media screen and (max-width: 46.8125em) {
  .paginated-carousel .carousel-item {
    position: absolute;
    top: 0;
    display: none;
    float: none;
    width: auto;
    margin-right: 0;
    opacity: 0;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .paginated-carousel .carousel-item.js-is-active {
    position: static;
    display: block;
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.paginated-carousel .carousel-nav {
  opacity: 0;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}

.js-is-loaded .paginated-carousel .carousel-nav {
  opacity: 1;
}

.paginated-carousel .carousel-nav-items {
  text-align: center;
}

.paginated-carousel .carousel-nav-item {
  width: 0.8125em;
  height: 0.8125em;
  margin: 0 0.25em;
  line-height: 1.25em;
  vertical-align: middle;
  background-color: #919ea9;
  border-radius: 50%;
}

.paginated-carousel .carousel-nav-item.js-is-active {
  background: #000000;
}

.paginated-carousel .carousel-arrow-left,
.paginated-carousel .carousel-arrow-right {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.paginated-carousel .carousel-arrow-left {
  left: 0;
}

.paginated-carousel .carousel-arrow-right {
  right: 0;
}

.inline-cta__heading {
  font-weight: 400;
  color: #454f59;
}

@media screen and (min-width: 46.875em) {
  .inline-cta__heading {
    display: inline-block;
  }
}

@media screen and (min-width: 46.875em) {
  .inline-cta__button {
    margin-left: 30px;
  }
}

@media screen and (min-width: 46.875em) {
  .marketing-input-button-pair__input,
  .marketing-input-button-pair__button {
    float: left;
  }
}

@media screen and (max-width: 46.8125em) {
  .marketing-input-wrapper .marketing-input-button-pair__input {
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 46.875em) {
  .marketing-input-button-pair__input {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-right: 10px;
  }
}

.marketing-input-button-pair__button {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}

@media screen and (max-width: 46.8125em) {
  .marketing-input-button-pair__button {
    width: 100%;
  }
}

.marketing-input-button__field-wrapper::after {
  content: "";
  display: table;
  clear: both;
}

@media screen and (min-width: 46.875em) {
  .marketing-input-button__field-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
}

@media screen and (min-width: 46.875em) {
  .js-is-sticky-init .sticky-menu-content {
    float: right;
  }
}

.js-is-sticky-container .sticky-menu {
  position: fixed;
  top: 0;
}

.js-is-abs-container .sticky-menu {
  position: absolute;
  bottom: 0;
  top: auto;
}

@media screen and (min-width: 67.5em) {
  .page--has-secondary-nav .js-is-abs-container .sticky-menu {
    top: auto;
  }
}

.js-is-abs-container .in-page-menu {
  margin-bottom: 0;
}

.in-page-menu {
  list-style: none;
  margin-bottom: 30px;
}

.in-page-menu a {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #454f59;
  font-size: 1em;
  display: block;
  -webkit-transition: all 150ms;
  transition: all 150ms;
}

.in-page-menu a:hover,
.in-page-menu a:focus {
  color: #000000;
}

.in-page-menu a.js-is-active {
  color: #000000;
  border-color: #000000;
}

.in-page-menu--vertical {
  border-left: 1px solid #dfe3e8;
}

.in-page-menu--vertical a {
  margin-left: -1px;
  border-left: 3px solid transparent;
  padding: 0.625em 0 0.625em 1.0625em;
}

.in-page-menu--horizontal a {
  border-bottom: 2px solid transparent;
  margin-right: 0.9375em;
  -webkit-transition-property: border, color;
  transition-property: border, color;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.in-page-menu--horizontal li {
  display: inline-block;
}

@media screen and (max-width: 46.8125em) {
  .accordion-item--mobile.js-is-active .accordion-link::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2044%2044%22%3E%3Cpath%20d=%22M4%2021.4h35.8v4.3H4z%22/%3E%3C/svg%3E");
  }
  .accordion-item--mobile .accordion-link {
    position: relative;
    cursor: pointer;
    display: block;
    width: 100%;
    text-align: left;
    min-height: 1.25em;
    padding-right: 1.25em;
  }
  .accordion-item--mobile .accordion-link::after {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2044%2044%22%3E%3Cpath%20d=%22M4%2020.4h35.8v4.3H4z%22/%3E%3Cpath%20d=%22M19.7%204.7H24v35.8h-4.3z%22/%3E%3C/svg%3E");
    content: "";
    position: absolute;
    right: 0;
    top: 1px;
    width: 1.25em;
    height: 1.25em;
  }
  .accordion-item--mobile .accordion-content {
    display: none;
  }
}

.accordion-item .accordion-content {
  display: none;
}

.icon-modules-close-white {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2044%2044%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23ffffff%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M39.196%2043.3L1.154%205.256l3.89-3.89%2038.04%2038.043z%22/%3E%3Cpath%20d=%22M.54%2039.413L38.58%201.37l3.89%203.89L4.428%2043.302z%22/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

.icon-modules-close-black {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2044%2044%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23000000%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M39.196%2043.3L1.154%205.256l3.89-3.89%2038.04%2038.043z%22/%3E%3Cpath%20d=%22M.54%2039.413L38.58%201.37l3.89%203.89L4.428%2043.302z%22/%3E%3C/svg%3E");
  background-repeat: no-repeat;
}

.js-modal-open #SiteNavContainer,
.js-modal-open .page-wrap {
  display: none;
}

.modal-container {
  display: none;
  min-height: 100vh;
  left: 0;
  opacity: 0;
  padding: 0 0 65px;
  top: 0;
  width: 100%;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  z-index: -1;
  -webkit-transition: opacity 150ms;
  transition: opacity 150ms;
}

@media screen and (max-width: 46.8125em) {
  .modal-container {
    padding: 0 0 30px;
  }
}

.modal-container.is-transitioning {
  z-index: 1000;
  position: fixed;
}

.modal-container.js-is-active {
  display: block;
  opacity: 1;
  visibility: visible;
  z-index: 1000;
}

.modal-container button:-moz-focusring,
.modal-container a:-moz-focusring {
  outline: solid #ffffff 2px;
}

.modal-container--lowlight {
  background: #2a2c2e;
  color: #ffffff;
}

.modal-container--highlight {
  background: #ffffff;
  color: #2a2c2e;
}

.modal__header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}

.modal__controls {
  text-align: right;
}

.modal__close {
  margin: 0.625em -0.625em 0 0;
  padding: 0.625em;
  opacity: 0.7;
  -webkit-transition: opacity 300ms ease;
  transition: opacity 300ms ease;
}

.modal__close:hover {
  opacity: 1;
}

.modal__close .icon {
  height: 16px;
  width: 16px;
}

.modal {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-perspective: 1000;
  perspective: 1000;
  padding-top: 130px;
  position: relative;
  text-align: left;
  -webkit-transition: all 150ms ease-out;
  transition: all 150ms ease-out;
  -webkit-transform: scale(0.85);
  transform: scale(0.85);
}

@media screen and (max-width: 46.8125em) {
  .modal {
    padding: 60px 30px 0;
  }
}

.js-is-active .modal {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.modal:focus {
  outline: none;
}

.modal__heading {
  color: inherit;
}

.popover-wrapper {
  position: relative;
  cursor: pointer;
  display: inline-block;
}

.popover-wrapper.js-is-active .popover {
  display: block;
  opacity: 1;
}

.popover {
  position: absolute;
  z-index: 1001;
  display: none;
  opacity: 0;
  width: 13.75em;
  text-align: left;
  background-clip: padding-box;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
}

@media screen and (max-width: 46.8125em) {
  .popover {
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
    padding-bottom: 10px;
    top: 0;
    left: 50%;
  }
}

.popover::after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border: transparent solid 10px;
}

@media screen and (max-width: 46.8125em) {
  .popover::after {
    left: 50%;
    bottom: 1px;
    border-bottom-width: 0;
    margin-left: -10px;
    border-top-color: #ffffff;
  }
}

.popover::before {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border: transparent solid 12px;
  z-index: -1;
}

@media screen and (max-width: 46.8125em) {
  .popover::before {
    left: 50%;
    bottom: 0;
    border-bottom-width: 0;
    border-top-color: #dfe3e8;
    margin-left: -12px;
  }
}

@media screen and (min-width: 46.875em) {
  .popover--top {
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
    top: 0;
    padding-bottom: 10px;
    left: 50%;
  }
  .popover--top::after,
  .popover--top::before {
    left: 50%;
    border-bottom-width: 0;
  }
  .popover--top::after {
    border-top-color: #ffffff;
    bottom: 1px;
    margin-left: -10px;
  }
  .popover--top::before {
    border-top-color: #dfe3e8;
    bottom: 0;
    margin-left: -12px;
  }
}

@media screen and (min-width: 46.875em) {
  .popover--right {
    left: 100%;
    top: 50%;
    padding-left: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .popover--right::after,
  .popover--right::before {
    top: 50%;
    border-left-width: 0;
  }
  .popover--right::after {
    border-right-color: #ffffff;
    left: 11px;
    margin-top: -10px;
  }
  .popover--right::before {
    border-right-color: #dfe3e8;
    left: 10px;
    margin-top: -12px;
  }
}

@media screen and (min-width: 46.875em) {
  .popover--left {
    right: 100%;
    top: 50%;
    padding-right: 20px;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  .popover--left::after,
  .popover--left::before {
    top: 50%;
    border-right-width: 0;
  }
  .popover--left::after {
    border-left-color: #ffffff;
    right: 11px;
    margin-top: -10px;
  }
  .popover--left::before {
    border-left-color: #dfe3e8;
    right: 10px;
    margin-top: -12px;
  }
}

@media screen and (min-width: 46.875em) {
  .popover--bottom {
    left: 50%;
    padding-top: 10px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .popover--bottom::after,
  .popover--bottom::before {
    left: 50%;
    border-top-width: 0;
  }
  .popover--bottom::after {
    border-bottom-color: #ffffff;
    top: 1px;
    margin-left: -10px;
  }
  .popover--bottom::before {
    border-bottom-color: #dfe3e8;
    top: 0;
    margin-left: -12px;
  }
}

@media screen and (max-width: 46.8125em) {
  .popover-wrapper--dark .popover::after {
    border-top-color: rgba(48, 55, 59, 0.95);
  }
}

@media screen and (min-width: 46.875em) {
  .popover-wrapper--dark .popover--top::after {
    border-top-color: rgba(48, 55, 59, 0.95);
  }
}

@media screen and (min-width: 46.875em) {
  .popover-wrapper--dark .popover--right::after {
    border-right-color: rgba(48, 55, 59, 0.95);
  }
}

@media screen and (min-width: 46.875em) {
  .popover-wrapper--dark .popover--bottom::after {
    border-bottom-color: rgba(48, 55, 59, 0.95);
  }
}

@media screen and (min-width: 46.875em) {
  .popover-wrapper--dark .popover--left::after {
    border-left-color: rgba(48, 55, 59, 0.95);
  }
}

.popover-wrapper--dark .popover-content {
  background-color: rgba(48, 55, 59, 0.95);
  color: #ffffff;
}

.popover-content {
  background-color: #ffffff;
  padding: 0.5em 0.625em;
  border-radius: 5px;
  border: 1px solid #dfe3e8;
  -webkit-box-shadow: 0 5px 30px 5px rgba(69, 79, 91, 0.1);
  box-shadow: 0 5px 30px 5px rgba(69, 79, 91, 0.1);
}

.popover-dropdown .popover-content {
  padding: 0.9375em 1.875em;
}

.popover-dropdown .popover-content li {
  display: block;
  line-height: 1em;
}

.popover-dropdown .popover-content a,
.popover-dropdown .popover-content button {
  display: block;
  position: relative;
  color: #454f59;
  padding: 0.625em 0;
}

.popover-dropdown .popover-content a:hover,
.popover-dropdown .popover-content a:focus,
.popover-dropdown .popover-content a.active,
.popover-dropdown .popover-content button:hover,
.popover-dropdown .popover-content button:focus,
.popover-dropdown .popover-content button.active {
  color: #000000;
}

.popover-dropdown .popover__arrow::after {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -0.1875em;
  width: 10px;
  height: 0.75em;
  content: "";
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20baseProfile=%22tiny%22%20viewBox=%220%200%2010.289%206.563%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23c4cdd5%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M5.212%206.563L0%201.423%201.404%200l3.788%203.735L8.865.01l1.424%201.404%22/%3E%3C/svg%3E");
  background-size: 100% auto;
  background-repeat: no-repeat;
}

.popover-dropdown.js-is-active .popover__arrow {
  color: #ffffff;
}

.popover-dropdown.js-is-active .popover__arrow::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20baseProfile=%22tiny%22%20viewBox=%220%200%2010.289%206.563%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23ffffff%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M5.212%206.563L0%201.423%201.404%200l3.788%203.735L8.865.01l1.424%201.404%22/%3E%3C/svg%3E");
}

.js-window-loading {
  opacity: 0;
  -webkit-transition: opacity 300ms ease-in-out;
  transition: opacity 300ms ease-in-out;
}

.js-is-loaded .js-window-loading {
  opacity: 1;
}

.responsive-svg {
  height: 0;
  position: relative;
}

.responsive-svg svg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.display--mobile,
.display--tablet,
.display--desktop {
  display: none !important;
}

@media screen and (min-width: 67.5em) {
  .display--desktop {
    display: block !important;
  }
  .hide--desktop {
    display: none !important;
  }
}

@media screen and (min-width: 67.5em) {
  .display--expanded-nav {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .hide--expanded-nav {
    display: none !important;
  }
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .display--tablet {
    display: block !important;
  }
  .hide--tablet {
    display: none !important;
  }
}

@media screen and (max-width: 46.8125em) {
  .display--mobile {
    display: block !important;
  }
  .hide--mobile {
    display: none !important;
  }
}

.gutter-bottom {
  margin-bottom: 30px !important;
}

.gutter-bottom--reset {
  margin-bottom: 0 !important;
}

.gutter-bottom--half {
  margin-bottom: 15px !important;
}

@media screen and (max-width: 46.8125em) {
  .gutter-bottom--reset--mobile {
    margin-bottom: 0 !important;
  }
  .gutter-bottom--mobile {
    margin-bottom: 30px !important;
  }
}

@media screen and (max-width: 67.4375em) {
  .gutter-bottom--tablet-down {
    margin-bottom: 30px !important;
  }
}

.text-center {
  text-align: center;
}

@media screen and (max-width: 46.8125em) {
  .text-center--mobile {
    text-align: center;
  }
}

@media screen and (min-width: 46.875em) {
  .text-center--tablet-up {
    text-align: center;
  }
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .text-center--tablet-only {
    text-align: center;
  }
}

@media screen and (max-width: 67.4375em) {
  .text-center--tablet-down {
    text-align: center;
  }
}

@media screen and (min-width: 67.5em) {
  .text-center--desktop-up {
    text-align: center;
  }
}

.text-left {
  text-align: left;
}

@media screen and (max-width: 46.8125em) {
  .text-left--mobile {
    text-align: left;
  }
}

@media screen and (min-width: 46.875em) {
  .text-left--tablet-up {
    text-align: left;
  }
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .text-left--tablet-only {
    text-align: left;
  }
}

@media screen and (max-width: 67.4375em) {
  .text-left--tablet-down {
    text-align: left;
  }
}

@media screen and (min-width: 67.5em) {
  .text-left--desktop-up {
    text-align: left;
  }
}

.text-right {
  text-align: right;
}

@media screen and (max-width: 46.8125em) {
  .text-right--mobile {
    text-align: right;
  }
}

@media screen and (min-width: 46.875em) {
  .text-right--tablet-up {
    text-align: right;
  }
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .text-right--tablet-only {
    text-align: right;
  }
}

@media screen and (max-width: 67.4375em) {
  .text-right--tablet-down {
    text-align: right;
  }
}

@media screen and (min-width: 67.5em) {
  .text-right--desktop-up {
    text-align: right;
  }
}

.color-primary {
  color: #5c6ac4;
}

.background-primary {
  background-color: #5c6ac4;
}

.background-light {
  background: #f9f6f4;
}

.background-lowlight {
  background: #000639;
}

.background-indigo-lightest {
  background-color: #f4f5fa;
}

.color-indigo-lightest {
  color: #f4f5fa;
}

.background-indigo-light {
  background-color: #b3bcf5;
}

.color-indigo-light {
  color: #b3bcf5;
}

.background-indigo {
  background-color: #5c6ac4;
}

.color-indigo {
  color: #5c6ac4;
}

.background-indigo-dark {
  background-color: #202e78;
}

.color-indigo-dark {
  color: #202e78;
}

.background-indigo-darkest {
  background-color: #000639;
}

.color-indigo-darkest {
  color: #000639;
}

.background-salmon-lightest {
  background-color: #fae1e1;
}

.color-salmon-lightest {
  color: #fae1e1;
}

.background-salmon-light {
  background-color: #fabcbb;
}

.color-salmon-light {
  color: #fabcbb;
}

.background-salmon {
  background-color: #fb8b8a;
}

.color-salmon {
  color: #fb8b8a;
}

.background-salmon-dark {
  background-color: #a65c5b;
}

.color-salmon-dark {
  color: #a65c5b;
}

.background-salmon-darkest {
  background-color: #452626;
}

.color-salmon-darkest {
  color: #452626;
}

.background-teal-lightest {
  background-color: #e0f5f5;
}

.color-teal-lightest {
  color: #e0f5f5;
}

.background-teal-light {
  background-color: #b7ecec;
}

.color-teal-light {
  color: #b7ecec;
}

.background-teal {
  background-color: #47c1bf;
}

.color-teal {
  color: #47c1bf;
}

.background-teal-dark {
  background-color: #00818a;
}

.color-teal-dark {
  color: #00818a;
}

.background-teal-darkest {
  background-color: #003135;
}

.color-teal-darkest {
  color: #003135;
}

.background-yellow-lightest {
  background-color: #fdeed1;
}

.color-yellow-lightest {
  color: #fdeed1;
}

.background-yellow-light {
  background-color: #ffe6b3;
}

.color-yellow-light {
  color: #ffe6b3;
}

.background-yellow {
  background-color: #fdd991;
}

.color-yellow {
  color: #fdd991;
}

.background-yellow-dark {
  background-color: #cd9157;
}

.color-yellow-dark {
  color: #cd9157;
}

.background-yellow-darkest {
  background-color: #6f451f;
}

.color-yellow-darkest {
  color: #6f451f;
}

.background-ink-lightest {
  background-color: #919eab;
}

.color-ink-lightest {
  color: #919eab;
}

.background-ink-lighter {
  background-color: #637381;
}

.color-ink-lighter {
  color: #637381;
}

.background-ink-light {
  background-color: #454f5b;
}

.color-ink-light {
  color: #454f5b;
}

.background-ink {
  background-color: #212b35;
}

.color-ink {
  color: #212b35;
}

.background-sky-lighter {
  background-color: #f9fafb;
}

.color-sky-lighter {
  color: #f9fafb;
}

.background-sky-light {
  background-color: #f9f6f4;
}

.color-sky-light {
  color: #f9f6f4;
}

.background-sky {
  background-color: #dfe3e8;
}

.color-sky {
  color: #dfe3e8;
}

.background-sky-dark {
  background-color: #c4cdd5;
}

.color-sky-dark {
  color: #c4cdd5;
}

.background-black {
  background-color: #000000;
}

.color-black {
  color: #000000;
}

.background-white {
  background-color: #ffffff;
}

.color-white {
  color: #ffffff;
}

.background-green {
  background-color: #58b44b;
}

.color-green {
  color: #58b44b;
}

.background-red {
  background-color: #eb644d;
}

.color-red {
  color: #eb644d;
}

.background-facebook {
  background-color: #385a98;
}

.color-facebook {
  color: #385a98;
}

.background-twitter {
  background-color: #55acee;
}

.color-twitter {
  color: #55acee;
}

.background-linkedin {
  background-color: #0976b4;
}

.color-linkedin {
  color: #0976b4;
}

.background-pinterest {
  background-color: #bd081c;
}

.color-pinterest {
  color: #bd081c;
}

.background-grey-lightest {
  background-color: #dfe3e8;
}

.color-grey-lightest {
  color: #dfe3e8;
}

.background-grey-lighter {
  background-color: #c4cdd5;
}

.color-grey-lighter {
  color: #c4cdd5;
}

.background-grey-light {
  background-color: #919ea9;
}

.color-grey-light {
  color: #919ea9;
}

.background-grey-lightish {
  background-color: #64737f;
}

.color-grey-lightish {
  color: #64737f;
}

.background-grey {
  background-color: #454f59;
}

.color-grey {
  color: #454f59;
}

.background-grey-dark {
  background-color: #212b37;
}

.color-grey-dark {
  color: #212b37;
}

.background-blue-lightest {
  background-color: #f6fafd;
}

.color-blue-lightest {
  color: #f6fafd;
}

.background-blue-lighter {
  background-color: #00baff;
}

.color-blue-lighter {
  color: #00baff;
}

.background-blue-light {
  background-color: #479ccf;
}

.color-blue-light {
  color: #479ccf;
}

.background-blue {
  background-color: #368aa9;
}

.color-blue {
  color: #368aa9;
}

.background-blue-dark {
  background-color: #206882;
}

.color-blue-dark {
  color: #206882;
}

.background-slate {
  background-color: #30373b;
}

.color-slate {
  color: #30373b;
}

.background-slate-dark {
  background-color: #2a2c2e;
}

.color-slate-dark {
  color: #2a2c2e;
}

.background-slate-darkest {
  background-color: #222222;
}

.color-slate-darkest {
  color: #222222;
}

.announcement {
  padding: 0.9375em;
  text-align: center;
  font-size: 0.875em;
  color: #ffffff;
  background-color: #2a2c2e;
  display: none;
}

@media screen and (max-width: 46.8125em) {
  .announcement {
    position: relative;
    padding-right: 2.675em;
  }
}

.announcement.is-active {
  display: block;
}

.announcement__content {
  display: inline-block;
  margin-bottom: 0;
}

.announcement__link {
  white-space: nowrap;
}

.announcement__icon {
  fill: currentColor;
}

.announcement__close {
  margin-left: 15px;
}

@media screen and (max-width: 46.8125em) {
  .announcement__close {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0.9375em;
    right: 0;
  }
}

.announcement__close > .icon {
  vertical-align: middle;
}

.text--uppercase {
  text-transform: uppercase;
}

.hr {
  height: 30px;
  border: 1px solid #dfe3e8;
  border-color: transparent transparent #dfe3e8;
}

.grid__ruler {
  margin-left: 4px;
  margin-right: 4px;
  margin-bottom: 60px;
  width: 100%;
  float: left;
}

@media screen and (min-width: 67.5em) {
  .grid__ruler {
    margin-left: 18px;
    margin-right: 18px;
  }
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .grid__ruler {
    margin-left: 9px;
    margin-right: 9px;
  }
}

.hide {
  display: none;
}

.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.marketing-button--visual-link {
  text-decoration: underline;
  font-weight: 700;
  color: #5c6ac4;
}

.marketing-button--visual-link:hover,
.marketing-button--visual-link:focus {
  background: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.marketing-label__optional-label {
  color: #637381;
  float: right;
  font-weight: 400;
}

.marketing-input__field-character-count {
  font-size: 0.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.section:not(.section--tight) {
  padding: 30px 0;
}

@media screen and (min-width: 67.5em) {
  .section:not(.section--tight) {
    padding-top: 5.625em;
    padding-bottom: 5.625em;
  }
}

.footer-country-select__content.popover-content {
  -webkit-column-count: 1;
  column-count: 1;
}

@media screen and (min-width: 46.875em) {
  .footer-country-select__content.popover-content {
    padding-left: 15px;
    -webkit-column-count: 2;
    column-count: 2;
  }
}

.footer-country-select__country-button {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: ShopifySans, Helvetica, Arial, sans-serif;
  font-weight: 700;
  color: #454f5b;
  text-align: left;
  text-decoration: none;
  white-space: nowrap;
}

.footer-country-select__badge {
  background-color: #b7ecec;
  color: #212b35;
  font-size: 0.75em;
  padding: 0.25em 0.5em 0.3125em;
  margin-left: 0.5em;
  border-radius: 1.125em;
  font-weight: 500;
}

.announcement {
  background-color: #202e78;
}

#baguetteBox-overlay .full-image figcaption {
  padding: 0.4em;
  font-size: 1.4em;
  line-height: 1.4;
  color: #ffffff;
  font-family: inherit;
}

.app-store-logo {
  display: inline-block;
  width: 203px;
  height: 32px;
  /*margin-top: -4px*/
}

.code-header-devby {
  display: inline-block;
  color: #999;
  font-size: 0.75em;
  font-weight: 500;
  text-transform: uppercase;
  position: relative;
  top: -1px;
  margin: 0 10px;
}

@media screen and (max-width: 28em) {
  .code-header-devby {
    display: none;
  }
}

.code-header-logo {
  display: inline-block;
  width: 112px;
  height: 32px;
  position: relative;
  top: 8px;
}

.code-header-logo > .icon {
  width: 100%;
  height: 100%;
}

.drawer .app-store-logo {
  margin-top: 0;
}

.marketing-nav .popover-content {
  padding: 0;
}

.marketing-nav__user {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2;
}

.marketing-nav__hamburger {
  margin-left: 0;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

.button-as-link {
  background: none;
  border: 0;
  color: #5c6ac4;
  cursor: pointer;
  font: inherit;
  padding: 0;
}

.app-collection {
  display: -ms-grid;
  display: grid;
  grid-template-areas: "heading" "subheading" "item-1" "item-2" "item-3" "item-4" "more-link";
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  grid-column-gap: 8px;
  grid-auto-columns: 1fr;
  padding-left: calc(4px + 5%);
  padding-right: calc(4px + 5%);
}

.app-collection .app-collection__heading {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-area: heading;
  margin-bottom: 0.5em;
  font-weight: 500;
}

.app-collection .app-collection__subheading {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-area: subheading;
  color: #637381;
  font-weight: 400;
}

.app-collection .app-collection__more-link {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 13;
  -ms-grid-row-span: 1;
  grid-area: more-link;
}

.app-collection .app-collection__item:nth-of-type(1n) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 5;
  -ms-grid-row-span: 1;
  grid-area: item-1;
}

.app-collection .app-collection__item:nth-of-type(2n) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 7;
  -ms-grid-row-span: 1;
  grid-area: item-2;
}

.app-collection .app-collection__item:nth-of-type(3n) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 9;
  -ms-grid-row-span: 1;
  grid-area: item-3;
}

.app-collection .app-collection__item:nth-of-type(4n) {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 11;
  -ms-grid-row-span: 1;
  grid-area: item-4;
}

.app-collection .app-collection__item {
  margin: 15px 0;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .app-collection {
    -ms-grid-columns: 1fr 18px 1fr;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 18px;
    grid-template-areas: "heading heading" "subheading more-link" "item-1 item-2" "item-3 item-4";
    padding-left: calc(9px + 5%);
    padding-right: calc(9px + 5%);
  }
  .app-collection .app-collection__heading {
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-area: heading;
  }
  .app-collection .app-collection__subheading {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-area: subheading;
  }
  .app-collection .app-collection__more-link {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-area: more-link;
    text-align: right;
  }
  .app-collection .app-collection__item:nth-of-type(1n) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    grid-area: item-1;
  }
  .app-collection .app-collection__item:nth-of-type(2n) {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    grid-area: item-2;
  }
  .app-collection .app-collection__item:nth-of-type(3n) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 7;
    -ms-grid-row-span: 1;
    grid-area: item-3;
  }
  .app-collection .app-collection__item:nth-of-type(4n) {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 7;
    -ms-grid-row-span: 1;
    grid-area: item-4;
  }
}

@media screen and (min-width: 67.5em) {
  .app-collection {
    -ms-grid-columns: 1fr 36px 1fr 36px 1fr 36px 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-column-gap: 36px;
    grid-template-areas: "heading heading heading heading" "subheading subheading subheading more-link" "item-1 item-2 item-3 item-4";
    padding-left: calc(18px + 5%);
    padding-right: calc(18px + 5%);
  }
  .app-collection .app-collection__heading {
    -ms-grid-column: 1;
    -ms-grid-column-span: 7;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-area: heading;
  }
  .app-collection .app-collection__subheading {
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-area: subheading;
  }
  .app-collection .app-collection__more-link {
    -ms-grid-column: 7;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-area: more-link;
    text-align: right;
  }
  .app-collection .app-collection__item:nth-of-type(1n) {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    grid-area: item-1;
  }
  .app-collection .app-collection__item:nth-of-type(2n) {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    grid-area: item-2;
  }
  .app-collection .app-collection__item:nth-of-type(3n) {
    -ms-grid-column: 5;
    -ms-grid-column-span: 1;
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    grid-area: item-3;
  }
  .app-collection .app-collection__item:nth-of-type(4n) {
    -ms-grid-column: 7;
    -ms-grid-column-span: 1;
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    grid-area: item-4;
  }
  .app-collection .app-collection__item {
    margin-top: 30px;
  }
}

.as-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition: width ease 100ms;
  transition: width ease 100ms;
}

.as-nav__primary-list,
.as-nav__secondary-list {
  margin: 12px 0;
}

.as-nav__primary-list {
  border-right: 1px solid transparent;
  -webkit-transition: border ease 100ms;
  transition: border ease 100ms;
}

.as-nav__primary-list--active-children {
  border-color: #dfe3e8;
}

.as-nav__secondary-list {
  visibility: hidden;
  position: absolute;
  top: 10px;
  right: 0;
  height: 100%;
}

.as-nav__item--active .as-nav__secondary-list {
  visibility: visible;
}

.as-nav__secondary-list--2-col {
  -webkit-column-count: 2;
  column-count: 2;
  -webkit-column-gap: 0;
  column-gap: 0;
}

.as-nav__secondary-list--2-col .as-nav__item {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
}

.as-nav__item--primary {
  padding-right: 15px;
}

.as-nav__item--secondary {
  opacity: 0;
  -webkit-transition: opacity ease 300ms;
  transition: opacity ease 300ms;
}

.as-nav__item--active .as-nav__item--secondary {
  opacity: 1;
  -webkit-transition-delay: 100ms;
  transition-delay: 100ms;
}

.as-nav__item--secondary .as-nav__link {
  border: none;
}

.as-nav__item--secondary-link-appearance .as-nav__link {
  color: #5c6ac4;
  font-weight: normal;
  text-decoration: underline;
}

.as-nav__link {
  position: relative;
  cursor: pointer;
}

.as-nav__link-chevron {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 0;
  height: 1em;
  width: 1em;
  fill: #c4ccd2;
}

.as-nav__item--active .as-nav__link-chevron {
  fill: #212b35;
}

.featured-app-group__header {
  font-weight: 500;
}

.featured-app-group__item--is-animating {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

@media screen and (min-width: 46.875em) {
  .featured-app-group__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .featured-app-group__item {
    max-width: 25%;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
  }
  .featured-app-group__item + .featured-app-group__item {
    margin-left: 20px;
  }
}

@media screen and (min-width: 67.5em) {
  .featured-app-group__item + .featured-app-group__item {
    margin-left: 40px;
  }
}

@media screen and (max-width: 46.8125em) {
  .featured-app-group__container.ms-touch {
    overflow-x: scroll;
    overflow-y: hidden;
    -ms-overflow-style: none;
    -ms-scroll-chaining: none;
    -ms-scroll-snap-type: mandatory;
    -ms-scroll-snap-points-x: snapInterval(0%, 100%);
  }
  .featured-app-group__container--1-nodes {
    width: calc(1 * (60vw - (20px / 2)));
  }
  .featured-app-group__container--2-nodes {
    width: calc(2 * (60vw - (20px / 2)));
  }
  .featured-app-group__container--3-nodes {
    width: calc(3 * (60vw - (20px / 2)));
  }
  .featured-app-group__container--4-nodes {
    width: calc(4 * (60vw - (20px / 2)));
  }
  .featured-app-group__container--5-nodes {
    width: calc(5 * (60vw - (20px / 2)));
  }
  .featured-app-group__container--6-nodes {
    width: calc(6 * (60vw - (20px / 2)));
  }
  .featured-app-group__container--7-nodes {
    width: calc(7 * (60vw - (20px / 2)));
  }
  .featured-app-group__container--8-nodes {
    width: calc(8 * (60vw - (20px / 2)));
  }
  .featured-app-group__container--9-nodes {
    width: calc(9 * (60vw - (20px / 2)));
  }
  .featured-app-group__item {
    position: relative;
    float: left;
    width: calc(60vw - (20px / 2));
    padding-right: 10px;
  }
  .featured-app-group__item + .featured-app-group__item {
    padding-left: 10px;
  }
}

.search-filters {
  background-color: #f9fafb;
  border-radius: 5px;
  padding: 15px;
}

.ui-sheet .search-filters {
  display: block;
}

.search-filter__section + .search-filter__section {
  border-top: 1px solid #dfe3e8;
  padding-top: 15px;
  margin-top: 15px;
}

.search-filter__section:last-of-type {
  margin-bottom: -0.625em;
}

.search-filter-group__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 7.5px;
  padding-top: 7.5px;
  line-height: 1;
  width: 100%;
}

.search-filter-group__header-button {
  overflow: hidden;
}

.search-filter-group__header-title {
  text-align: left;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.search-filter-group__header-count {
  font-weight: 400;
  color: #637381;
  font-size: 0.875em;
  position: relative;
  top: -1px;
}

.search-filter-group__header-icon {
  height: 20px;
  width: 20px;
  -webkit-transform: rotate(0);
  transform: rotate(0);
  -webkit-transition: -webkit-transform 150ms;
  transition: -webkit-transform 150ms;
  transition: transform 150ms;
  transition: transform 150ms, -webkit-transform 150ms;
}

.js-is-active .search-filter-group__header-icon {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.search-filter-group__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-left: 30px;
}

.search-filter-group__item .marketing-radio-label {
  -webkit-box-flex: 1;
  -ms-flex: 1 auto;
  flex: 1 auto;
}

.search-filter-group__item-count {
  line-height: 2.34375em;
  font-variant-numeric: tabular-nums;
}

.search-filters .marketing-radio-label::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}

.search-filter--is-selected .marketing-radio-label::after {
  left: 5px;
  top: 5px;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background-color: #5c6ac4;
}

.search-filter--is-selected .marketing-radio-label::before {
  border-color: #5c6ac4;
}

.search-filter-group__item-name {
  padding-top: 5px;
  overflow: hidden;
  color: inherit;
  margin-bottom: 0;
}

.search-filter-group__item-name.marketing-radio-label::before,
.search-filter-group__item-name.marketing-radio-label::after {
  margin-top: 5px;
}

@media screen and (min-width: 67.5em) {
  .search-filters__sort-by .marketing-input-wrapper {
    margin-bottom: 0;
  }
}

@media screen and (min-width: 90em) {
  .search-results__grid .grid__item {
    width: 33.33333%;
  }
  .search-results__grid .grid__item-push {
    right: auto;
    left: 33.33333%;
  }
}

.integrations-list__item {
  display: inline-block;
}

.truncate-content-toggle {
  display: none;
}

.truncate-content-toggle--show {
  margin-top: 1em;
  display: block;
}

.ui-app-card {
  display: inline-block;
}

.ui-app-card:hover .ui-app-card__icon-container {
  -webkit-box-shadow: 1px 2px 18px 1px #919ea9;
  box-shadow: 1px 2px 18px 1px #919ea9;
}

.ui-app-card:hover .ui-app-card__name {
  color: #5c6ac4;
}

.ui-app-card__container {
  display: -ms-inline-grid;
  display: inline-grid;
  -ms-grid-columns: 58px 0 30px 0 auto 0 1fr;
  grid-template-columns: 58px 30px auto 1fr;
  grid-column-gap: 0;
  grid-template-areas: "icon icon name name" "icon icon context context" "icon icon tagline tagline" "icon icon rating rating";
  max-width: 350px;
  color: #637381;
}

.ui-app-card__icon-container {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 7;
  grid-area: icon;
  height: 72px;
  width: 72px;
  min-width: 72px;
  -webkit-box-shadow: 1px 2px 14px 1px #c4cdd5;
  box-shadow: 1px 2px 14px 1px #c4cdd5;
  border-radius: 6px;
  background-color: #ffffff;
  overflow: hidden;
}

.ui-app-card__icon img {
  height: 72px;
  width: 72px;
}

.ui-app-card__badge {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  grid-column: 2 / span 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-row: 1 / span 1;
  -ms-grid-column-align: end;
  justify-self: end;
  z-index: 10;
}

.ui-app-card__name {
  -ms-grid-column: 5;
  -ms-grid-column-span: 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-area: name;
  -ms-flex-item-align: center;
  align-self: center;
  -ms-grid-row-align: center;
  margin: 0;
  overflow: hidden;
}

.ui-app-card__details {
  -ms-grid-column: 5;
  -ms-grid-column-span: 3;
  -ms-grid-row: 5;
  -ms-grid-row-span: 1;
  grid-area: tagline;
  margin: 4px 0 0;
}

.ui-app-card__review {
  -ms-grid-column: 5;
  -ms-grid-column-span: 3;
  -ms-grid-row: 7;
  -ms-grid-row-span: 1;
  grid-area: rating;
  -ms-flex-item-align: start;
  align-self: start;
  -ms-grid-row-align: start;
  margin: 0;
  font-size: 0.875em;
  color: #454f5b;
}

.ui-review-count-summary {
  color: #637381;
}

.ui-app-card__context {
  -ms-grid-column: 5;
  -ms-grid-column-span: 3;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-area: context;
  -ms-flex-item-align: center;
  align-self: center;
  -ms-grid-row-align: center;
  font-size: 0.875em;
  margin-top: 0.3125em;
}

.ui-app-card__pricing {
  color: #454f5b;
  line-height: 1.25;
  padding: 0.125em 0;
}

.ui-app-card__requirements {
  border: 1px solid #eb644d;
  border-radius: 0.3125em;
  color: #eb644d;
  padding: 0 0.75em;
  display: inline-block;
  line-height: 1.3;
}

@media screen and (max-width: 67.4375em) {
  .ui-app-card__container {
    -ms-grid-columns: 72px 0 22px 0 auto 0 1fr;
    grid-template-columns: 72px 22px auto 1fr;
    grid-column-gap: 0;
    grid-template-areas: "icon icon name name" "icon icon context context" "icon icon tagline tagline" "icon icon rating rating";
  }
  .ui-app-card__icon-container {
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 7;
    grid-area: icon;
    height: 72px;
    width: 72px;
    min-width: 72px;
  }
  .ui-app-card__icon img {
    height: 72px;
    width: 72px;
  }
  .ui-app-card__details {
    font-size: 0.875em;
  }
  .ui-app-card__review {
    font-size: 0.75em;
  }
  .ui-app-card__context {
    font-size: 0.75em;
  }
}

.ui-app-card-ad-wrapper__ad {
  font-size: 0.875em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 0.25em;
  padding-bottom: 0.25em;
}

.ui-app-card-ad-wrapper__ad .popover-wrapper {
  width: 1.25em;
  height: 1.25em;
  color: #919eab;
}

.ui-app-card-ad-wrapper__ad .popover-wrapper:hover,
.ui-app-card-ad-wrapper__ad .popover-wrapper button:focus {
  color: #454f5b;
}

.ui-app-card-ad-wrapper__ad button {
  display: block;
  width: 100%;
  height: 100%;
}

.ui-app-card-ad-wrapper__ad .icon {
  width: 100%;
  height: 100%;
}

.ui-app-card-ad-wrapper__ad-text {
  padding-right: 0.1em;
}

@media screen and (max-width: 67.4375em) {
  .ui-app-card-ad-wrapper__ad {
    font-size: 0.75em;
  }
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .ui-app-card-ad-wrapper--ad-spacing-2 {
    padding-top: 1.375em;
  }
}

@media screen and (min-width: 67.5em) {
  .ui-app-card-ad-wrapper--ad-spacing-2 {
    padding-top: 1.5625em;
  }
}

@media screen and (min-width: 90em) {
  .ui-app-card-ad-wrapper--ad-spacing-2 {
    padding-top: 0;
  }
  .ui-app-card-ad-wrapper--ad-spacing-3 {
    padding-top: 1.5625em;
  }
}

.ui-app-store-hero {
  background-color: #f9f6f4;
  padding: 2.475em 0;
}

@media screen and (min-width: 46.875em) {
  .ui-app-store-hero {
    padding: 3.75em 0;
  }
}

.ui-app-store-hero .ui-app-store-hero__container {
  display: -ms-grid;
  display: grid;
}

.ui-app-store-hero .ui-app-store-hero__kicker {
  margin-bottom: 1.25em;
  text-transform: uppercase;
  color: #637381;
  font-weight: 400;
}

.ui-app-store-hero .ui-app-store-hero__header {
  font-weight: 400;
}

.ui-app-store-hero .ui-app-store-hero__header__subscript {
  display: inline-block;
  color: #637381;
}

.ui-app-store-hero .ui-app-store-hero__description {
  color: #454f5b;
}

.ui-app-store-hero .ui-app-store-hero__ratings {
  color: #454f5b;
}

.ui-app-store-hero .ui-app-store-hero__cta-button {
  min-width: 100%;
  margin-top: 2em;
}

.ui-app-store-hero .ui-app-store-hero__footer {
  color: #637381;
  font-weight: 400;
  margin-bottom: 0;
}

@media screen and (min-width: 46.875em) {
  .ui-app-store-hero .ui-app-store-hero__cta-button {
    min-width: 12.5em;
  }
}

.ui-app-store-hero--type-app-stub,
.ui-app-store-hero--type-app-details {
  background: url("https://cdn.shopifycloud.com/shopify_app_store/assets/v2/backgrounds/hero-073ea0d17442ccee222f7e710d062b8c05b22f59910e3aa366fa21b586660c0d.svg")
    no-repeat top left;
  background-size: cover;
}

.ui-app-store-hero--type-app-stub .ui-app-store-hero__container,
.ui-app-store-hero--type-app-details .ui-app-store-hero__container {
  -ms-grid-columns: 1fr 8px 3fr;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 8px;
  grid-template-areas: "kicker kicker" "icon header" "icon description" "icon ratings" "cta cta" "footer footer" "media media";
}

.ui-app-store-hero--type-app-stub
  .ui-app-store-hero__container
  .ui-app-store-hero__kicker,
.ui-app-store-hero--type-app-details
  .ui-app-store-hero__container
  .ui-app-store-hero__kicker {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-area: kicker;
}

.ui-app-store-hero--type-app-stub
  .ui-app-store-hero__container
  .ui-app-store-hero__header,
.ui-app-store-hero--type-app-details
  .ui-app-store-hero__container
  .ui-app-store-hero__header {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-area: header;
  margin-bottom: 0.9375em;
}

.ui-app-store-hero--type-app-stub
  .ui-app-store-hero__container
  .ui-app-store-hero__header__app-name,
.ui-app-store-hero--type-app-details
  .ui-app-store-hero__container
  .ui-app-store-hero__header__app-name {
  display: inline-block;
  margin-bottom: 0;
  padding-right: 0.3em;
  font-weight: 400;
}

.ui-app-store-hero--type-app-stub
  .ui-app-store-hero__container
  .ui-app-store-hero__app-icon,
.ui-app-store-hero--type-app-details
  .ui-app-store-hero__container
  .ui-app-store-hero__app-icon {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 5;
  grid-area: icon;
  -ms-flex-item-align: start;
  align-self: start;
  -ms-grid-row-align: start;
  width: 75%;
  max-width: 84px;
  -webkit-box-shadow: 0 0 12px 1px #c4cdd5;
  box-shadow: 0 0 12px 1px #c4cdd5;
  border-radius: 6px;
  overflow: hidden;
}

.ui-app-store-hero--type-app-stub
  .ui-app-store-hero__container
  .ui-app-store-hero__app-icon
  img,
.ui-app-store-hero--type-app-details
  .ui-app-store-hero__container
  .ui-app-store-hero__app-icon
  img {
  height: auto;
}

.ui-app-store-hero--type-app-stub
  .ui-app-store-hero__container
  .ui-app-store-hero__description,
.ui-app-store-hero--type-app-details
  .ui-app-store-hero__container
  .ui-app-store-hero__description {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 5;
  -ms-grid-row-span: 1;
  grid-area: description;
  font-weight: 400;
  margin-bottom: 0.9375em;
}

.ui-app-store-hero--type-app-stub
  .ui-app-store-hero__container
  .ui-app-store-hero__ratings,
.ui-app-store-hero--type-app-details
  .ui-app-store-hero__container
  .ui-app-store-hero__ratings {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 7;
  -ms-grid-row-span: 1;
  grid-area: ratings;
  color: #637381;
  font-weight: 400;
  margin-bottom: 0;
}

.ui-app-store-hero--type-app-stub
  .ui-app-store-hero__container
  .ui-app-store-hero__cta,
.ui-app-store-hero--type-app-details
  .ui-app-store-hero__container
  .ui-app-store-hero__cta {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 9;
  -ms-grid-row-span: 1;
  grid-area: cta;
  margin-bottom: 0.9375em;
}

.ui-app-store-hero--type-app-stub
  .ui-app-store-hero__container
  .ui-app-store-hero__footer,
.ui-app-store-hero--type-app-details
  .ui-app-store-hero__container
  .ui-app-store-hero__footer {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 11;
  -ms-grid-row-span: 1;
  grid-area: footer;
}

.ui-app-store-hero--type-app-stub
  .ui-app-store-hero__container
  .ui-app-store-hero__media,
.ui-app-store-hero--type-app-details
  .ui-app-store-hero__container
  .ui-app-store-hero__media {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 13;
  -ms-grid-row-span: 1;
  grid-area: media;
  -ms-grid-column-align: start;
  justify-self: start;
  margin-top: 2em;
  width: 100%;
}

.ui-app-store-hero--type-app-stub
  .ui-app-store-hero__container
  .ui-app-store-hero__media__video-container,
.ui-app-store-hero--type-app-details
  .ui-app-store-hero__container
  .ui-app-store-hero__media__video-container {
  position: relative;
  height: 50vw;
  border-radius: 6px;
  overflow: hidden;
  -webkit-box-shadow: 0 0 0.3em 1px #c4cdd5;
  box-shadow: 0 0 0.3em 1px #c4cdd5;
}

.ui-app-store-hero--type-app-stub
  .ui-app-store-hero__container
  .ui-app-store-hero__media__video,
.ui-app-store-hero--type-app-details
  .ui-app-store-hero__container
  .ui-app-store-hero__media__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .ui-app-store-hero--type-app-stub .ui-app-store-hero__container,
  .ui-app-store-hero--type-app-details .ui-app-store-hero__container {
    -ms-grid-columns: 0.75fr 18px 3.25fr 18px 2fr;
    grid-template-columns: 0.75fr 3.25fr 2fr;
    grid-column-gap: 18px;
    grid-template-areas: "kicker kicker kicker" "icon header media" "icon description media" "icon ratings media" ". cta media" ". footer media" ". . media";
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__kicker,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__kicker {
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-area: kicker;
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__header,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__header {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-area: header;
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__app-icon,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__app-icon {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 5;
    grid-area: icon;
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__description,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__description {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    grid-area: description;
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__ratings,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__ratings {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 7;
    -ms-grid-row-span: 1;
    grid-area: ratings;
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__cta,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__cta {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 9;
    -ms-grid-row-span: 1;
    grid-area: cta;
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__footer,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__footer {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 11;
    -ms-grid-row-span: 1;
    grid-area: footer;
    margin-bottom: 0;
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__media,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__media {
    -ms-grid-column: 5;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 11;
    grid-area: media;
    -ms-grid-column-align: end;
    justify-self: end;
    margin-top: 0;
    height: 100%;
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__media__video-container,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__media__video-container {
    padding-bottom: 28.14%;
    padding-top: 28.14%;
    height: 0;
  }
}

@media screen and (min-width: 67.5em) {
  .ui-app-store-hero--type-app-stub .ui-app-store-hero__container,
  .ui-app-store-hero--type-app-details .ui-app-store-hero__container {
    -ms-grid-columns: 1fr 36px 6fr 36px 5fr;
    grid-template-columns: 1fr 6fr 5fr;
    grid-column-gap: 36px;
    grid-template-areas: "kicker kicker kicker" "icon header media" "icon description media" "icon ratings media" ". cta media" ". footer media" ". . media";
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__kicker,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__kicker {
    -ms-grid-column: 1;
    -ms-grid-column-span: 5;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-area: kicker;
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__header,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__header {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-area: header;
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__app-icon,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__app-icon {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 5;
    grid-area: icon;
    width: 100%;
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__description,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__description {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    grid-area: description;
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__ratings,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__ratings {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 7;
    -ms-grid-row-span: 1;
    grid-area: ratings;
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__cta,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__cta {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 9;
    -ms-grid-row-span: 1;
    grid-area: cta;
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__footer,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__footer {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 11;
    -ms-grid-row-span: 1;
    grid-area: footer;
    margin-bottom: 0;
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__media,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__media {
    -ms-grid-column: 5;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 11;
    grid-area: media;
    -ms-grid-column-align: end;
    justify-self: end;
    margin-top: 0;
    height: 100%;
  }
  .ui-app-store-hero--type-app-stub
    .ui-app-store-hero__container
    .ui-app-store-hero__media__video-container,
  .ui-app-store-hero--type-app-details
    .ui-app-store-hero__container
    .ui-app-store-hero__media__video-container {
    padding-bottom: 28.14%;
    padding-top: 28.14%;
    height: 0;
  }
}

.ui-app-store-hero--type-category img {
  max-height: 100%;
  position: absolute;
}

.ui-app-store-hero--type-category .ui-app-store-hero__media {
  display: none;
  position: relative;
}

.ui-app-store-hero--type-category .ui-app-store-hero__container {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  grid-column-gap: 8px;
  grid-template-areas: "header" "description" "content";
}

.ui-app-store-hero--type-category
  .ui-app-store-hero__container
  .ui-app-store-hero__header {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-area: header;
}

.ui-app-store-hero--type-category
  .ui-app-store-hero__container
  .ui-app-store-hero__description {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-area: description;
}

.ui-app-store-hero--type-category
  .ui-app-store-hero__container
  .ui-app-store-hero__content {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 5;
  -ms-grid-row-span: 1;
  grid-area: content;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .ui-app-store-hero--type-category .ui-app-store-hero__container {
    -ms-grid-columns: 4fr 18px 2fr;
    grid-template-columns: 4fr 2fr;
    grid-column-gap: 18px;
    grid-template-areas: "header media" "description media" "content media" ". media";
  }
  .ui-app-store-hero--type-category
    .ui-app-store-hero__container
    .ui-app-store-hero__header {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-area: header;
  }
  .ui-app-store-hero--type-category
    .ui-app-store-hero__container
    .ui-app-store-hero__description {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-area: description;
  }
  .ui-app-store-hero--type-category
    .ui-app-store-hero__container
    .ui-app-store-hero__content {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    grid-area: content;
  }
  .ui-app-store-hero--type-category
    .ui-app-store-hero__container
    .ui-app-store-hero__media {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 7;
    grid-area: media;
    display: block;
    margin-right: -3.75em;
    margin-left: -1.25em;
  }
}

@media screen and (min-width: 67.5em) {
  .ui-app-store-hero--type-category .ui-app-store-hero__container {
    -ms-grid-columns: 7fr 36px 5fr;
    grid-template-columns: 7fr 5fr;
    grid-column-gap: 36px;
    grid-template-areas: "header media" "description media" "content media" ". media";
  }
  .ui-app-store-hero--type-category
    .ui-app-store-hero__container
    .ui-app-store-hero__header {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-area: header;
  }
  .ui-app-store-hero--type-category
    .ui-app-store-hero__container
    .ui-app-store-hero__description {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-area: description;
  }
  .ui-app-store-hero--type-category
    .ui-app-store-hero__container
    .ui-app-store-hero__content {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 5;
    -ms-grid-row-span: 1;
    grid-area: content;
  }
  .ui-app-store-hero--type-category
    .ui-app-store-hero__container
    .ui-app-store-hero__media {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 7;
    grid-area: media;
    display: block;
    margin-top: -3.75em;
    margin-bottom: -3.75em;
  }
}

.ui-app-store-hero--type-collection {
  background: url("https://cdn.shopifycloud.com/shopify_app_store/assets/v2/backgrounds/hero-073ea0d17442ccee222f7e710d062b8c05b22f59910e3aa366fa21b586660c0d.svg")
    no-repeat top left;
  background-size: cover;
  padding: 1.875em 0 1.25em;
}

@media screen and (min-width: 46.875em) {
  .ui-app-store-hero--type-collection {
    padding: 1.25em 0 0.9375em;
  }
}

@media screen and (min-width: 67.5em) {
  .ui-app-store-hero--type-collection {
    padding: 2.8125em 0 2.1875em;
  }
}

.ui-app-store-hero--type-collection .ui-app-store-hero__container {
  -ms-grid-columns: 1fr;
  grid-template-columns: 1fr;
  grid-column-gap: 8px;
  grid-template-areas: "header" "description";
}

.ui-app-store-hero--type-collection
  .ui-app-store-hero__container
  .ui-app-store-hero__header {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-area: header;
}

.ui-app-store-hero--type-collection
  .ui-app-store-hero__container
  .ui-app-store-hero__description {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-area: description;
  margin-bottom: 0;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .ui-app-store-hero--type-collection .ui-app-store-hero__container {
    -ms-grid-columns: 4fr 18px 2fr;
    grid-template-columns: 4fr 2fr;
    grid-column-gap: 18px;
    grid-template-areas: "header ." "description .";
  }
  .ui-app-store-hero--type-collection
    .ui-app-store-hero__container
    .ui-app-store-hero__header {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-area: header;
  }
  .ui-app-store-hero--type-collection
    .ui-app-store-hero__container
    .ui-app-store-hero__description {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-area: description;
  }
}

@media screen and (min-width: 67.5em) {
  .ui-app-store-hero--type-collection .ui-app-store-hero__container {
    -ms-grid-columns: 7fr 36px 5fr;
    grid-template-columns: 7fr 5fr;
    grid-column-gap: 36px;
    grid-template-areas: "header ." "description .";
  }
  .ui-app-store-hero--type-collection
    .ui-app-store-hero__container
    .ui-app-store-hero__header {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-area: header;
  }
  .ui-app-store-hero--type-collection
    .ui-app-store-hero__container
    .ui-app-store-hero__description {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-area: description;
  }
}

.ui-app-store-hero--type-home {
  position: relative;
  z-index: 0;
  overflow: hidden;
  background: #f3f4fb
    url("https://cdn.shopifycloud.com/shopify_app_store/assets/v2/backgrounds/home-hero-55f84d849386d382681ea2b39abf5cfb93e2dafa4432a12f5312720cab9e3613.svg")
    no-repeat left -60px;
  background-size: cover;
  padding-top: git 3.75em;
  padding-bottom: 1.25em;
}

@media screen and (min-width: 67.5em) {
  .ui-app-store-hero--type-home {
    background-size: auto;
  }
}

.ui-app-store-hero--type-home::after {
  content: " ";
  position: absolute;
  z-index: -1;
  bottom: -55%;
  left: -10%;
  display: block;
  width: 130%;
  height: 100%;
  background-color: #ffffff;
  -webkit-transform: rotate(-8deg);
  transform: rotate(-8deg);
}

@media screen and (min-width: 46.875em) {
  .ui-app-store-hero--type-home::after {
    bottom: -50%;
  }
}

@media screen and (min-width: 67.5em) {
  .ui-app-store-hero--type-home::after {
    bottom: -60%;
  }
}

.ui-app-store-hero--type-home .hero-search-form {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-area: search;
}

.ui-app-store-hero--type-home .ui-app-store-hero__container {
  -ms-grid-columns: 1fr 8px 1fr;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  grid-template-areas: "header header" "search none" "apps apps";
}

.ui-app-store-hero--type-home
  .ui-app-store-hero__container
  .ui-app-store-hero__header {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-area: header;
}

.ui-app-store-hero--type-home
  .ui-app-store-hero__container
  .ui-app-store-hero__featured-apps {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 5;
  -ms-grid-row-span: 1;
  grid-area: apps;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .ui-app-store-hero--type-home .ui-app-store-hero__container {
    -ms-grid-columns: 3fr 8px 1fr;
    grid-template-columns: 3fr 1fr;
    grid-column-gap: 8px;
  }
}

@media screen and (max-width: 46.8125em) {
  .ui-app-store-hero--type-home .ui-app-store-hero__container {
    -ms-grid-columns: 1fr;
    grid-template-columns: 1fr;
    grid-column-gap: 8px;
  }
}

.hero-search-form,
.ui-app-store-hero__search {
  position: relative;
  margin-bottom: 2.5em;
}

@media screen and (min-width: 46.875em) {
  .hero-search-form,
  .ui-app-store-hero__search {
    margin-bottom: 3.75em;
  }
}

@media screen and (min-width: 67.5em) {
  .hero-search-form,
  .ui-app-store-hero__search {
    margin-bottom: 5em;
  }
}

.hero-search-form > .marketing-input-wrapper,
.ui-app-store-hero__search > .marketing-input-wrapper {
  margin-bottom: 0;
}

.hero-search-form__input {
  padding-right: calc(1em * 2 + 1.4em);
  -webkit-box-shadow: 1px 1px 0.3em 1px #c4cdd5;
  box-shadow: 1px 1px 0.3em 1px #c4cdd5;
}

.hero-search-form__button {
  bottom: 3.2em;
  padding-right: 1em;
  height: 3.2em;
  width: calc(1.4em + 1em);
}

.hero-search-form__suggestions {
  -webkit-box-shadow: 0 0 12px 1px #c4cdd5;
  box-shadow: 0 0 12px 1px #c4cdd5;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.hero-search-form__suggestions > :last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ui-app-store-hero--type-partner {
  background: url("https://cdn.shopifycloud.com/shopify_app_store/assets/v2/backgrounds/hero-073ea0d17442ccee222f7e710d062b8c05b22f59910e3aa366fa21b586660c0d.svg")
    no-repeat top left;
  background-size: cover;
}

.ui-app-store-hero--type-partner .ui-app-store-hero__container {
  -ms-grid-columns: 1fr 8px 3fr;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 8px;
  grid-template-areas: "header header" "description ratings";
}

.ui-app-store-hero--type-partner
  .ui-app-store-hero__container
  .ui-app-store-hero__header {
  -ms-grid-column: 1;
  -ms-grid-column-span: 3;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-area: header;
}

.ui-app-store-hero--type-partner
  .ui-app-store-hero__container
  .ui-app-store-hero__description {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-area: description;
  margin-bottom: 0;
}

.ui-app-store-hero--type-partner
  .ui-app-store-hero__container
  .ui-app-store-hero__ratings {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-area: ratings;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ui-app-store-hero--type-partner
  .ui-app-store-hero__container
  .ui-app-store-hero__ratings
  > div:first-child {
  margin-right: 10px;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .ui-app-store-hero--type-partner .ui-app-store-hero__container {
    -ms-grid-columns: 1fr 18px 3fr 18px 2fr;
    grid-template-columns: 1fr 3fr 2fr;
    grid-column-gap: 18px;
    grid-template-areas: "header header ." "description ratings .";
  }
  .ui-app-store-hero--type-partner
    .ui-app-store-hero__container
    .ui-app-store-hero__header {
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-area: header;
  }
  .ui-app-store-hero--type-partner
    .ui-app-store-hero__container
    .ui-app-store-hero__description {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-area: description;
  }
  .ui-app-store-hero--type-partner
    .ui-app-store-hero__container
    .ui-app-store-hero__ratings {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-area: ratings;
  }
}

@media screen and (min-width: 67.5em) {
  .ui-app-store-hero--type-partner .ui-app-store-hero__container {
    -ms-grid-columns: 1fr 36px 6fr 36px 5fr;
    grid-template-columns: 1fr 6fr 5fr;
    grid-column-gap: 36px;
    grid-template-areas: "header header ." "description ratings .";
  }
  .ui-app-store-hero--type-partner
    .ui-app-store-hero__container
    .ui-app-store-hero__header {
    -ms-grid-column: 1;
    -ms-grid-column-span: 3;
    -ms-grid-row: 1;
    -ms-grid-row-span: 1;
    grid-area: header;
  }
  .ui-app-store-hero--type-partner
    .ui-app-store-hero__container
    .ui-app-store-hero__description {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-area: description;
  }
  .ui-app-store-hero--type-partner
    .ui-app-store-hero__container
    .ui-app-store-hero__ratings {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
    -ms-grid-row: 3;
    -ms-grid-row-span: 1;
    grid-area: ratings;
  }
}

.ui-app-store-hero--type-generic .ui-app-store-hero__container {
  grid-template-areas: "kicker kicker" "icon header" "icon description" "cta cta" "footer footer" "media media";
  -ms-grid-columns: 1fr 8px 3fr;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 8px;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .ui-app-store-hero--type-generic .ui-app-store-hero__container {
    -ms-grid-columns: 0.75fr 18px 3.25fr 18px 2fr;
    grid-template-columns: 0.75fr 3.25fr 2fr;
    grid-column-gap: 18px;
    grid-template-areas: "kicker kicker kicker" "icon header media" "icon description media" "icon ratings media" ". cta media" ". footer media" ". . media";
  }
}

@media screen and (min-width: 67.5em) {
  .ui-app-store-hero--type-generic .ui-app-store-hero__container {
    -ms-grid-columns: 1fr 36px 6fr 36px 5fr;
    grid-template-columns: 1fr 6fr 5fr;
    grid-column-gap: 36px;
    grid-template-areas: "kicker kicker kicker" "icon header media" "icon description media" "icon ratings media" ". cta media" ". footer media" ". . media";
  }
}

.ui-app-store-hero--type-generic
  .ui-app-store-hero__container
  .ui-app-store-hero__icon {
  -ms-grid-column: 1;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 5;
  grid-area: icon;
}

.ui-app-store-hero--type-generic
  .ui-app-store-hero__container
  .ui-app-store-hero__kicker {
  -ms-grid-column: 1;
  -ms-grid-column-span: 5;
  -ms-grid-row: 1;
  -ms-grid-row-span: 1;
  grid-area: kicker;
}

.ui-app-store-hero--type-generic
  .ui-app-store-hero__container
  .ui-app-store-hero__header {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 1;
  grid-area: header;
}

.ui-app-store-hero--type-generic
  .ui-app-store-hero__container
  .ui-app-store-hero__description {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 5;
  -ms-grid-row-span: 1;
  grid-area: description;
}

.ui-app-store-hero--type-generic
  .ui-app-store-hero__container
  .ui-app-store-hero__cta {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 9;
  -ms-grid-row-span: 1;
  grid-area: cta;
}

.ui-app-store-hero--type-generic
  .ui-app-store-hero__container
  .ui-app-store-hero__footer {
  -ms-grid-column: 3;
  -ms-grid-column-span: 1;
  -ms-grid-row: 11;
  -ms-grid-row-span: 1;
  grid-area: footer;
}

.ui-app-store-hero--type-generic
  .ui-app-store-hero__container
  .ui-app-store-hero__media {
  -ms-grid-column: 5;
  -ms-grid-column-span: 1;
  -ms-grid-row: 3;
  -ms-grid-row-span: 11;
  grid-area: media;
}

.ui-app-store-hero--type-generic {
  background: url("https://cdn.shopifycloud.com/shopify_app_store/assets/v2/backgrounds/hero-073ea0d17442ccee222f7e710d062b8c05b22f59910e3aa366fa21b586660c0d.svg")
    no-repeat top left;
  background-size: cover;
}

.ui-app-store-hero--type-generic .ui-app-store-hero__icon {
  -ms-flex-item-align: start;
  align-self: start;
  -ms-grid-row-align: start;
  width: 75%;
  max-width: 84px;
  -webkit-box-shadow: 0 0 12px 1px #c4cdd5;
  box-shadow: 0 0 12px 1px #c4cdd5;
  border-radius: 6px;
  overflow: hidden;
}

.ui-app-store-hero--type-generic .ui-app-store-hero__icon img {
  height: auto;
}

@media screen and (min-width: 67.5em) {
  .ui-app-store-hero--type-generic .ui-app-store-hero__icon {
    width: 100%;
  }
}

.ui-app-store-hero--type-generic .ui-app-store-hero__header {
  margin-bottom: 0.9375em;
}

.ui-app-store-hero--type-generic .ui-app-store-hero__header-text {
  display: inline-block;
  font-weight: 400;
  margin-bottom: 0;
  padding-right: 0.3em;
}

.ui-app-store-hero--type-generic .ui-app-store-hero__description-text {
  font-size: 1.25em;
  font-weight: 400;
  margin-bottom: 0.9375em;
}

@media screen and (min-width: 67.5em) {
  .ui-app-store-hero--type-generic .ui-app-store-hero__description-text {
    font-size: 1.5em;
    line-height: 1.222;
  }
}

.ui-app-store-hero--type-generic .ui-app-store-hero__cta {
  margin-bottom: 0.9375em;
}

.ui-app-store-hero--type-generic .ui-app-store-hero__cta-button {
  margin-top: 0;
}

.ui-card {
  overflow: hidden;
  padding: 15px;
  background: #ffffff;
  -webkit-box-shadow: 0 0 6px 1px #c4cdd5;
  box-shadow: 0 0 6px 1px #c4cdd5;
  border-radius: 6px;
}

.ui-card--no-padding {
  padding: 0;
}

.ui-card--large-padding {
  padding: 30px;
}

.ui-category-callout {
  padding-left: 5%;
  padding-right: 5%;
}

@media screen and (min-width: 46.875em) {
  .ui-category-callout {
    min-height: 23.75em;
    padding-bottom: 4.21875em;
  }
}

@media screen and (min-width: 67.5em) {
  .ui-category-callout {
    padding-left: calc(5% + 18px);
    padding-right: calc(5% + 18px);
    padding-bottom: 5.625em;
  }
}

.ui-category-callout__title {
  font-weight: 400;
}

@media screen and (min-width: 46.875em) {
  .ui-category-callout__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -30px;
  }
}

.ui-category-callout__item .ui-card {
  height: 100%;
  -webkit-transition: -webkit-box-shadow 50ms linear;
  transition: -webkit-box-shadow 50ms linear;
  transition: box-shadow 50ms linear;
  transition: box-shadow 50ms linear, -webkit-box-shadow 50ms linear;
}

@media screen and (min-width: 46.875em) {
  .ui-category-callout__item {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 30px;
  }
}

@media screen and (max-width: 46.8125em) {
  .ui-category-callout__item + .ui-category-callout__item {
    margin-top: 30px;
  }
}

.ui-category-callout__item:hover .ui-card,
.ui-category-callout__item:focus-within .ui-card {
  -webkit-box-shadow: 0 0 10px 4px #c4cdd5;
  box-shadow: 0 0 10px 4px #c4cdd5;
}

.ui-category-callout__item:hover .ui-category-callout__item-title,
.ui-category-callout__item:focus-within .ui-category-callout__item-title {
  color: #5c6ac4;
}

.ui-category-callout--type-single {
  padding: 60px;
}

.ui-category-callout--type-single .ui-category-callout__icon {
  text-align: center;
}

@media screen and (max-width: 46.8125em) {
  .ui-category-callout--type-single .ui-category-callout__icon {
    margin-bottom: 0;
  }
}

.ui-category-callout--type-single .ui-category-callout__cta {
  margin-top: 30px;
}

@media screen and (min-width: 46.875em) {
  .ui-category-callout--type-single {
    position: relative;
    overflow: hidden;
    padding-left: 50%;
    padding-right: 12%;
  }
  .ui-category-callout--type-single .ui-category-callout__icon {
    position: absolute;
    left: 0;
    top: 0;
    width: 50%;
    text-align: center;
    height: 100%;
    margin: 0;
  }
  .ui-category-callout--type-single .ui-category-callout__icon img {
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.ui-category-callout__link:focus {
  text-decoration: underline;
}

.ui-category-callout--type-multi .ui-category-callout__item {
  text-align: center;
}

.ui-category-callout--type-multi .ui-category-callout__link {
  display: block;
  padding: 15px;
}

.ui-category-callout--type-multi .ui-category-callout__icon {
  display: block;
  margin: 0;
}

.ui-category-callout--type-multi .ui-category-callout__icon img {
  max-height: 190px;
}

.ui-category-callout--type-multi .ui-category-callout__item-title {
  font-weight: 400;
  font-size: 1.25em;
}

.ui-empty-state {
  margin: 30px 0;
  text-align: center;
}

.ui-empty-state__description {
  max-width: 34.375em;
  margin: 0 auto;
}

.ui-empty-state__image + .ui-empty-state__description {
  margin-top: 30px;
}

.ui-empty-state__cta {
  margin-top: 30px;
}

.ui-expandable-content__inner {
  position: relative;
  overflow: hidden;
  /*max-height: 200px;*/
  -webkit-transition-property: max-height;
  transition-property: max-height;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  margin-bottom: 30px;
}

.ui-expandable-content__inner::after {
  content: "";
  -webkit-transition-property: top;
  transition-property: top;
  -webkit-transition-duration: 300ms;
  transition-duration: 300ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 0)),
    to(#fff)
  );
  background-image: linear-gradient(rgba(255, 255, 255, 0), #fff 100%);
  position: absolute;
  top: calc(100% - 6.25em);
  left: 0;
  height: 6.25em;
  width: 100%;
}

.ui-expandable-content__trigger {
  -webkit-transition-property: opacity, height, -webkit-transform;
  transition-property: opacity, height, -webkit-transform;
  transition-property: opacity, transform, height;
  transition-property: opacity, transform, height, -webkit-transform;
  -webkit-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.ui-expandable-content__collapse-trigger {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}

.ui-expandable-content__collapse-trigger-container {
  height: 0;
}

.ui-expandable-content--expanded .ui-expandable-content__inner::after {
  top: 100%;
}

.ui-expandable-content--expanded .ui-expandable-content__trigger-container {
  height: 0;
}

.ui-expandable-content--expanded
  .ui-expandable-content__collapse-trigger-container {
  height: auto;
}

.ui-expandable-content--expanded .ui-expandable-content__expand-trigger {
  -webkit-transform: scale(0);
  transform: scale(0);
  opacity: 0;
}

.ui-expandable-content--expanded .ui-expandable-content__collapse-trigger {
  -webkit-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

.ui-expandable-content--inactive .ui-expandable-content__inner::after,
.ui-expandable-content--inactive .ui-expandable-content__trigger-container,
.ui-expandable-content--inactive .ui-expandable-content__collapse-trigger {
  display: none;
}

.ui-featured-app-card__banner {
  height: auto;
  width: 100%;
  -webkit-box-shadow: 0 0 6px 1px #c4cdd5;
  box-shadow: 0 0 6px 1px #c4cdd5;
  border-radius: 6px;
  overflow: hidden;
  background-color: #ffffff;
}

.ui-featured-app-card__header {
  margin-top: 0.5em;
  color: #212b35;
}

.ui-featured-app-card__link {
  display: block;
  color: inherit;
  margin-top: 0.2em;
}

.ui-featured-app-card__title {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.ui-featured-app-card__tagline {
  font-size: 1em;
  color: inherit;
}

.ui-featured-app-card__byline {
  font-size: 0.875em;
}

@-webkit-keyframes in-progress-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes in-progress-spin {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ui-modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.45);
  opacity: 0;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

.ui-modal-backdrop--in-transition {
  display: block;
}

.ui-modal-backdrop--visible {
  display: block;
  opacity: 1;
}

.ui-modal {
  position: fixed;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-transform: translate(0%, 0%);
  transform: translate(0%, 0%);
  width: 100%;
  background: #ffffff;
  border-radius: 0.5em 0.5em 0 0;
}

@media screen and (max-width: 67.4375em) {
  .ui-modal {
    min-height: 20em;
    max-height: 100%;
    -webkit-transition: -webkit-transform 0.35s;
    transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    transition: transform 0.35s, -webkit-transform 0.35s;
  }
}

@media screen and (min-width: 67.5em) {
  .ui-modal {
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
    width: 40em;
    min-height: 15em;
    max-height: 30em;
    opacity: 0;
    border-radius: 0.5em;
    -webkit-transition: top 0.35s, opacity 0.35s, -webkit-transform 0.35s;
    transition: top 0.35s, opacity 0.35s, -webkit-transform 0.35s;
    transition: transform 0.35s, top 0.35s, opacity 0.35s;
    transition: transform 0.35s, top 0.35s, opacity 0.35s,
      -webkit-transform 0.35s;
  }
}

@media screen and (min-width: 67.5em) {
  .ui-modal--wide {
    width: 55em;
  }
}

@media screen and (min-width: 67.5em) {
  .ui-modal--tall {
    max-height: 90vh;
    height: auto;
    min-height: 15em;
  }
}

@media screen and (max-width: 67.4375em) {
  .ui-modal--sheet {
    height: 100%;
    max-height: unset;
  }
}

.ui-modal--in-transition {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ui-modal--visible {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (max-width: 67.4375em) {
  .ui-modal--visible {
    -webkit-transform: translate(0, -100%);
    transform: translate(0, -100%);
  }
}

@media screen and (min-width: 67.5em) {
  .ui-modal--visible {
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

.ui-modal__body,
.ui-modal__footer {
  padding: 20px;
}

.ui-modal__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  border-bottom: solid 1px #dfe3e8;
}

.ui-modal__header-title {
  padding: 20px;
  margin: 0;
  font-weight: normal;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.ui-modal__close-btn {
  padding: 20px;
  margin-left: auto;
}

.ui-modal__close-btn svg {
  fill: #000000;
}

.ui-modal__body {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  overflow-y: auto;
}

@media screen and (max-width: 46.8125em) {
  .ui-modal__body {
    -webkit-box-shadow: inset 0 16px 6px -16px rgba(196, 205, 213, 0.5),
      inset 0 -16px 6px -16px rgba(196, 205, 213, 0.5);
    box-shadow: inset 0 16px 6px -16px rgba(196, 205, 213, 0.5),
      inset 0 -16px 6px -16px rgba(196, 205, 213, 0.5);
  }
}

.ui-modal__body-prefix {
  margin: -20px;
  margin-bottom: 20px;
  padding: 20px;
  border-bottom: solid 1px #dfe3e8;
  opacity: 0.8;
}

.ui-modal__footer {
  border-top: solid 1px #dfe3e8;
}

@media screen and (min-width: 46.875em) {
  .ui-modal__footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.ui-modal__footer .marketing-button {
  width: 100%;
}

@media screen and (min-width: 46.875em) {
  .ui-modal__footer .marketing-button {
    width: auto;
    margin-left: 10px;
  }
}

.ui-modal__footer .marketing-button,
.ui-modal__footer .marketing-button:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui-modal__footer .marketing-button + .marketing-button {
  margin-top: 15px;
}

@media screen and (min-width: 46.875em) {
  .ui-modal__footer .marketing-button + .marketing-button {
    margin-top: 0;
  }
}

.ui-modal__banner {
  -webkit-transition: all ease-in-out 150ms;
  transition: all ease-in-out 150ms;
  overflow: hidden;
  visibility: visible;
  max-height: 600px;
}

.ui-modal__banner.hide {
  display: inherit;
  visibility: hidden;
  max-height: 0;
  opacity: 0;
}

.ui-modal-action--in-progress {
  position: relative;
}

.ui-modal-action--in-progress::before {
  content: "";
  background: #919eab;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 5px;
}

.ui-modal-action--in-progress::after {
  content: "";
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 10px);
  display: block;
  width: 20px;
  height: 20px;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAAXNSR0IArs4c6QAAAXtJREFUOBGllD8vBEEYxncPudxFo6YhkSPxAagVlESiV90HOJVQiw8gIuEDSDREgYRWISrFiYjKaTRKzp/1e253ZHZvNjvHm/xu3nnneZ/M7O5cEOREFEUDUIMwR+JXlgEswwMo1vw6Y1W/Laa5yvwYZq16ZOX+KWZlOAcTTyQr/g4ZJc27xonxCCoZif+U5jH4AMUFlP27HUoM9uREfMGEQ9JbCRM9L8Vhb53d6hImg5SHk6WrbklvlRLycavl3sr/lMrw3er838vASIYty3DKyr1SHplu1iZsQV+nieQaFE1I3Z4iV/R1NSYRfyFMGqbCuFFkYtbRVuE56b1j/N1hhUkrWWgzTpumvBGNrupJ0qNhKaWlMAefWiFkug7O41OfhEswcWrMUv91rNZZ2IZ460HQJD+AW2hDDWZgEfRCFWewEIbhW2eW/cF0HszxzQ5co+7+DhR/aoj0sFfhBrLxSmEfRrOb0Tx1ZJeAxiHqI6Ar+gKPHO+b0Rk/jrp4hlkOtFUAAAAASUVORK5CYII=");
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-animation: in-progress-spin 1s linear infinite;
  animation: in-progress-spin 1s linear infinite;
}

.ui-modal__secondary-action[disabled],
.ui-modal__secondary-action[disabled]:hover {
  background: white;
  color: #919eab;
  border-color: #919eab;
}

@media screen and (min-width: 46.875em) {
  .ui-modal__primary-action,
  .ui-modal__secondary-action {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
  }
}

.ui-modal__tertiary-action {
  font-weight: 700;
  width: 100%;
  margin-bottom: 15px;
}

@media screen and (min-width: 46.875em) {
  .ui-modal__tertiary-action {
    -webkit-box-flex: 2;
    -ms-flex: 2 1 auto;
    flex: 2 1 auto;
    margin-bottom: 0;
    width: auto;
  }
}

.ui-modal .page-width {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.ui-modal .section-block {
  padding-bottom: 0;
}

.contact-support-footer {
  font-size: 0.8rem;
}

.ui-star-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ui-star-rating__icon-set {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ui-star-rating__icon {
  width: 14px;
  height: 14px;
  margin-right: 3px;
}

.ui-star-rating__icon svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: #dfe3e8;
}

.ui-star-rating__rating {
  margin-right: 0.25em;
}

.ui-star-rating--active .ui-star-rating__icon svg,
.ui-star-rating[data-rating="1"] .ui-star-rating__icon:nth-child(-n + 1) svg,
.ui-star-rating[data-rating="2"] .ui-star-rating__icon:nth-child(-n + 2) svg,
.ui-star-rating[data-rating="3"] .ui-star-rating__icon:nth-child(-n + 3) svg,
.ui-star-rating[data-rating="4"] .ui-star-rating__icon:nth-child(-n + 4) svg,
.ui-star-rating[data-rating="5"] .ui-star-rating__icon:nth-child(-n + 5) svg {
  fill: #fdd991;
}

.ui-star-rating--monochrome.ui-star-rating--active .ui-star-rating__icon svg,
.ui-star-rating--monochrome[data-rating="1"]
  .ui-star-rating__icon:nth-child(-n + 1)
  svg,
.ui-star-rating--monochrome[data-rating="2"]
  .ui-star-rating__icon:nth-child(-n + 2)
  svg,
.ui-star-rating--monochrome[data-rating="3"]
  .ui-star-rating__icon:nth-child(-n + 3)
  svg,
.ui-star-rating--monochrome[data-rating="4"]
  .ui-star-rating__icon:nth-child(-n + 4)
  svg,
.ui-star-rating--monochrome[data-rating="5"]
  .ui-star-rating__icon:nth-child(-n + 5)
  svg {
  fill: #212b35;
}

.ui-star-rating-input__stars {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ui-star-rating-input__notifier {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
}

.ui-search-suggestions {
  position: relative;
}

.ui-search-suggestions__input-wrapper {
  margin-bottom: 0;
}

.ui-search-suggestions__input {
  position: relative;
  z-index: 11;
}

.ui-search-suggestions__button {
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  z-index: 12;
}

.ui-search-suggestions__button-icon {
  width: 100%;
  height: 100%;
  color: #ffffff;
  fill: #919eab;
}

.ui-search-suggestions__suggestions {
  background-color: #ffffff;
  position: absolute;
  top: 3.2em;
  width: 100%;
  z-index: 10;
}

.ui-search-suggestions__suggestions-item {
  padding: 0.9375em;
  font-weight: 700;
}

.ui-search-suggestions__suggestions-item b {
  font-weight: 400;
}

.ui-search-suggestions__suggestions-item:hover,
.ui-search-suggestions__suggestions-item__selected {
  background-color: #f9f6f4;
  cursor: pointer;
}

.page--ui-search-navbar--is-open {
  overflow: hidden;
  height: 100vh;
}

.page--ui-search-navbar--is-open .page-wrap::before {
  visibility: visible;
  opacity: 1;
}

[data-module="ui-search-navbar__activator"] *,
[data-module="ui-search-navbar__deactivator"] * {
  pointer-events: none;
}

.ui-search-navbar__container {
  position: fixed;
  z-index: 1000;
  top: 0;
  height: 60px;
  width: 100%;
  background-color: #ffffff;
  border-bottom: 1px solid #f9f6f4;
  display: none;
}

@media screen and (min-width: 67.5em) {
  .ui-search-navbar__container {
    height: 80px;
  }
}

.ui-search-navbar__container--is-open {
  display: block;
}

.ui-search-navbar {
  max-width: 800px;
  margin: auto;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.ui-search-navbar__form-wrapper {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.ui-search-navbar__button {
  -webkit-box-flex: 0;
  -ms-flex: 0 1 60px;
  flex: 0 1 60px;
  height: 100%;
  width: 60px;
}

@media screen and (min-width: 67.5em) {
  .ui-search-navbar__button {
    height: 80px;
  }
}

.ui-search-navbar__button.focusable:focus {
  position: absolute;
  right: 0;
  z-index: 1000;
  background: #ffffff;
  height: 100%;
  width: 60px;
}

.ui-search-navbar__button-icon {
  height: 22px;
  width: 22px;
  margin-top: 0.3125em;
  fill: #212b35;
}

.ui-search-navbar__navbar-activator {
  height: 60px;
  width: 40px;
  margin-left: auto;
}

@media screen and (min-width: 67.5em) {
  .ui-search-navbar__navbar-activator {
    height: 80px;
    width: auto;
    line-height: 80px;
  }
}

.ui-search-navbar__navbar-activator-label {
  padding-left: 0.4375em;
}

.ui-search-navbar__navbar-activator-icon {
  height: 22px;
  width: 22px;
  color: #ffffff;
  vertical-align: middle;
}

.ui-search-navbar-form__input {
  height: 60px;
  width: calc(100% - 22px - 30px);
  padding-left: 30px;
  border: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: transparent;
}

@media screen and (min-width: 67.5em) {
  .ui-search-navbar-form__input {
    height: 80px;
    padding-right: 30px;
  }
}

.ui-search-navbar-form__input:focus,
.ui-search-navbar-form__input:active {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ui-search-navbar-form__input::-ms-clear {
  height: 22px;
}

.ui-search-navbar-form__button {
  height: 100%;
  width: 52px;
  padding: 0 15px;
}

.ui-search-navbar-form__suggestions {
  top: 60px;
  color: #30373b;
}

@media screen and (min-width: 67.5em) {
  .ui-search-navbar-form__suggestions {
    top: 80px;
  }
}

.ui-search-navbar-form__suggestions .ui-search-suggestions__suggestions-item {
  padding: 15px 30px;
}

.ui-banner {
  position: relative;
  padding: 22px 20px 20px;
  background: #f9f6f4;
  margin: 0 0 15px;
  border-radius: 5px;
}

.ui-banner__icon {
  position: absolute;
  width: 22px;
  height: 22px;
}

.ui-banner__icon::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.ui-banner__body {
  width: 100%;
  color: #212b35;
}

.ui-banner__body p {
  padding: 0;
  margin: 0;
}

.ui-banner--with-icon .ui-banner__body {
  padding-left: 32px;
}

.ui-banner--success {
  background: #e0f5f5;
}

.ui-banner--success .ui-banner__icon::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20id=%22Layer_1%22%20data-name=%22Layer%201%22%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2020%2016%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%2347c1bf%20!important;%20%7D%3C/style%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%2350b83c;%7D%3C/style%3E%3C/defs%3E%3Cg%20id=%22Pricing%22%3E%3Cg%20id=%22Pricing---yearly-variations%22%3E%3Cg%20id=%22Group-17-Copy-3%22%3E%3Cg%20id=%22Group-7%22%3E%3Cg%20id=%22Group-4%22%3E%3Cg%20id=%22Group-9%22%3E%3Cg%20id=%22Group-10%22%3E%3Cg%20id=%22Group-3%22%3E%3Cg%20id=%22Polaris-icon_Major_Mono_Mobile-Accept%22%20data-name=%22Polaris-icon/Major/Mono/Mobile-Accept%22%3E%3Cpath%20id=%22Icon%22%20class=%22cls-1%22%20d=%22M7,16a1.05,1.05,0,0,1-.71-.29l-6-6A1,1,0,0,1,1.71,8.29l5.23,5.24L18.24.35a1,1,0,1,1,1.52,1.3l-12,14A1,1,0,0,1,7,16H7%22%20transform=%22translate(0%200)%22/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

.ui-banner--error {
  background: #fae1e1;
}

.ui-banner--error .ui-banner__icon::before {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2020%2020%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23fb8b8a%20!important;%20%7D%3C/style%3E%3Cpath%20d=%22M11.414%2010l4.293-4.293c.39-.39.39-1.023%200-1.414s-1.023-.39-1.414%200L10%208.586%205.707%204.293c-.39-.39-1.023-.39-1.414%200s-.39%201.023%200%201.414L8.586%2010l-4.293%204.293c-.39.39-.39%201.023%200%201.414.195.195.45.293.707.293s.512-.098.707-.293L10%2011.414l4.293%204.293c.195.195.45.293.707.293s.512-.098.707-.293c.39-.39.39-1.023%200-1.414L11.414%2010z%22/%3E%3C/svg%3E%0A");
}

.ui-external-link > .icon {
  width: 1rem;
  height: 1rem;
  margin-left: 4px;
  fill: #5c6ac4;
}

@media screen and (min-width: 67.5em) {
  .ui-external-link > .icon {
    opacity: 0;
  }
  .ui-external-link:hover .icon,
  .ui-external-link:focus .icon {
    opacity: 1;
  }
}

.ui-external-link--show-icon .icon {
  opacity: 1;
}

.ui-external-link.marketing-button svg {
  fill: currentColor;
}

.listing-banner {
  background: #e0f5f5;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 30px 0;
}

.listing-banner__content {
  padding-bottom: 15px;
}

.listing-banner__content .body-link,
.listing-banner__content .applied-filters__remove-all {
  color: #454f5b;
  text-decoration: underline;
}

.listing-banner__content .body-link:hover,
.listing-banner__content .applied-filters__remove-all:hover,
.listing-banner__content .body-link:focus,
.listing-banner__content .applied-filters__remove-all:focus {
  text-decoration: none;
  color: #003135;
}

.listing-banner__content:last-child {
  padding: 0;
}

.listing-banner__action {
  margin-right: 15px;
  color: #454f5b;
}

.listing-banner__action > .icon {
  fill: #454f5b;
}

.listing-banner__action__primary {
  -webkit-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.15);
  background-color: #e0f5f5;
  color: #003135;
  border-width: 0.125em;
  border-style: solid;
  border-color: #003135;
}

.listing-banner__action__primary:hover,
.listing-banner__action__primary:focus {
  background-color: #003135;
  color: #e0f5f5;
}

.listing-banner__action__primary:active {
  background-color: #003135;
  color: #e0f5f5;
  border-color: #003135;
}

.listing-banner__action__primary:hover {
  -webkit-box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.25);
}

.listing-banner__action__primary:focus {
  -webkit-box-shadow: 0 0 0.1875em 0.1875em rgba(0, 49, 53, 0.5);
  box-shadow: 0 0 0.1875em 0.1875em rgba(0, 49, 53, 0.5);
}

.listing-banner__action__secondary {
  text-decoration: underline;
}

.listing-banner__action__secondary:hover,
.listing-banner__action__secondary:focus {
  text-decoration: none;
}

@media screen and (min-width: 67.5em) and (max-width: 89.9375em) {
  .key-benefits-section .grid__item {
    width: 33.3333333333%;
  }
}

.key-benefit {
  max-width: 320px;
  margin: 0 auto;
  padding-bottom: 30px;
}

.key-benefit__image-wrap {
  border-radius: 6px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 0 0.3em 1px #c4cdd5;
  box-shadow: 0 0 0.3em 1px #c4cdd5;
  height: 240px;
  padding: 20px;
}

.key-benefit__image {
  height: 100%;
}

.app-listing-description {
  color: #212b35;
}

.app-listing-description ul {
  list-style: disc inside;
}

.app-listing-description ol {
  list-style: decimal inside;
}

.app-listing-description ul > li > p,
.app-listing-description ol > li > p {
  display: inline-block;
}

.app-listing__section {
  margin-top: 3.75em;
  margin-bottom: 0;
}

@media screen and (min-width: 46.875em) {
  .app-listing__section {
    margin-top: 2.8125em;
  }
}

.app-listing__support-section {
  margin-bottom: 0;
}

@media screen and (min-width: 46.875em) {
  .app-listing__support-section {
    margin-top: 0;
  }
}

@media screen and (min-width: 67.5em) {
  .app-listing__support-section {
    min-width: 320px;
  }
}

.app-listing-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 30px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media screen and (min-width: 46.875em) {
  .app-listing-title {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}

.app-listing-title--top {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.app-listing-title--center {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@media screen and (min-width: 46.875em) {
  .app-listing-title--center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
}

.app-listing-title__heading {
  margin-bottom: 0;
}

.app-listing-title__sub-heading {
  font-size: 1.1rem;
  font-weight: normal;
  color: #637381;
  margin-left: 12px;
}

.app-listing-title__link {
  display: inline-block;
  margin-top: 15px;
  text-decoration: none;
}

@media screen and (min-width: 46.875em) {
  .app-listing-title__link {
    display: block;
    margin-left: auto;
    margin-top: 0;
  }
}

.app-listing__pricing .app-listing-title {
  margin: 0;
}

.app-listing__pricing .app-listing-title__link {
  margin-top: 15px;
}

.app-listing__pricing .app-listing-description {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 0;
}

@media screen and (min-width: 46.875em) {
  .app-listing__pricing .app-listing-description {
    max-width: 30em;
  }
}

@media screen and (min-width: 67.5em) {
  .app-listing__pricing .app-listing-description {
    max-width: 35em;
  }
}

.app-listing__pricing-footnote {
  color: #637381;
  font-size: 1.1rem;
}

@media screen and (min-width: 46.875em) {
  .app-listing__pricing-footnote {
    max-width: 30em;
    padding: 0 0.5em;
  }
}

.app-listing__pricing-plans {
  margin-top: 30px;
}

@media screen and (min-width: 46.875em) {
  .app-listing__pricing-plans {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    max-width: 30em;
  }
}

@media screen and (min-width: 67.5em) {
  .app-listing__pricing-plans {
    max-width: 35em;
  }
}

@media screen and (min-width: 46.875em) {
  .app-listing__pricing--multiple .app-listing__pricing-plans {
    margin-right: -30px;
    margin-left: -30px;
    max-width: none;
  }
}

.pricing-plan-card {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin-bottom: 30px;
}

@media screen and (min-width: 46.875em) {
  .app-listing__pricing--multiple .pricing-plan-card {
    min-width: calc(50% - 30px);
    max-width: calc(50% - 30px);
    margin-left: 30px;
  }
}

@media screen and (min-width: 67.5em) {
  .app-listing__pricing--multiple .pricing-plan-card {
    min-width: calc(25% - 30px);
  }
}

.pricing-plan-card__title {
  margin-bottom: 30px;
}

.pricing-plan-card--pricing-only .pricing-plan-card__title {
  margin-bottom: 0;
}

.pricing-plan-card__title .pricing-plan-card__title-kicker {
  font-weight: normal;
  font-size: 1rem;
  letter-spacing: 2px;
  color: #637381;
}

.pricing-plan-card__title .pricing-plan-card__title-header {
  font-weight: normal;
  font-size: 2rem;
  margin-bottom: 0;
}

.pricing-plan-card__title .pricing-plan-card__title-header span {
  font-size: 1.6rem;
}

.pricing-plan-card__title .pricing-plan-card__title-sub-heading {
  font-size: 1.2rem;
  margin-top: 1rem;
}

.pricing-plan-card__title .pricing-plan-card__title-sub-heading:last-of-type {
  margin-bottom: 0;
}

.app-listing__pricing--multiple
  .pricing-plan-card__title
  .pricing-plan-card__title-sub-heading {
  max-width: 15.625em;
}

.pricing-plan-card__title .pricing-plan-card__title-sub-heading--subdued {
  color: #637381;
  font-size: 1rem;
}

.pricing-plan-card__details-list .bullet {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-bottom: 10.5px;
}

.pricing-plan-card__details-list .bullet__icon {
  padding-right: 10px;
}

.pricing-plan-card__details-list .bullet__icon svg {
  width: 1.125em;
  height: 1.5em;
}

.pricing-plan-card__details-list .bullet__content {
  padding: 0;
}

.app-listing-media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -15px -15px 40px -15px;
  padding-left: 3px;
}

.app-listing-media__thumbnail {
  margin: 30px 15px 0;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.35);
  list-style: none;
}

.app-listing-media__thumbnail > div {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  cursor: pointer;
}

.app-listing-media__thumbnail img {
  position: absolute;
  top: 0;
  left: 50%;
  width: auto;
  height: 100%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

@media screen and (min-width: 46.875em) {
  .app-listing-media__thumbnail {
    width: calc(33% - 30px);
  }
}

@media screen and (max-width: 46.8125em) {
  .app-listing-media__thumbnail {
    width: calc(50% - 30px);
  }
}

.app-support-list__item {
  margin: 0.65em 0;
}

.app-support-list__item span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.app-support-list__item span > .icon {
  width: 1.2em;
  height: 1.2em;
  margin-right: 0.7em;
  fill: #637381;
}

.app-support-list__item--get-support .marketing-button {
  font-weight: normal;
  color: #4917ff;
}

.developer-feedback-modal__field + .developer-feedback-modal__field {
  margin-top: 15px;
}

.developer-experience-rating {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.developer-experience-rating__input + .developer-experience-rating__input {
  margin-left: 30px;
}

.developer-experience-rating__input .developer-experience-rating__icon {
  width: 50px;
  height: 50px;
  fill: #919eab;
}

.developer-experience-rating__input
  input:checked[value="2"]
  + label
  .developer-experience-rating__icon {
  fill: #58b44b;
}

.developer-experience-rating__input
  input:checked[value="1"]
  + label
  .developer-experience-rating__icon {
  fill: #454f5b;
}

.developer-experience-rating__input
  input:checked[value="0"]
  + label
  .developer-experience-rating__icon {
  fill: #eb644d;
}

.developer-experience-rating__input
  label:hover
  .developer-experience-rating__icon {
  fill: #637381;
}

.developer-experience-rating__label {
  display: block;
  cursor: pointer;
}

.app-details-banner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 0 30px;
  padding: 0 0 15px;
  border-bottom: solid 1px #dfe3e8;
}

@media screen and (min-width: 67.5em) {
  .app-details-banner {
    padding: 0 15px 15px;
  }
}

.app-details-banner__icon {
  min-width: 72px;
  max-width: 72px;
}

.app-details-banner__icon img {
  display: block;
  width: 100%;
  border-radius: 5px;
}

.app-details-banner__info {
  padding: 0 0 0 15px;
}

.contact-support__email {
  color: #5c6ac4;
}

.app-listing-requirements {
  color: #212b35;
}

.app-listing-requirements__trigger {
  color: #eb644d;
  text-decoration: underline;
  text-align: left;
  padding-left: 2em;
  font-size: 1rem;
}

.app-listing-requirements__trigger:hover,
.app-listing-requirements__trigger:focus {
  text-decoration: none;
}

.app-listing-requirements__trigger-icon {
  height: 1.5em;
  width: 1.9em;
  vertical-align: middle;
  padding-right: 0.3em;
  margin-top: -0.1em;
  margin-left: -2em;
}

.app-listing-requirements__list {
  background-color: #f9fafb;
  border-radius: 5px;
  padding: 15px;
  margin: 15px 0;
}

.app-store__embeds-page {
  background: transparent;
  margin: 30px;
}

.ui-app-store-hero--type-app-details-stub .ui-app-store-hero__container {
  grid-template-areas: "icon header";
  grid-template-columns: 1fr 11fr;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.ui-app-store-hero--type-app-details-stub
  .ui-app-store-hero__container
  .ui-app-store-hero__app-icon {
  -ms-grid-row: 1;
}

.ui-app-store-hero--type-app-details-stub
  .ui-app-store-hero__container
  .ui-app-store-hero__header {
  margin-bottom: 0;
  -ms-grid-row: 2;
}

@media screen and (max-width: 46.8125em) {
  .reviews-summary {
    margin-bottom: 60px;
  }
}

.reviews-summary__overall-rating {
  display: inline-block;
  margin-left: 0.3em;
}

.reviews-summary__count {
  display: block;
  margin-top: 0.3em;
  color: #637381;
  font-size: 0.6em;
}

.reviews-summary__rating-list {
  margin: 30px 0;
  max-width: 400px;
}

.reviews-summary__rating-breakdown {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.reviews-summary__rating-breakdown + .reviews-summary__rating-breakdown {
  margin-top: 0.5em;
}

.review-summary__star-rating {
  min-width: 80px;
}

.reviews-summary__rating-proportion {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  max-width: calc(100% - 140px);
  margin: 0 7.5px;
}

.reviews-summary__rating-proportion progress {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 1em;
  width: 100%;
  color: #47c1bf;
  border: 1px solid #dfe3e8;
  border-radius: 4px;
}

.reviews-summary__rating-proportion progress::-ms-fill {
  border: none;
}

.reviews-summary__rating-proportion progress::-webkit-progress-bar {
  background-color: #f9f6f4;
  border-radius: 3px;
}

.reviews-summary__rating-proportion progress::-moz-progress-bar {
  background-color: #47c1bf;
  border-radius: 3px 0 0 3px;
}

.reviews-summary__rating-proportion progress::-webkit-progress-value {
  background-color: #47c1bf;
  border-radius: 3px 0 0 3px;
}

.reviews-summary__review-count {
  font-size: 0.875em;
  min-width: 3.125em;
  max-width: 3.125em;
}

.reviews-summary__view-all-link {
  margin-top: 30px;
}

.reviews-summary__view-all-link a {
  color: #5c6ac4;
  text-decoration: underline;
  -webkit-text-decoration-line: underline;
  text-decoration-line: underline;
  -webkit-text-decoration-style: solid;
  text-decoration-style: solid;
  -webkit-text-decoration-color: currentColor;
  text-decoration-color: currentColor;
  text-decoration-skip-ink: auto;
  -webkit-transition: color 150ms;
  transition: color 150ms;
}

.reviews-summary__view-all-link a:hover,
.reviews-summary__view-all-link a:focus {
  color: #4655bc;
}

.reviews-summary__view-all-link a:focus {
  outline: 0;
  background-color: rgba(92, 106, 196, 0.125);
}

.review-index-filters {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media screen and (min-width: 46.875em) {
  .review-index-filters {
    margin: 0 -15px;
  }
}

@media screen and (max-width: 46.8125em) {
  .review-index-filters {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 46.875em) {
  .review-index-filters__filter {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 15px;
  }
}

@media screen and (max-width: 46.8125em) {
  .review-index-filters__filter {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
}

.review-listing {
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: solid 1px #c4cdd5;
}

.review-listing:last-child {
  border: none;
}

.review-listing.review-listing--your-review {
  border-bottom: solid 1px #c4cdd5;
}

.review-listing-header__text {
  font-size: 1.5em;
  font-weight: normal;
}

.review-metadata {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
}

.review-metadata__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 30px 15px 0;
}

.review-metadata__item:last-child {
  margin-right: 0;
}

.review-metadata__item-label {
  font-size: 0.875em;
}

.review-metadata__item-value {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  color: #212b35;
}

.review-content {
  margin-bottom: 15px;
  color: #212b35;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}

.review-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 30px;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.review-action__button {
  color: #5c6ac4;
}

.review-action__button:hover,
.review-action__button:focus {
  color: #202e78;
}

.review-action__button:disabled {
  color: #919eab;
  cursor: default;
}

.review-action__button-reply {
  margin: auto auto auto 0;
}

.review-action__button-helpfulness {
  padding-left: 30px;
  background: no-repeat left -2px url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2020%2020%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%235c6ac4%20!important;%20%7D%3C/style%3E%3Cpath%20fill=%22currentColor%22%20d=%22M1%207h4v10H1V7z%22/%3E%3Cpath%20d=%22M16.153%2018.002h-5.96L6%2016.325V8.002h1a1%201%200%200%200%20.895-.553l2.712-5.425.393.015c.55%200%201%20.45%201%201v2.962h-1a1%201%200%200%200%200%202h6.82l-1.667%2010zM2%2016.002v-8h2v8H2zm17.763-9.646A1%201%200%200%200%2019%206.002h-5V3.04c0-1.654-1.345-3-2.96-3h-.002l-1-.038a.985.985%200%200%200-.932.553L6.382%206.002H1a1%201%200%200%200-1%201v10a1%201%200%200%200%201%201h3.807l4.822%201.93c.117.046.243.07.37.07h7c.49%200%20.906-.354.986-.835l2-12a.99.99%200%200%200-.223-.81z%22/%3E%3C/svg%3E");
  background-size: 19px 100%;
}

.review-action__button-helpfulness:hover {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2020%2020%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23202e78%20!important;%20%7D%3C/style%3E%3Cpath%20fill=%22currentColor%22%20d=%22M1%207h4v10H1V7z%22/%3E%3Cpath%20d=%22M16.153%2018.002h-5.96L6%2016.325V8.002h1a1%201%200%200%200%20.895-.553l2.712-5.425.393.015c.55%200%201%20.45%201%201v2.962h-1a1%201%200%200%200%200%202h6.82l-1.667%2010zM2%2016.002v-8h2v8H2zm17.763-9.646A1%201%200%200%200%2019%206.002h-5V3.04c0-1.654-1.345-3-2.96-3h-.002l-1-.038a.985.985%200%200%200-.932.553L6.382%206.002H1a1%201%200%200%200-1%201v10a1%201%200%200%200%201%201h3.807l4.822%201.93c.117.046.243.07.37.07h7c.49%200%20.906-.354.986-.835l2-12a.99.99%200%200%200-.223-.81z%22/%3E%3C/svg%3E");
}

.review-action__button-helpfulness[data-selected="true"] {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20id=%22Layer_1%22%20data-name=%22Layer%201%22%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2020%2020%22%3E%3Cdefs%3E%3Cstyle%3E.cls-1,.cls-2%7Bfill:%235d6cc2;%7D.cls-2%7Bfill-opacity:0.4;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Eic_thumbs_up_filled%3C/title%3E%3Cg%20id=%22Page-1%22%3E%3Cg%20id=%22_Desktop_-Final---app-listing---helpful%22%20data-name=%22[Desktop]-Final---app-listing---helpful%22%3E%3Cg%20id=%22Group-29%22%3E%3Cg%20id=%22Group-39%22%3E%3Cg%20id=%22Group-27-Copy%22%3E%3Cg%20id=%22Group-7%22%3E%3Cg%20id=%22ic_thumbs_up_filled%22%20data-name=%22ic%20thumbs%20up%20filled%22%3E%3Cpath%20id=%22Icon%22%20class=%22cls-1%22%20d=%22M16.15,18h-6L6,16.32V8H7a1,1,0,0,0,.9-.55L10.61,2H11a1,1,0,0,1,1,1V6H11a1,1,0,1,0,0,2h6.82ZM2,16H4V8H2ZM19.76,6.36A1,1,0,0,0,19,6H14V3a3,3,0,0,0-3-3h0L10,0a1,1,0,0,0-.93.55L6.38,6H1A1,1,0,0,0,0,7V17a1,1,0,0,0,1,1H4.81l4.82,1.93A1,1,0,0,0,10,20h7a1,1,0,0,0,1-.83l2-12a1,1,0,0,0-.22-.81Z%22/%3E%3Cpath%20id=%22Icon-2%22%20data-name=%22Icon%22%20class=%22cls-2%22%20d=%22M19.76,6.36A1,1,0,0,0,19,6H14V3a3,3,0,0,0-3-3h0L10,0a1,1,0,0,0-.93.55L6.38,6H1A1,1,0,0,0,0,7V17a1,1,0,0,0,1,1H4.81l4.82,1.93A1,1,0,0,0,10,20h7a1,1,0,0,0,1-.83l2-12a1,1,0,0,0-.22-.81Z%22/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.review-action__button-helpfulness[data-disabled="true"] {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg%20xmlns=%22http://www.w3.org/2000/svg%22%20viewBox=%220%200%2020%2020%22%3E%3Cstyle%20type=%22text/css%22%3Ecircle,%20ellipse,%20line,%20path,%20polygon,%20polyline,%20rect,%20text%20%7B%20fill:%20%23919eab%20!important;%20%7D%3C/style%3E%3Cpath%20fill=%22currentColor%22%20d=%22M1%207h4v10H1V7z%22/%3E%3Cpath%20d=%22M16.153%2018.002h-5.96L6%2016.325V8.002h1a1%201%200%200%200%20.895-.553l2.712-5.425.393.015c.55%200%201%20.45%201%201v2.962h-1a1%201%200%200%200%200%202h6.82l-1.667%2010zM2%2016.002v-8h2v8H2zm17.763-9.646A1%201%200%200%200%2019%206.002h-5V3.04c0-1.654-1.345-3-2.96-3h-.002l-1-.038a.985.985%200%200%200-.932.553L6.382%206.002H1a1%201%200%200%200-1%201v10a1%201%200%200%200%201%201h3.807l4.822%201.93c.117.046.243.07.37.07h7c.49%200%20.906-.354.986-.835l2-12a.99.99%200%200%200-.223-.81z%22/%3E%3C/svg%3E");
}

.review-helpfulness {
  position: relative;
}

.review-helpfulness__helpful-flash-message {
  bottom: -21px;
  position: absolute;
  right: 0;
  display: none;
  white-space: nowrap;
  color: #5c6ac4;
  font-size: 14px;
  opacity: 0;
  -webkit-transition: opacity 0.35s;
  transition: opacity 0.35s;
}

.review-helpfulness__helpful-flash-message--transitioning {
  display: block;
}

.review-helpfulness__helpful-flash-message--visible {
  display: block;
  opacity: 1;
}

.review-reply,
.review-reply__flash {
  margin: 30px 0;
}

@media screen and (min-width: 46.875em) {
  .review-reply,
  .review-reply__flash {
    margin-left: 30px;
  }
}

.review-reply {
  background-color: #f4f5fa;
  padding: 30px;
  border-radius: 6px;
}

@media screen and (min-width: 46.875em) {
  .review-reply__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
}

.review-reply__header-item {
  font-weight: 400;
  margin-bottom: 0.9em;
}

.review-form__description {
  min-height: 6.4em;
}

.review-form__delete-action {
  color: #eb644d;
}

.review-form__delete-action[disabled],
.review-form__delete-action[disabled]:hover {
  background: none;
  color: #919eab;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.review-form__label {
  color: #212b35;
}

.applied-filters {
  padding-top: 15px;
}

@media screen and (min-width: 67.5em) {
  .applied-filters {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
  }
}

.applied-filters__label {
  display: inline-block;
  color: #919ea9;
  margin-bottom: 0.625em;
  margin-right: 0.625em;
}

@media screen and (max-width: 46.8125em) {
  .applied-filters__label {
    display: block;
  }
}

.applied-filters__filter {
  background-color: #dfe3e8;
  color: #454f59;
  margin-right: 0.625em;
  margin-bottom: 0.625em;
  padding: 0.25em 0.5em;
  font-weight: 400;
  line-height: 1.5;
  -webkit-box-shadow: none;
  box-shadow: none;
  white-space: nowrap;
}

.applied-filters__filter + .applied-filters__filter {
  margin-left: 0;
}

.applied-filters__target {
  display: inline-block;
}

.applied-filters__target:hover .applied-filters__filter,
.applied-filters__target:focus .applied-filters__filter,
.applied-filters__target:active .applied-filters__filter {
  background: #5c6ac4;
  color: #ffffff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.applied-filters__target:hover .applied-filters__icon--remove-filter,
.applied-filters__target:focus .applied-filters__icon--remove-filter,
.applied-filters__target:active .applied-filters__icon--remove-filter {
  fill: #ffffff;
}

.applied-filters__icon--remove-filter {
  position: relative;
  top: 3px;
  width: 15px;
  height: 15px;
  fill: #637381;
}

.ui-sheet .applied-filters__remove-all {
  margin-right: 15px;
}

.grid-item--app-card-listing {
  margin-bottom: 30px;
}

.search-hero__header__container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.search-hero__header-breadcrumbs {
  margin-bottom: 30px;
}

.search-hero__header-breadcrumb-arrow-icon {
  position: relative;
  top: -2px;
  width: 15px;
  margin-right: 0.2em;
  vertical-align: middle;
  fill: #5c6ac4;
}

.search-hero__header {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: left;
}

.search-hero__subheader {
  margin-bottom: 15px;
}

.search-hero__search-icon {
  margin: 0 0.375em 0 0;
  height: 0.75em;
  color: #f9f6f4;
  fill: #5c6ac4;
}

@media screen and (min-width: 67.5em) {
  .search-hero__filter-button {
    display: none;
  }
}

.search-hero__no-results {
  margin-top: 15px;
  color: #637381;
}

.search-hero__browse-all-button {
  min-width: 100%;
}

@media screen and (min-width: 46.875em) {
  .search-hero__browse-all-button {
    min-width: 12.5em;
  }
}

.search-no-results-illustration img {
  width: 100%;
}

.search-pagination {
  margin-top: 30px;
}

.search-pagination .gap {
  display: none;
}

.search-pagination__link {
  margin: 0 0.625em;
  text-decoration: underline;
}

@media screen and (max-width: 46.8125em) {
  .search-pagination__link {
    margin: 0 0.75em;
  }
}

.search-pagination__link--hide {
  display: none;
}

.search-pagination__tag {
  margin: 0 0.625em;
}

@media screen and (max-width: 46.8125em) {
  .search-pagination__tag {
    margin: 0 0.75em;
  }
}

.search-pagination__next-page-text,
.search-pagination__previous-page-text {
  text-decoration: underline;
  display: inline-block;
}

.search-pagination__arrow-icon {
  position: relative;
  top: 0.1875em;
  margin: 0 0.3125em;
  width: 15px;
  fill: #5c6ac4;
}

.search-pagination__previous-page-text {
  margin-right: 0.875em;
}

@media screen and (max-width: 46.8125em) {
  .search-pagination__previous-page-text {
    margin-right: 1.25em;
  }
}

.search-pagination__next-page-text {
  margin-left: 0.875em;
}

@media screen and (max-width: 46.8125em) {
  .search-pagination__next-page-text {
    margin-left: 1.25em;
  }
}

.search-pagination__next-page-text.disabled,
.search-pagination__previous-page-text.disabled {
  display: none;
}

.sitemap--section .sitemap--section--header a {
  color: #212b35;
}

@media screen and (min-width: 46.875em) and (max-width: 67.4375em) {
  .sitemap--section .sitemap--section--content {
    -webkit-column-count: 2;
    column-count: 2;
    -webkit-column-gap: 5.625em;
    column-gap: 5.625em;
  }
}

@media screen and (min-width: 67.5em) {
  .sitemap--section .sitemap--section--content {
    -webkit-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 3.75em;
    column-gap: 3.75em;
  }
}

.sitemap--section .sitemap--section--group {
  -webkit-column-break-inside: avoid;
  break-inside: avoid;
  display: table;
}
